import React from 'react';
import { Filter, TextInput, NullableBooleanInput, BooleanInput } from 'react-admin';

import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';
import { LATEST_SEASON } from '@hisports/common/src/seasons';

import { InlineDateInput } from '../../common/inputs/DateInput';
import { GameStatusEnumInput } from '../../common/inputs/EnumInputs';
import { SeasonFilter, useSeason } from '../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../common/Authorize';
import { useDefaultSeason, useFlag, useScopes } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';
import { SurfaceInput } from '../surfaces/SurfaceInput';
import { TeamInput } from '../teams/TeamInput';
import { ScheduleInput } from '../schedules/ScheduleInput';
import { CategoryInput } from '../categories/CategoryInput';

const multipleProps = {
  multiple: true,
  allowEmpty: false,
}

const allOfficeFilter = {
  type: { neq: 'Historical' }
}

const localOfficeFilter = {
  type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] }
}

export const GameFilters = props => {
  const scopes = useScopes();
  const seasonId = useSeason()
  const defaultSeason = useDefaultSeason();
  const isEnabled = useFlag();

  const canListOffices = isAuthorized(scopes, 'offices', 'show');
  const canAssign = isAuthorized(scopes, 'games', 'assign');
  const canListTeams = isAuthorized(scopes, 'teams', 'list');
  const canListSurfaces = isAuthorized(scopes, 'surfaces', 'list');
  const canListSchedules = isAuthorized(scopes, 'schedules', 'list');
  const showPastSeason = (seasonId === LATEST_SEASON) || (seasonId === defaultSeason);

  return <Filter variant="outlined" {...props}>
    <TextInput source="number" alwaysOn />
    <InlineDateInput source="startTime" label="resources.games.filters.startTime" alwaysOn />
    <InlineDateInput source="endTime" label="resources.games.filters.endTime" />
    {canListOffices && <OfficeInput source="officeId" filter={allOfficeFilter} {...multipleProps} />}
    {canListOffices && <OfficeInput source="scheduleOffices" label="resources.games.filters.scheduleOffices" filter={allOfficeFilter} alwaysOn {...multipleProps} />}
    {canListOffices && <OfficeInput source="teamOffices" label="resources.games.filters.teamOffices" filter={localOfficeFilter} alwaysOn {...multipleProps} />}
    {canListOffices && <OfficeInput source="homeTeamOffices" label="resources.games.filters.homeTeamOffices" filter={localOfficeFilter} {...multipleProps} />}
    {canListOffices && <OfficeInput source="awayTeamOffices" label="resources.games.filters.awayTeamOffices" filter={localOfficeFilter} {...multipleProps} />}
    {canListOffices && <OfficeInput source="surfaceOffices" label="resources.games.filters.surfaceOffices" filter={localOfficeFilter} {...multipleProps} />}
    {canAssign && <OfficeInput source="assignOffices" label="resources.games.filters.assignOffices" filter={{ ...localOfficeFilter, isAssigner: true }} alwaysOn {...multipleProps} />}
    {canListTeams && <TeamInput source="teamId" label="resources.teams.fields.teamId" filter={{ seasonId }} {...multipleProps} alwaysOn />}
    {canListSurfaces && <SurfaceInput source="effectiveSurfaces" filter={{ parentId: null }} {...multipleProps} />}
    {canListSchedules && <ScheduleInput source="scheduleId" {...multipleProps} />}
    <CategoryInput source="categoryId" {...multipleProps} />
    <GameStatusEnumInput source="status" {...multipleProps} />
    <NullableBooleanInput source="isApproved" label="resources.games.filters.isApproved" alwaysOn />
    <NullableBooleanInput source="isCertified" label="resources.games.filters.isCertified" alwaysOn />
    {showPastSeason && <BooleanInput source="pastEvents" label="resources.games.filters.pastEvents" alwaysOn />}
    <SeasonFilter source="seasonId" alwaysOn />
  </Filter>
}
