import React from 'react'
import { RecordContextProvider } from 'react-admin'
import { TableCell } from '@material-ui/core';

import InfoCard from '../../../common/cards/InfoCard'

import { ScheduleStatsTable } from '../ScheduleStatsTable'
import { TeamField } from '../../teams/TeamField';
import { GroupSelect } from './PlayerScheduleStatsCard';

const renderColumns = (row, schedule) =>
  <TableCell>
    <RecordContextProvider value={{ teamId: row.teamId, ...schedule }}>
      <TeamField source="teamId" />
    </RecordContextProvider>
  </TableCell>

const renderHeaders = ({ translate }) =>
  <TableCell style={{ width: 325 }}>{translate('resources.teams.name', 1)}</TableCell>

export default function TeamScheduleStandingsCard() {
  const filters = [
    <GroupSelect key="group" />
  ]

  return <InfoCard
    title="components.scheduleStats.titles.TeamScheduleStats"
  >
    <ScheduleStatsTable
      resource="TeamScheduleStats"
      filters={filters}
      sort={{ field: 'ranking', order: 'asc' }}
      renderColumns={renderColumns}
      renderHeaders={renderHeaders}
      fixedColumns={2}
    />
  </InfoCard>
}
