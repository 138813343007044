import React, { createContext, useContext, useMemo, useState } from 'react'
import { GET_LIST, GET_ONE, RecordContextProvider, useLocale, useQuery, useRecordContext, useTranslate } from 'react-admin'
import { LinearProgress, Table, TableBody as MUITableBody, TableCell, TableHead, TablePagination, TableRow, makeStyles, TableSortLabel, Tooltip, TableContainer, Typography } from '@material-ui/core'
import { orderBy } from 'lodash'
import { blue } from '@material-ui/core/colors'

import { translateApiProperty } from '@hisports/common'

import NoResults from '../../common/NoResults'
import FunctionField from '../../common/fields/FunctionField'

import { getGenericLocale } from '../../locale/LocaleProvider'

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    '& .MuiTableCell-root': {
      paddingInline: theme.spacing(0.75),
    }
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}))

const getPropertyValue = (row, property) => row.stats ? row.stats[property.id] : row[property.id]

const getSchemaProperties = (schema, rows, querySort) => {
  if (!schema?.schema?.properties) return [];

  const properties = Object.keys(schema.schema.properties)
    .map(id => ({ id, ...schema.schema.properties[id] }))
    .filter(property => {
      let { hidden, hideNull } = property.displayOptions || {};
      let missingRowProperty = false;
      if (hidden) return false;

      if (querySort && querySort.includes(property.id)) {
        hideNull = false;
      } else {
        missingRowProperty = rows.every(row => {
          const rowProperties = Object.keys(row.stats || row);
          return !rowProperties.includes(property.id);
        });
      }

      if (missingRowProperty) return false;

      return !(hideNull && rows.every(row => getPropertyValue(row, property) == null))
    })

  properties.sort((a, b) => a.displayOptions.order - b.displayOptions.order);
  return properties;
}

const formatPrecision = (stat, precision) => {
  return Number(stat).toFixed(precision);
}

const formatPercentage = (stat) => {
  if (stat >= 1) return stat;
  const [ , decimal ] = String(stat).split('.');
  return `.${decimal}`
}

const formatPlusMinus = (stat) => {
  const style = {
    color: stat === 0 ? undefined : (stat < 0 ? 'red' : 'green'),
    fontSize: 'inherit',
  };

  return <Typography style={style}>{stat <= 0 ? stat : `+${stat}`}</Typography>;
};

const formatMinutes = (stat) => {
  const minutes = Math.floor(stat);
  const seconds = Math.round((stat % 1) * 60)
  return `${minutes}:${String(seconds).padStart(2, 0)}`
}

const format = (value, displayOptions) => {
  const { precision, type } = displayOptions;

  if (value == null) return '--'

  if (value === true) return '✓';

  if (precision) {
    value = formatPrecision(value, precision);
  }

  switch (type) {
    case 'percentage': return formatPercentage(value);
    case 'plus-minus': return formatPlusMinus(value);
    case 'minutes': return formatMinutes(value);
  }

  return value;
}

const countRankings = (data) => {
  return data.reduce((acc, row) => {
    const ranking = row?.ranking;
    if (ranking != null) {
      acc[ranking] = (acc[ranking] || 0) + 1;
    }
    return acc;
  }, {});
};

const filterStats = (row, filters) => {
  const isTeam = filters?.teamId == null || row.teamId === filters.teamId;
  const isGroup = filters?.groupId == null || row.groupId === filters.groupId;
  return isTeam && isGroup;
}

const useScheduleStats = (resource, filter) => useQuery({
  type: GET_LIST,
  resource,
  payload: {
    filter,
    pagination: { page: 1, perPage: 99999 },
  },
}, { enabled: !!resource })

const useSchema = schemaId => useQuery({
  type: GET_ONE,
  resource: 'schemas',
  payload: { id: schemaId }
}, { enabled: !!schemaId })

const RankHeader = ({ sort, sortBy, createSortHandler, translate }) => {
  return <TableCell component="th" scope="row" align="center" style={{ backgroundColor: 'ranking' === sortBy ? blue[50] : undefined, width: 75 }}>
    <TableSortLabel
      active={'ranking' === sortBy}
      direction={sortBy === 'ranking' ? sort : 'asc'}
      onClick={createSortHandler('ranking')}
      hideSortIcon
    >
      {translate('components.scheduleStats.labels.ranking')}
    </TableSortLabel>
  </TableCell>
}

const RankCell = ({ row, schedule, rankingCounts, sortBy }) => {
  const locale = useLocale();

  return <TableCell component="th" scope="row" align="center" style={{ backgroundColor: 'ranking' === sortBy ? blue[50] : undefined }}>
    <RecordContextProvider value={{ ranking: row.ranking, ...schedule }}>
      <FunctionField source="ranking" render={record => rankingCounts[row.ranking] > 1 && locale === 'en' ? `T${record.ranking}` : record.ranking } />
    </RecordContextProvider>
  </TableCell>
}

const SortableHeader = ({ properties, renderHeaders, sort, sortBy, onRequestSort, hidden }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const classes = useStyles();

  const genericLocale = getGenericLocale(locale);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  if (hidden) return null;
  return <TableHead>
    <TableRow>
      <RankHeader sort={sort} sortBy={sortBy} createSortHandler={createSortHandler} translate={translate} />
      {renderHeaders && renderHeaders({ sort, sortBy, createSortHandler, translate })}
      {properties.map(property => {
        const { id, displayOptions } = property;
        const description = translateApiProperty(displayOptions, 'description', genericLocale);
        const abbreviation = translateApiProperty(displayOptions, 'abbreviation', genericLocale);
        const active = id === sortBy;

        return <TableCell key={id} sortDirection={sortBy === id ? sort : false} align="center" style={{ backgroundColor: sortBy === id ? blue[50] : undefined }}>
          <Tooltip title={description} placement="top">
            <TableSortLabel className={classes.label} active={active} direction={sortBy === id ? sort : 'asc'} onClick={createSortHandler(id)} hideSortIcon>
              {abbreviation}
            </TableSortLabel>
          </Tooltip>
        </TableCell>
      })}
    </TableRow>
  </TableHead>
}

const TableBody = ({ data, properties, renderColumns, schedule, fixedColumns, sortBy }) => {
  if (!data?.length) return <TableRow>
    <TableCell colSpan={properties.length + (fixedColumns || 0)}>
      <NoResults />
    </TableCell>
  </TableRow>

  const rankingCounts = countRankings(data);

  return <MUITableBody>
    {data.map((row) => (
      <TableRow key={row.id}>
        <RankCell row={row} schedule={schedule} rankingCounts={rankingCounts} sortBy={sortBy} />
        {renderColumns && renderColumns(row, schedule, sortBy)}
        {properties.map(property => {
          const { id, displayOptions } = property;
          const stat = format(getPropertyValue(row, property), displayOptions);
          return <TableCell key={id} align="center" style={{ backgroundColor: sortBy === id ? blue[50] : undefined }}>{stat}</TableCell>
        })}
      </TableRow>
    ))}
  </MUITableBody>
}

const Filters = ({ filterComponents = [] }) => {
  const classes = useStyles();
  const { groupIds } = useScheduleStatsContext();

  const hasFilters = filterComponents.some(filter => {
    return filter.key === 'group' ? groupIds?.length > 0 : true;
  })

  if (!hasFilters) return null;
  return <div className={classes.filtersContainer}>
    {filterComponents}
  </div>
}

const ScheduleStatsContext = createContext();

export const useScheduleStatsContext = () => useContext(ScheduleStatsContext);

export const ScheduleStatsTable = ({
  resource,
  filter: defaultFilters,
  filters: filterComponents,
  sort: defaultSort,
  renderColumns,
  renderHeaders,
  fixedColumns
}) => {
  const schedule = useRecordContext();
  const classes = useStyles();
  const [sortBy, setSortBy] = useState(defaultSort?.field || 'id');
  const [sort, setSort] = useState(defaultSort?.order || 'asc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState(defaultFilters);

  const { data, loading: dataLoading } = useScheduleStats(resource, { scheduleId: schedule?.id, type: filters?.type });
  const { data: schema, loading: schemaLoading } = useSchema(data?.[0]?.schemaId);

  const filteredData = useMemo(() => data?.filter(row => filterStats(row, filters)), [data, filters]);
  const sortedData = useMemo(() => {
    return orderBy(filteredData, [sortBy], [sort])
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [filteredData, sortBy, sort, page, rowsPerPage]);

  const properties = getSchemaProperties(schema, data);

  const handleRequestSort = (event, property) => {
    const isDesc = sortBy === property && sort === 'desc';
    setSort(isDesc ? 'asc' : 'desc');
    setSortBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const loading = dataLoading || schemaLoading;

  const context = useMemo(() => ({
    schedule,
    teamIds: data?.map(row => row.teamId).filter(Boolean),
    groupIds: data?.map(row => row.groupId).filter(Boolean),
    page,
    sort,
    sortBy,
    filters,
    setPage,
    setSort,
    setSortBy,
    setFilters
  }), [data, schedule, filters, page, sortBy, sort]);

  return (
    <ScheduleStatsContext.Provider value={context}>
      {loading && <LinearProgress />}
      {!loading && <>
        <Filters filterComponents={filterComponents} />
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <SortableHeader properties={properties} renderHeaders={renderHeaders} sort={sort} sortBy={sortBy} onRequestSort={handleRequestSort} hidden={!sortedData?.length} />
            <TableBody data={sortedData} properties={properties} renderColumns={renderColumns} schedule={schedule} sortBy={sortBy} fixedColumns={fixedColumns} />
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.pagination}
          count={filteredData?.length}
          page={page}
          rowsPerPageOptions={[10, 25, 50]}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>}
    </ScheduleStatsContext.Provider>
  )
}
