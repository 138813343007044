import React, { Fragment, useState } from 'react';
import { BulkActionsToolbar, FilterButton, ListBase, ListContextProvider, ListToolbar, Pagination, ResourceContextProvider, TopToolbar, useListContext, useRecordContext, useReferenceManyFieldController, useResourceContext, useTranslate } from 'react-admin';
import { Card, CardHeader as MuiCardHeader, Divider, styled, IconButton, Menu, MenuItem, makeStyles, Dialog, useMediaQuery } from '@material-ui/core';
import { Close, ExpandLess, ExpandMore, MoreVert, ZoomOutMap } from '@material-ui/icons';

import NoResults from '../NoResults';

const CardHeader = styled(MuiCardHeader)({
  cursor: 'pointer',
})

const CardContentInner = styled('div')({
  padding: '0 8px',
})

const Actions = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '2px 8px',
})

const Collapsable = styled('span')(({ open }) => ({
  display: !open ? 'none' : '',
}))

const ActionsMenu = ({ actions }) => {
  const [ anchor, setAnchor ] = useState(null)
  const toggle = e => setAnchor(anchor => anchor ? !anchor : e.currentTarget)

  if (!actions?.length) return null;
  return <>
    <IconButton onClick={toggle} size="small"><MoreVert /></IconButton>
    <Menu
      anchorEl={anchor}
      open={anchor != null}
      onClose={() => setAnchor(null)}
    >
      {actions.map(action => <MenuItem dense>{action}</MenuItem>)}
    </Menu>
  </>
}

const useActionStyles = makeStyles(() => ({
  root: {
    '@media (max-width: 900px)': {
      width: 'calc(100% - 16px)',
    }
  }
}))

export const ActionsToolbar = ({ addButton, actions = [], filters }) => {
  const classes = useActionStyles();
  // TODO: figure out how to deal with add buttons + menu item nicely
  // const defaultActions = actions?.filter(action => action?.props?.alwaysOn);
  // const menuActions = actions?.filter(action => !action?.props?.alwaysOn);

  const renderActions = Array.isArray(actions) ? actions.map(action => <div>{action}</div>) : <div>{actions}</div>

  // having a button as a flex-child makes them missaligned wih the rest. Thats why we use empty divs
  return <TopToolbar classes={classes}>
    <FilterButton filters={filters || []} />
    <div>{addButton && React.cloneElement(addButton, { ...addButton.props, size: 'small' })}</div>
    {renderActions}
  </TopToolbar>
}

const useListStyles = makeStyles(theme => {
  return ({
    toolbar: {
      lineHeight: ({ filters, compactToolbar }) => compactToolbar ? null : `${theme.spacing(filters?.length ? 7 : 6)}px`,
      minHeight: ({ filters }) => theme.spacing(filters?.length ? 7 : 6),
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'calc(100vw - 32px)',
        flexDirection: 'column',
        paddingLeft: theme.spacing(1),
      },
      '& .MuiFormControl-marginNormal': {
        marginBlock: theme.spacing(1), // vertically align formControls
      },
      '& .MuiFormControl-marginDense': {
        marginBlock: theme.spacing(.5), // vertically align formControls
      }
    },
  });
})

export const ListView = ({
  filters,
  fill,
  addButton,
  addPosition = 'header',
  actions,
  headerRow,
  alert,
  rowsPerPageOptions,
  labelDisplayedRows,
  noResultsText,
  pagination = <Pagination
    rowsPerPageOptions={rowsPerPageOptions}
    labelDisplayedRows={labelDisplayedRows}
    labelRowsPerPage=""
  />,
  bulkActionButtons,
  open,
  children,
  compactToolbar = false,
}) => {
  const classes = useListStyles({ filters, fill, compactToolbar })
  const { total, loaded, loading, perPage } = useListContext();

  const hasBulkActions = bulkActionButtons !== false && React.isValidElement(bulkActionButtons)
  const actionsToolbar = <ActionsToolbar addButton={addPosition === 'header' && addButton} actions={actions} filters={filters} />

  const noResultsFound = loaded && total <= 0;
  const showToolbar = (filters?.length > 0 || (addButton && addPosition === 'header' && !noResultsFound) || actions?.length > 0)
  const showHeaderRow = headerRow !== false && React.isValidElement(headerRow)
  const showFooter = loaded && (total > perPage || (addButton && addPosition === 'footer'))

  return <Collapsable open={open}>
    {showToolbar && <CardContentInner>
      <ListToolbar filters={filters} actions={actionsToolbar} classes={classes} />
    </CardContentInner>}
    {showToolbar && <Divider />}
    {showHeaderRow && headerRow}
    {showHeaderRow && <Divider />}
    {alert}
    {hasBulkActions !== false && (
      <BulkActionsToolbar>
        {bulkActionButtons}
      </BulkActionsToolbar>
    )}
    {children && React.cloneElement(children, {
      hasBulkActions,
      loading,
      loaded,
      ...children.props,
    })}
    {noResultsFound
      ? <NoResults addButton={addButton} noResultsText={noResultsText} />
      : showFooter
        ? <Actions>
          <span>{addPosition === 'footer' && addButton}</span>
          {!noResultsFound ? pagination : null}
        </Actions>
        : null
    }
  </Collapsable>
}

const ReferenceManyBase = ({ children, ...props }) => {
  const {
    basePath,
    filter,
    page = 1,
    perPage,
    reference,
    sort,
    source = 'id',
    target,
  } = props;
  const record = useRecordContext(props);
  const resource = useResourceContext(props);

  const value = useReferenceManyFieldController({
    basePath,
    filter,
    page,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  });

  return <ListContextProvider value={value}>
    {children}
  </ListContextProvider>
}

const Expandable = ({ children, dialogOpen, dialogProps, ...props }) => {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  if (!dialogOpen) return React.cloneElement(children, children.props)

  return <Dialog
    open={dialogOpen}
    maxWidth="xl"
    fullWidth
    fullScreen={fullScreen}
    PaperProps={dialogProps?.fullHeight && !fullScreen ? { style: { minHeight: '90%', maxHeight: '90%' } } : undefined}
    {...dialogProps}
  >
    {React.cloneElement(children, { ...children.props, dialogOpen })}
  </Dialog>
}

export default function ListCard({
  title,
  subtitle,
  headerAction,
  reference,
  resource = reference,
  filters = null,
  perPage = 25,
  rowsPerPageOptions = [25, 50, 100],
  labelDisplayedRows = () => null,
  pagination,
  actions,
  addButton,
  addPosition,
  bulkActionButtons,
  alert,
  icon,
  collapsable = false,
  expandableComponent: ExpandableComponent,
  expandIcon: ExpandIcon,
  expandable = false,
  onExpand,
  dialogProps,
  component: Component = Card,
  componentProps,
  base: Base = reference != null ? ReferenceManyBase : ListBase,
  baseProps,
  children,
  ...props
}) {
  const translate = useTranslate();
  const [ open, setOpen ] = useState(!collapsable);
  const [ loaded, setLoaded ] = useState(!collapsable);
  const [ dialogOpen, setDialogOpen ] = useState(false);

  const onToggle = (e) => {
    if (dialogOpen) {
      return onToggleDialog(e)
    }
    setLoaded(true);
    setOpen(open => !open);
  }

  const onToggleDialog = (e) => {
    if (typeof onExpand === 'function') {
      onExpand();
    }
    e.stopPropagation();
    setDialogOpen(!dialogOpen);
  }

  Component = dialogOpen ? Fragment : Component
  ExpandIcon = ExpandIcon || <ZoomOutMap />

  return <Expandable dialogOpen={dialogOpen} dialogProps={dialogProps}>
    <Component {...componentProps}>
      {title && <CardHeader
        avatar={icon}
        title={translate(title, { _: title, smart_count: 1 })}
        subheader={subtitle && translate(subtitle, { _: subtitle, smart_count: 1 })}
        action={<>
          {open && headerAction}
          {expandable && open && <IconButton onClick={onToggleDialog}>{dialogOpen ? <Close /> : ExpandIcon}</IconButton>}
          {!dialogOpen && <IconButton>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
        </>}
        titleTypographyProps={{ variant: "subtitle2", gutterBottom: false }}
        subheaderTypographyProps={{ variant: "body2", gutterBottom: false }}
        onClick={onToggle}
      />}
      {title && <Divider />}

      {loaded && <ResourceContextProvider value={resource}>
        <Base
          reference={reference}
          perPage={perPage}
          syncWithLocation={false}
          {...baseProps}
          {...props}
        >
          <ListView
            filters={filters}
            rowsPerPageOptions={rowsPerPageOptions}
            labelDisplayedRows={labelDisplayedRows}
            pagination={pagination}
            addButton={addButton}
            addPosition={addPosition}
            actions={actions}
            bulkActionButtons={bulkActionButtons}
            alert={alert}
            open={open}
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            {...props}
          >
            {(expandable && dialogOpen && ExpandableComponent) || children}
          </ListView>
        </Base>
      </ResourceContextProvider>}
    </Component>
  </Expandable>
}
