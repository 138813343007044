import React from 'react';
import { useRecordContext } from 'react-admin';

import { useSeason } from '../../../common/inputs/SeasonSelector';
import { isAuthorized } from '../../../common/Authorize';
import ListCard from '../../../common/cards/ListCard';

import { ScheduleGrid } from '../../schedules/ScheduleGrid';
import { AddScheduleButton } from '../../schedules/ScheduleModal';
import { CategoryInput } from '../../categories/CategoryInput';
import { ScheduleIcon } from '../../icons';

export default ({ showOffice = true, filter = {}, collapsable, ...props }) => {
  const office = useRecordContext(props);
  const seasonId = useSeason();

  const canAddSchedule = isAuthorized(office, "schedules", "create");
  const filters = [
    <CategoryInput source="categoryId" multiple variant="outlined" alwaysOn />,
  ]

  return <ListCard
    title="resources.schedules.labels.card.title"
    icon={<ScheduleIcon />}
    reference="schedules"
    target="officeId"
    filter={{ seasonId, ...filter }}
    filters={filters}
    sort={{
      field: ['category.order', 'office.name', 'type', 'name'],
      order: ['ASC', 'DESC', 'ASC', 'ASC'],
    }}
    collapsable={collapsable}
    addButton={canAddSchedule && <AddScheduleButton initialValues={{ seasonId, officeId: office?.id }} />}
  >
    <ScheduleGrid rowClick="show" showOffice={showOffice} />
  </ListCard>
}
