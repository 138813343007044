import React, { useState } from "react"
import { RecordContextProvider, useDataProvider, useNotify } from "react-admin"
import { Card, LinearProgress, makeStyles } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

import { FF_DRAFT_GAMES } from "@hisports/common/featureFlags";

import { useFlag } from "../../../http";
import { useScheduleSettings } from "../../scheduleSettings";

import { AddDraftDialog } from "../../drafts/dialogs/DraftDialog";
import ScheduleSettingsForm from '../../scheduleSettings/ScheduleSettingsForm'

import { useScheduleContext } from "./WizardContext";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(3, 4),
  },
}));

export default ({ toolbar, handleNext }) => {
  const isEnabled = useFlag()
  const notify = useNotify();
  const history = useHistory()
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [ schedule ] = useScheduleContext();
  const [ showDraft, setShowDraft ] = useState(false);
  const { data: scheduleSettings, loaded } = useScheduleSettings(schedule?.id);

  const onSubmit = async values => {
    if (!isEnabled(FF_DRAFT_GAMES)) setShowDraft(true);
    try {
      await dataProvider.update("scheduleSettings", {
        id: values.id,
        data: values,
      })
      if (isEnabled(FF_DRAFT_GAMES)) history.push(`/schedules/${values.id}/show/scheduler`)
    } catch (error) {
      notify(error.message, 'error');
    }
  }

  return <Card>
    <RecordContextProvider value={scheduleSettings}>
      {loaded
        ? <ScheduleSettingsForm toolbar={toolbar} save={onSubmit} classes={classes} schedule={schedule} />
        : <LinearProgress color="primary" variant="indeterminate" />
      }
    </RecordContextProvider>
    {!isEnabled(FF_DRAFT_GAMES) && <AddDraftDialog schedule={schedule} isOpen={showDraft} handleClose={() => setShowDraft(false)} showSchedule showBrackets={['Playoffs', 'Tournament'].includes(schedule?.type)} showDraftGamesGenerator />}
  </Card>
}
