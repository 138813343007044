import React from 'react';
import { Datagrid, useListContext, useRecordContext, useTranslate } from 'react-admin';
import moment from 'moment';

import DateField from '../../common/fields/DateField';
import NoResults from '../../common/NoResults';

import { AccountField } from '../accounts/AccountField';
import { ParticipantField } from '../participants/ParticipantField';
import { TargetField } from '../targets/TargetField';
import { TargetTypeField } from '../targets/TargetTypeField';
import { GroupsField } from '../groups/GroupsField';

import { RoleField } from './RoleField';
import { RoleDatagrid } from './RoleModal';
import { CategoriesField } from '../categories/CategoriesField';

const PrincipalField = (props) => {
  const role = useRecordContext(props);
  if (!role) return null;

  switch (role?.principalType) {
    case 'Account': return <AccountField {...props} />
    case 'Participant': return <ParticipantField {...props} />
    default: return null;
  }
}

const ExpiryField = props => {
  const translate = useTranslate();
  const role = useRecordContext(props);
  const expiry = role?.[props.source];

  if (expiry && moment.utc(expiry, 'YYYY-MM-DD').isBefore(moment(), 'day')) return translate('resources.permissions.labels.expired');
  return <DateField {...props} />
}

const has = (ids, data, property) =>
  ids?.some(id => data?.[id]?.[property]?.length > 0)

export const RoleGrid = ({ showAccount = true, showOffice = true, showSchedule = false, ...props }) => {
  const { ids = [], data } = useListContext(props);

  const showGroups = has(ids, data, 'groupIds');
  const showCategories = has(ids, data, 'categoryIds')
  const showExpiry = has(ids, data, 'expiry');

  return <RoleDatagrid size="medium">
    {showOffice && <TargetTypeField source="targetType" allowEmpty label="" />}
    {showOffice && <TargetField source="targetId" allowEmpty />}
    {showAccount && <PrincipalField source="principalId" />}
    <RoleField source="roleId" />
    {showCategories && <CategoriesField source="categoryIds" emptyText="ra.message.all" allowEmpty />}
    {showGroups && <GroupsField source="groupIds" showType emptyText="ra.message.all" allowEmpty />}
    {showExpiry && <ExpiryField source="expiry" format="LL" yearFormat="LL" />}
  </RoleDatagrid>
}

export const CommitteeRoleGrid = (props) => {
  const { ids = [], data } = useListContext(props);

  const showGroups = has(ids, data, 'groupIds');
  const showCategories = has(ids, data, 'categoryIds')
  const showExpiry = has(ids, data, 'expiry');

  return <Datagrid size="medium" empty={<NoResults />}>
    <PrincipalField source="principalId" label="resources.participants.fields.name" />
    <TargetField source="targetId" allowEmpty label="resources.permissions.values.targetType.Office" />
    {showCategories && <CategoriesField source="categoryIds" emptyText="ra.message.all" allowEmpty />}
    {showGroups && <GroupsField source="groupIds" showType emptyText="ra.message.all" allowEmpty />}
    {showExpiry && <ExpiryField source="expiry" format="LL" yearFormat="LL" />}
  </Datagrid>
}
