import React from 'react';
import { useTranslate } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  button: {
    color: 'white',
    padding: theme.spacing(0.25, 1),
  }
}))

const ScheduleSequenceError = ({ errorMsg, scheduleId, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const translate = useTranslate();

  const handleButtonClick = () => {
    history.push({
      pathname: `/schedules/${scheduleId}/show/games`,
      state: { focus: 'sequence' }
    });
  };

  return <div className={classes.container}>
    {errorMsg}
    {scheduleId && <Button className={classes.button} showIcon={false} onClick={handleButtonClick} {...props} >
      {translate('resources.schedulesequences.actions.go_to')}
    </Button>}
  </div>
}

export default ScheduleSequenceError;
