import React, { useRef } from 'react';
import { SimpleForm, useTranslate, NumberInput } from 'react-admin';
import { useFormState } from "react-final-form";
import createCalculator from 'final-form-calculate';
import moment from 'moment-timezone';
import { Grid, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab';

import { isEmpty } from '@hisports/parsers';

import Toolbar from '../../common/ra/Toolbar';
import { CupScheduleTypeEnumInput, DayEnumInput, ScheduleTypeEnumInput, TournamentScheduleTypeEnumInput } from '../../common/inputs/EnumInputs';
import { InlineDateInput } from '../../common/inputs/DateInput';
import { CategoryInput } from '../categories/CategoryInput';

const validate = values => {
  const errors = {}

  if (!values.resource) errors.resource = 'ra.validation.required'

  if (values.limit == null) {
    errors.limit = 'ra.validation.required'
  } else if (values.limit < 0) {
    errors.limit = 'ra.validation.greater_than_or_equal_zero'
  }

  if (!values.periodType) errors.periodType = 'ra.validation.required'

  switch (values.periodType) {
    case 'Date Range': {
      errors.period = {};
      if (values.period == null) {
        errors.period.startDate = 'ra.validation.required'
      } else {
        if (!values.period.startDate) errors.period.startDate = 'ra.validation.required';
        if (!values.period.endDate) errors.period.endDate = 'ra.validation.required';
      }
      break;
    }
    case 'Days': {
      if (values.period == null) {
        errors.period = 'ra.validation.required';
      } else if (!(typeof values.period === 'number')) {
        errors.period = 'ra.validation.required';
      } else if (values.period <= 0) {
        errors.period = 'resources.schedulingConstraints.validations.greater_than_zero';
      }
      break;
    }
    case 'Weekdays': {
      if (values.period == null || !Array.isArray(values.period)) {
        errors.period = 'ra.validation.required';
      } else if (values.period.length <= 0) {
        errors.period = 'ra.validation.required';
      }
      break;
    }
  }

  return errors;
}

const inputProps = {
  resource: 'schedulingConstraints',
  basePath: '/schedulingConstraints',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const PeriodValueInput = props => {
  const { values: { periodType } } = useFormState();

  switch (periodType) {
    case 'Days':
      return <NumberInput source="period" label="resources.schedulingConstraints.fields.period.days" min={1} helperText="" {...inputProps} />
    case 'Weekdays':
      return <DayEnumInput source="period" label="resources.schedulingConstraints.fields.period.weekdays" multiple helperText="" {...inputProps} />
    case 'Date Range':
      return <Grid container spacing={2} fullWidth>
        <Grid item xs={6}>
          <InlineDateInput
            source="period.startDate"
            label="resources.schedulingConstraints.fields.period.startDate"
            {...inputProps}
          />
        </Grid>
        <Grid item xs={6}>
          <InlineDateInput
            source="period.endDate"
            label="resources.schedulingConstraints.fields.period.endDate"
            {...inputProps}
          />
        </Grid>
      </Grid>
  }

  return null;
}

const TypeInput = ({ office, ...props }) => {
  switch (office?.type) {
    case 'Tournament':
      return <TournamentScheduleTypeEnumInput {...props} {...inputProps} />
    case 'Cup':
      return <CupScheduleTypeEnumInput {...props} {...inputProps} />
    default:
      return <ScheduleTypeEnumInput {...props} {...inputProps} />
  }
}

export const getConstraintSummary = (values, translate) => {
  const { resource, limit, periodType, period } = values;
  if (!resource || !limit || !periodType || !period) return null;
  const verb = translate(`resources.schedulingConstraints.messages.${resource === "Arena" ? "host" : resource === "Team" ? "play" : "have"}`)
  const resourceText = translate(`resources.${resource.toLowerCase()}s.name`, 1)
  const summary = translate('resources.schedulingConstraints.messages.summary', { resource: resourceText.toLowerCase(), verb, smart_count: limit })
  switch (periodType) {
    case 'Days': {
      return `${summary} ${translate('resources.schedulingConstraints.messages.days', { smart_count: period })}`
    }
    case 'Date Range': {
      const start = moment.utc(period.startDate);
      const end = moment.utc(period.endDate);
      if (!start.isValid() || !end.isValid()) return null;
      return `${summary} ${translate('resources.schedulingConstraints.messages.between', { start: start.format("MMM D"), end: end.format("MMM D") })}`
    }
    case 'Weekdays': {
      if (!period?.length) return null;
      return `${summary} ${translate('resources.schedulingConstraints.messages.on', { weekdays: (period || []).join(', ') })}`
    }
  }
  return null;
}

const ConstraintSummary = () => {
  const translate = useTranslate();
  const { values } = useFormState();

  const summary = getConstraintSummary(values, translate)
  if (!summary) return null;

  return <Grid item xs={12}>
    <Alert severity="info">
      {summary}
    </Alert>
  </Grid>
}

export const ConstraintsForm = ({ initialValues, office, ...props }) => {
  const translate = useTranslate();
  const decorators = useRef([createCalculator({
    field: 'periodType',
    updates: {
      period: (periodType, values, prevValues) => {
        if (values.periodType === initialValues.periodType || isEmpty(prevValues)) return initialValues.period
        if (prevValues.periodType !== values.periodType) return null;
        return values.period;
      }
    }
  })])

  /* To be used for league Restrictions later */
  const disableOfficeInput = initialValues?.officeId != null && (office && ['League', 'Tournament', 'Cup'].includes(office.type))

  return <SimpleForm toolbar={<Toolbar />} validate={validate} decorators={decorators.current} initialValues={{ resource: 'Team', periodType: 'Days', ...initialValues }} {...props}>
    <Grid container spacing={2} fullWidth>
      <ConstraintSummary />
      {/* <Grid item xs={12}>
        <ResourceTypeEnumInput source="resource" radio helperText="" disabled {...inputProps} />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <NumberInput source="limit" min={0} helperText="" {...inputProps} />
      </Grid>
      {/* <Grid item xs={12}>
        <Typography variant="subtitle2">{translate('resources.schedulingConstraints.labels.period')}</Typography>
      </Grid> */}
      {/* <Grid item xs={12}>
        <PeriodTypeEnumInput source="periodType" radio helperText="" disabled {...inputProps} />
      </Grid> */}
      <Grid item xs={12} md={6}>
        <PeriodValueInput {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{translate('resources.schedulingConstraints.labels.restrictions')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <CategoryInput source="categoryIds" multiple helperText="resources.arenaslots.helpers.categoryIds" emptyText="ra.message.all" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TypeInput source="scheduleTypes" office={office} helperText="resources.schedulingConstraints.helpers.schedules" multiple {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
