import React from "react";
import { useTranslate } from "react-admin";
import { Typography } from "@material-ui/core";

const NoResults = ({ addButton, noResultsText }) => {
  const translate = useTranslate();
  if (noResultsText === false) return null;

  if (noResultsText && typeof noResultsText === 'string') {
    noResultsText = translate(noResultsText, { _: noResultsText })
  } else {
    noResultsText = translate('ra.navigation.no_results')
  }

  return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 16 }}>
    <Typography variant="body2" gutterBottom>{noResultsText}</Typography>
    {addButton && React.cloneElement(addButton, { ...addButton.props, size: 'large' })}
  </div>
}

export default NoResults;
