import React from 'react';
import { NotificationsOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { NotificationGrid } from '../../notifications/NotificationGrid';

export default props => {
  const seasonId = useSeason();

  return <ListCard
    title="resources.notifications.labels.sources"
    icon={<NotificationsOutlined fontSize="small" />}
    reference="notifications"
    target="participants"
    sort={{ field: 'targetType', order: 'ASC' }}
    filter={{ or: [{ seasonId }, { seasonId: null }] }}
    collapsable
  >
    <NotificationGrid showParticipant={false} showTarget showContact />
  </ListCard>
}
