import React from 'react';
import { Datagrid, TextField } from 'react-admin';

import { dedupe } from '@hisports/parsers';

import FunctionField from '../../common/fields/FunctionField'
import { SMAChip } from '../../common/chips/SMAChip';
import { SyncedIndicatorField } from '../../common/SyncAlert';

export const ParticipantGrid = ({ ids, ...props }) => {
  return <Datagrid size="medium" ids={dedupe(ids)} rowClick="show">
    <SyncedIndicatorField source="fullName" />
    <TextField source="HCRId" />
    <FunctionField source="accounts.email" label="resources.participants.fields.accountEmail" render={participant => <ParticipantAccountEmail participant={participant} />} />
  </Datagrid>
};

const ParticipantAccountEmail = ({ participant }) => {
  const identity = participant?.identities?.find(identity => identity.isPrimary);
  const accountId = identity?.accountId;
  const account = participant?.accounts?.find(account => account.id === accountId);

  return account?.externalId
    ? <>
      <span>{account?.email}</span>
      <SMAChip />
    </>
    : <span>{account?.email}</span>
}
