import React from 'react';
import { Datagrid, TextField, useListContext } from 'react-admin';

import { SyncedIndicatorField } from '../../common/SyncAlert';
import { CategoryField } from '../categories/CategoryField';
import { OfficeField } from '../offices/OfficeField';

export const TeamGrid = ({ showOffice = false, ...props }) => {
  const { ids = [], data } = useListContext(props);

  const teams = ids?.map(id => data?.[id]).filter(Boolean) || [];
  const showIds = teams.some(team => team.HCRId != null)

  return <Datagrid size="medium" {...props}>
    <SyncedIndicatorField source="name" />
    <CategoryField source="categoryId" />
    {showIds && <TextField source="HCRId" />}
    {showOffice && <OfficeField source="officeId" />}
  </Datagrid>
}
