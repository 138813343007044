import { orderBy } from '@hisports/common/src/lodash.js';

import { translateApiProperty } from "@hisports/common";

import { calculateEndTime, calculateGameTime, compareTime, isTimeBefore, isTimeEqual, isTimeValid } from "./gameTime.js";
import { INFRACTION_SEVERITY, LEGACY_RULEBOOK_SEASONS, PENALTY_SEVERITY } from "../constants.js";
import { getChoiceLabel, getDuplicateDurationFractionLabel, getLegacyDuration, getLegacyInfraction } from "./legacyPenalties.js";

export const isLegacyRulebookSeason = seasonId => !seasonId || LEGACY_RULEBOOK_SEASONS.includes(seasonId)

export const parseLegacyRulebookMeta = meta => {
  if (!meta) return meta
  if (!isLegacyRulebookSeason(meta.seasonId)) return meta

  const { durations, infractions, ...newMeta } = meta

  newMeta.types = durations
  newMeta.rules = infractions

  return newMeta
}

export const parseLegacyRulebookRules = (rules, seasonId) => {
  return parseLegacyRulebookMeta({ ...rules, seasonId })
}

// Linked penalties are a bundle of penalties created off of a rule option.
// This includes accumulation penalties caused by the rule option (from an accumulation rule).
// Ex. rule option resulted in A, B, C
// Passing any of them → A, B, C would get returned
export const getLinkedPenalties = (penalty = {}, teamPenalties = [], sport, excludeCurrentPenalty = false) => {
  if (!penalty.optionId) return []

  const originalPenalty = teamPenalties.find(teamPenalty => teamPenalty.id === penalty.id);
  if (!originalPenalty) return []

  let linkedPenalties = teamPenalties
    .filter(({ optionId, participantId, gameTime }) => {
      const isSameOption = optionId && originalPenalty.optionId && optionId === originalPenalty.optionId;
      const isSameParticipant = participantId && originalPenalty.participantId && participantId === originalPenalty.participantId;
      const isSameGameTime = gameTime && originalPenalty.gameTime && isTimeEqual(gameTime, originalPenalty.gameTime, sport)
      return isSameOption && isSameParticipant && isSameGameTime
    })

  if (excludeCurrentPenalty) {
    linkedPenalties = linkedPenalties.filter(linkedPenalty => linkedPenalty.id !== penalty.id)
  }

  return sortPenalties(linkedPenalties, sport)
}

// Subsequent penalties are all linked penalties after the first one.
// Ex. a rule option resulted in A, B, C
// Passing penalty A → B, C would get returned
// Passing penalty C → B, C would get returned
export const getSubsequentLinkedPenalties = (penalty = {}, teamPenalties = [], sport, excludeCurrentPenalty = false) => {
  const linkedPenalties = getLinkedPenalties(penalty, teamPenalties, sport);
  let subsequentLinkedPenalties = linkedPenalties.slice(1);

  if (excludeCurrentPenalty) {
    subsequentLinkedPenalties = subsequentLinkedPenalties.filter(linkedPenalty => linkedPenalty.id !== penalty.id)
  }

  return subsequentLinkedPenalties;
}

export const getSeverity = (penalty = {}, infractions = [], types, seasonId) => {
  let severity;

  if (!isLegacyRulebookSeason(seasonId)) {
    const infraction = infractions.find(infraction => infraction.id === penalty.infractionId)
    if (infraction) {
      severity = infraction.severity;
    }
  } else {
    const duration = getLegacyDuration(penalty.duration, types)
    if (duration) {
      severity = duration.severity;
    }
  }

  return severity;
}

export const hideEndTime = (infractionType = {}, seasonId) => {
  const { hideEndTime, duration, isShorthanded, isEjection } = infractionType;
  if (isLegacyRulebookSeason(seasonId)) {
    return !!hideEndTime;
  }
  return !duration || (!isShorthanded && isEjection)
}

export const getInfractionRule = (penalty = {}, infractions = [], rules = [], seasonId) => {
  if (!isLegacyRulebookSeason(seasonId)) {
    const infraction = infractions.find(infraction => infraction.id === penalty.infractionId) || {};
    return rules.find(rule => rule.id === infraction.ruleId);
  }
  return getLegacyInfraction(penalty.infraction, rules)
}

export const getInfractionType = (penalty = {}, infractions = [], types, seasonId) => {
  if (!isLegacyRulebookSeason(seasonId)) {
    const infraction = infractions.find(infraction => infraction.id === penalty.infractionId) || {};
    return (types || []).find(type => type.id === infraction.typeId);
  }
  return getLegacyDuration(penalty.duration, types)
}

export const getInfractionTypeDuration = (infractionType, seasonId, inMinutes = false) => {
  if (!infractionType) return;

  const seconds = (!isLegacyRulebookSeason(seasonId) ? infractionType.duration : infractionType.length) || 0

  if (inMinutes) {
    return Math.floor(seconds / 60);
  }

  return seconds
}

export const sumDurationMinutes = (infractions, types, seasonId) => (total, penalty) => {
  const infractionType = getInfractionType(penalty, infractions, types, seasonId)
  const minutes = getInfractionTypeDuration(infractionType, seasonId, true);
  return total + (minutes || 0);
}

export const getFractionLabel = (penalty = {}, infractions = [], types, rules = [], teamPenalties = [], sport, seasonId) => {
  if (isLegacyRulebookSeason(seasonId)) {
    const infractionType = getInfractionType(penalty, infractions, types, seasonId)
    return getDuplicateDurationFractionLabel(infractionType, penalty, teamPenalties, rules, sport);
  }

  const linkedPenalties = getLinkedPenalties(penalty, teamPenalties, sport).filter(linkedPenalty => linkedPenalty.infractionId === penalty.infractionId);
  if (linkedPenalties.length < 2) return;

  const getTotalMinutes = penalties => penalties.reduce(sumDurationMinutes(infractions, types, seasonId), 0)

  const penaltyIndex = linkedPenalties.findIndex(linkedPenalty => linkedPenalty.id === penalty.id);
  const elapsedPenalties = linkedPenalties.slice(0, (penaltyIndex + 1));

  const elapsedTotal = getTotalMinutes(elapsedPenalties);
  const total = getTotalMinutes(linkedPenalties);

  return `${elapsedTotal}/${total}`;
}

export const getInfractionRuleLabel = (rule, locale, separator = ' ') => {
  if (!rule || !rule.name) return;
  return [rule.code, translateApiProperty(rule, 'name', locale)].filter(Boolean).join(separator)
}

export const getInfractionRuleName = (rule, locale) => {
  if (!rule || !rule.name) return;
  return translateApiProperty(rule, 'name', locale)
}

export const getInfractionRuleCode = (rule) => {
  if (!rule || !rule.code) return;
  return `${rule.code}`
}

export const getInfractionOptionLabel = (option = {}, types, seasonId, locale) => {
  if (isLegacyRulebookSeason(seasonId)) {
    return getChoiceLabel(option, types)
  }

  return translateApiProperty(option, 'name', locale);
}

export const getInfractionOptionCodes = (option = {}, infractions = []) => {
  return (option.members || [])
    .sort((a, b) => a.order - b.order)
    .map(member => {
      const infraction = infractions.find(infraction => infraction.id === member.infractionId)
      if (!infraction && !infraction.code) return
      return infraction.code
    }).filter(Boolean)
}

export const getRuleSectionLabel = (section, locale) => {
  if (!section || !section.name) return
  return `${section.code ? `${section.code} - ` : ''}${translateApiProperty(section, 'name', locale)}`
}

export const isTimed = (infractionType = {}) => {
  // timed is a legacy property
  return requiresEndTime(infractionType) || infractionType.timed;
}

export const requiresIncidentReport = (infractionType = {}, severity) => {
  // require incident report is a legacy property
  return [INFRACTION_SEVERITY.HIGH, INFRACTION_SEVERITY.SEVERE].includes(severity) || infractionType.requireIncidentReport
}

export const requiresEmailReport = (severity) => {
  // PENALTY_SEVERITY are legacy values
  return [PENALTY_SEVERITY.HIGH, INFRACTION_SEVERITY.HIGH].includes(severity)
}

export const sortPenalties = (teamPenalties = [], sport) => {
  const sortedTeamPenalties = [...teamPenalties]

  // uses start time for hockey, game time for soccer / baseball
  sortedTeamPenalties
    .sort((a, b) => compareTime(
      a.startTime || a.gameTime,
      b.startTime || b.gameTime,
      sport))
    .sort((a, b) => {
      if (a.accumulationId && !b.accumulationId) return 1;
      if (!a.accumulationId && b.accumulationId) return -1;
      return 0;
    })

  return sortedTeamPenalties
}

const requiresEndTime = (infractionType) => {
  return infractionType && infractionType.duration && (infractionType.isShorthanded || !infractionType.isEjection)
}

const requiresServer = (infractionType) => {
  return infractionType && infractionType.isShorthanded
}

const matchesAccumulation = (accumulation, penalty) => {
  return accumulation.members.some(accumulationMember => accumulationMember.infractionId === penalty.infractionId);
}

export class PenaltyManager {
  constructor(meta, teamId, periods = []) {
    this.meta = meta;
    this.periods = periods;
    this.teamId = teamId;
    this.participantId = null;

    // game penalty or penalty option
    this.penalty = {
      teamId,
      participantId: null,
      infractionId: null,
      optionId: null,
      accumulationId: null,
      gameTime: null,
      startTime: null,
      endTime: null,
      servedById: null,
      isInjured: null,
    }

    this.infraction = null
    this.rule = null
    this.option = null
    this.accumulation = null

    this.originalPenalties = []
  }

  // set game penalty (requires an optionId)
  setPenalty(penalty, teamPenalties = []) {
    this.penalty.id = penalty.id;
    this.penalty.teamId = penalty.teamId;
    this.penalty.participantId = penalty.participantId;
    this.penalty.servedById = penalty.servedById;
    this.penalty.gameTime = penalty.gameTime;
    this.penalty.startTime = penalty.startTime;
    this.penalty.infractionId = penalty.infractionId;
    this.penalty.optionId = penalty.optionId;
    this.penalty.accumulationId = penalty.accumulationId;
    this.penalty.isInjured = penalty.isInjured;

    // editing a penalty
    if (this.penalty.id) {
      this.originalPenalties = getLinkedPenalties(penalty, teamPenalties, this.meta.sport)
    }

    if (penalty.isEnded) {
      this.penalty.endTime = penalty.endTime;
    }

    if (this.penalty.optionId) {
      this.option = this.meta.options.find(option => option.id === this.penalty.optionId);
    }

    if (!this.penalty.infractionId && this.option) {
      this.penalty.infractionId = this.option.members[0].infractionId
    }

    if (this.penalty.infractionId) {
      this.infraction = this.meta.infractions.find(infraction => infraction.id === this.penalty.infractionId);
      this.rule = this.meta.rules.find(rule => rule.id === this.infraction.ruleId);
      this.type = this.meta.types.find(type => type.id === this.infraction.typeId);
    }
  }

  // get linked game penalties
  getResultingPenalties(teamPenalties = []) {
    // get all infractions based off of option members
    let optionMembers = this.option.members.map(optionMember => {
      const infraction = this.meta.infractions.find(infraction => infraction.id === optionMember.infractionId)
      const rule = this.meta.rules.find(rule => rule.id === infraction.ruleId);
      const type = this.meta.types.find(type => type.id === infraction.typeId);

      return { ...optionMember, infraction, rule, type }
    })

    // sort by optionMember.order / type.servingOrder (pushing null/undefined to back of array)
    optionMembers = orderBy(optionMembers, [
      optionMember => optionMember.order == null ? undefined : optionMember.order,
      optionMember => optionMember.type.servingOrder == null ? undefined : optionMember.type.servingOrder
    ])

    // generate penalties based off of option members
    const resultingPenalties = optionMembers.map((option, index) => {
      const penalty = {
        teamId: this.penalty.teamId,
        participantId: this.penalty.participantId,
        gameTime: this.penalty.gameTime,
        startTime: this.penalty.startTime,
        infractionId: option.infraction.id,
        optionId: this.option.id,
        accumulationId: null,
        isInjured: this.penalty.isInjured,

        // legacy properties
        infraction: option.infraction.legacyName,
        duration: option.infraction.legacyDuration
      }
      let offset = 0

      // calculate offset only if the infraction type requires an end time
      if (requiresEndTime(option.type)) {
        // calculate offset based off of previous infractions
        offset = optionMembers.slice(0, index).reduce((offset, previousOptionMember) => {
          if (!requiresEndTime(previousOptionMember.type)) return offset
          return offset + previousOptionMember.type.duration
        }, 0)
      }

      // offset start time if necessary
      if (offset) {
        penalty.startTime = calculateGameTime(penalty.startTime, offset, this.periods);
      }

      // offset end time if necessary
      if (requiresEndTime(option.type)) {
        penalty.endTime = calculateEndTime(penalty.startTime, offset + option.type.duration, this.periods);
      }

      // case where user is editing end time of a specific penalty
      if (this.penalty.infractionId === option.infraction.id && this.penalty.endTime) {
        penalty.endTime = this.penalty.endTime;
      }

      if (requiresServer(option.type)) {
        penalty.servedById = this.penalty.servedById;
      }

      return penalty
    })

    // bundle up team penalties into an array of linked penalties for the player that were prior to the selected penalty
    // this array is used to check accumulation counts
    const pastParticipantLinkedPenalties = teamPenalties.reduce((linkedTeamPenalties, teamPenalty) => {
      const linkedPenalties = getLinkedPenalties(teamPenalty, teamPenalties, this.meta.sport)
      const alreadyExist = linkedTeamPenalties.some(linkedPenalties => linkedPenalties.some(linkedPenalty => linkedPenalty.id === teamPenalty.id))
      if (alreadyExist || !linkedPenalties.length) {
        return linkedTeamPenalties
      }
      return [...linkedTeamPenalties, linkedPenalties]
    }, [])
      .filter((linkedPenalties = []) => {
        const firstPenalty = linkedPenalties[0]
        const isGameTimeBefore = isTimeBefore(this.penalty.gameTime, firstPenalty.gameTime, this.meta.sport, true)
        const isSamePlayer = this.penalty.participantId === firstPenalty.participantId
        const areOriginalPenalties = this.originalPenalties.some(originalPenalty => linkedPenalties.some(linkedPenalty => linkedPenalty.id === originalPenalty.id))

        return isGameTimeBefore && isSamePlayer && !areOriginalPenalties
      })

    // check for any fulfilled accumulations
    const fulfilledAccumulations = this.meta.accumulations.filter(accumulation => {
      const count = [...pastParticipantLinkedPenalties, resultingPenalties].filter(linkedPenalties => {
        return linkedPenalties.some(linkedPenalty => matchesAccumulation(accumulation, linkedPenalty))
      }).length

      return count !== 0 && count % accumulation.count === 0;
    })

    // create or replace penalties based on fulfilled accumulations
    fulfilledAccumulations.forEach(accumulation => {
      const infraction = this.meta.infractions.find(infraction => infraction.id === accumulation.infractionId);
      const penalty = {
        teamId: this.penalty.teamId,
        participantId: this.penalty.participantId,
        gameTime: this.penalty.gameTime,
        startTime: this.penalty.startTime,
        optionId: this.option.id,
        infractionId: accumulation.infractionId,
        accumulationId: accumulation.id,
        isInjured: this.penalty.isInjured,

        // legacy properties
        infraction: infraction.legacyName,
        duration: infraction.legacyDuration
      }

      if (accumulation.isReplacement) {
        const resultingPenaltyIndex = resultingPenalties.findIndex(resultingPenalty => accumulation.members.some(accumulationMember => resultingPenalty.infractionId === accumulationMember.infractionId))
        if (resultingPenaltyIndex >= 0) {
          resultingPenalties[resultingPenaltyIndex] = penalty
        }
      } else {
        resultingPenalties.push(penalty)
      }
    })

    return resultingPenalties;
  }

  selectRule(rule) {
    this.resetRuleOption();

    // unselect
    if (this.rule && this.rule.id == rule.id) {
      this.rule = null;
      return;
    }

    this.rule = rule;

    // automatically select first option
    const options = this.meta.options
      .filter(option => option.ruleId === this.rule.id)
      .sort((a, b) => a.order - b.order)
    if (options.length) {
      this.selectRuleOption(options[0]);
    }
  }

  selectRuleOption(option) {
    if (this.isRuleOptionSelected(option)) {
      // unselect
      this.option = null;
      return;
    }

    this.option = option;
  }

  resetRuleOption() {
    this.option = null;
  }

  isRuleOptionSelected(option) {
    return this.option && this.option.id === option.id
  }

  isValidPlayers() {
    return this.penalty && this.penalty.participantId != null;
  }

  isValidTime() {
    if (!this.penalty || !this.penalty.gameTime) return false;
    return isTimeValid(this.penalty.gameTime, this.periods, this.meta.sport);
  }

  isValidStartTime() {
    if (this.meta.sport !== 'Hockey') return true;
    if (isTimeBefore(this.penalty.gameTime, this.penalty.startTime, this.meta.sport)) return false;
    return isTimeValid(this.penalty.startTime, this.periods, this.meta.sport);
  }

  isValidInfraction() {
    return this.rule && this.option && this.option.members.length
  }
}
