import React from 'react';
import { SelectInput, SelectArrayInput, useTranslate, useResourceContext, RadioButtonGroupInput, useLocale } from 'react-admin';
import { useFormState } from 'react-final-form';
import moment from 'moment';

import {
  FIRST_PERIOD,
  SECOND_PERIOD,
  THIRD_PERIOD,
  OVERTIME_PERIOD,
  SHOOTOUT_PERIOD,
} from '@hisports/scoresheet/src/constants';
import { getSeasonDisplayName, SEASONS } from '@hisports/common/src/seasons';

import { useSport } from '../../http';
import { ROUND_TYPES } from '../../resources/drafts/generator/InfoStep';
import {
  METHODS as GENERATOR_METHODS,
  TYPES as GENERATOR_TYPES,
  ROUND_TYPES as GENERATOR_ROUND_TYPES,
  WEEKDAYS_TYPES as GENERATOR_WEEKDAYS_TYPES,
} from '../../resources/schedules/dialogs/generator/Generator';
import { formatCurrency } from '../../locale';

import FunctionField from '../fields/FunctionField';
import AutocompleteInput from './AutocompleteInput';
import { useSeason } from './SeasonSelector';

const value = (id, name, description) => ({ id, name, description })
export const createChoices = choices => choices.map(choice => {
  if (typeof choice === 'string') return value(choice, choice)
  return choice;
})

export const EnumInput = ({
  select = false,
  radio = false,
  sortValues = true,
  multiple,
  options = {},
  emptyLabel = '',
  defaultValue,
  ...props
}) => {
  const translate = useTranslate();
  const sport = useSport();

  let Input;
  if (radio) {
    Input = RadioButtonGroupInput
  } else if (!select) {
    Input = AutocompleteInput
  } else if (multiple) {
    Input = SelectArrayInput;
  } else {
    Input = SelectInput;
  }

  props.choices = props.choices?.filter(choice => !choice?.sports?.length || choice.sports.includes(sport));

  return <Input
    multiple={multiple}
    options={{
      // displayEmpty: !!emptyLabel,
      renderValue: selected => {
        if (!selected.length) return emptyLabel;

        const choices = props.choices.map(choice => choice.id);
        let values = [...selected];
        if (sortValues) {
          values.sort((a, b) => choices.indexOf(a) - choices.indexOf(b))
        }
        values = values.map(value => {
          const choice = props.choices.find(choice => choice.id === value);
          return translate(choice.name, { _: choice.name });
        })
        if (values.length === 1) return values[0];
        if (values.length === 2) return `${values[0]} ${translate('ra.function.and')} ${values[1]}`

        const first = values.slice(0, -1);
        const last = values[values.length - 1];
        return `${first.join(', ')}, ${translate('ra.function.and')} ${last}`
      },
      defaultValue: defaultValue && multiple ? [defaultValue] : defaultValue,
      ...options
    }}
    disableSort
    {...props}
  />
}

export const EnumField = props =>
  <FunctionField {...props} render={(record, source, resource, translate) => {
    const value = record?.[source];
    if (!value) return;
    return translate(`resources.${resource}.values.${source}.${value}`, { _: value })
  }} />

export const SeasonInput = props => {
  const sport = useSport();
  const seasonId = useSeason();
  const choices = SEASONS.map(seasonId => ({ id: seasonId, name: getSeasonDisplayName(seasonId, sport) }))
  return <AutocompleteInput defaultValue={seasonId} {...props} choices={choices} />
}

export const ScheduleTypeEnumInput = props => {
  const resource = useResourceContext(props);
  return <EnumInput {...props} choices={[
    { id: 'Exhibition', name: `resources.${resource}.values.${props.source}.Exhibition` },
    { id: 'Placement', name: `resources.${resource}.values.${props.source}.Placement`, sports: ['Hockey'] },
    { id: 'League', name: `resources.${resource}.values.${props.source}.League` },
    { id: 'Playoffs', name: `resources.${resource}.values.${props.source}.Playoffs` },
    { id: 'Tournament', name: `resources.${resource}.values.${props.source}.Tournament` },
    { id: 'Cup', name: `resources.${resource}.values.${props.source}.Cup`, sports: ['Soccer'] },
    { id: 'Championship', name: `resources.${resource}.values.${props.source}.Championship`, sports: ['Hockey'] },
  ]} />
}

export const TournamentScheduleTypeEnumInput = props => {
  const resource = useResourceContext(props);
  return <EnumInput {...props} choices={[
    { id: 'Tournament', name: `resources.${resource}.values.${props.source}.Tournament` },
    { id: 'Exhibition', name: `resources.${resource}.values.${props.source}.Exhibition` },
  ]} />
}

export const CupScheduleTypeEnumInput = props => {
  const resource = useResourceContext(props);
  return <EnumInput {...props} initialValue="Cup" choices={[
    { id: 'Cup', name: `resources.${resource}.values.${props.source}.Cup` },
    { id: 'Exhibition', name: `resources.${resource}.values.${props.source}.Exhibition` },
  ]} />
}

export const PeriodTypeEnumInput = props => {
  const resource = useResourceContext(props);
  return <EnumInput {...props} select choices={[
    { id: 'Days', name: `resources.${resource}.fields.period.days` },
    { id: 'Weekdays', name: `resources.${resource}.fields.period.weekdays` },
    { id: 'Date Range', name: `resources.${resource}.fields.period.dateRange` },
  ]} />
}

export const ResourceTypeEnumInput = props => {
  const resource = useResourceContext(props);
  return <EnumInput {...props} select choices={[
    { id: 'Team', name: `resources.${resource}.values.Team` },
    { id: 'Schedule', name: `resources.${resource}.values.Schedule` },
    { id: 'Arena', name: `resources.${resource}.values.Arena` },
  ]} />
}

export const GenderEnumInput = props => {
  const resource = useResourceContext(props);
  return <EnumInput {...props} defaultValue="Integrated" choices={[
    { id: 'Male', name: `resources.${resource}.values.${props.source}.Male` },
    { id: 'Female', name: `resources.${resource}.values.${props.source}.Female` },
    { id: 'Integrated', name: `resources.${resource}.values.${props.source}.Integrated` },
  ]} />
}

export const GameStatusEnumInput = props => {
  const translate = useTranslate();
  return <EnumInput
    {...props}
    choices={[
      { id: 'Active', name: 'resources.games.values.status.Active', type: 'resources.games.labels.active' },
      { id: 'Rescheduled', name: 'resources.games.values.status.Rescheduled', type: 'resources.games.labels.active' },
      { id: 'Rink Changed', name: 'resources.games.values.status.Rink Changed', type: 'resources.games.labels.active' },
      { id: 'Cancelled', name: 'resources.games.values.status.Cancelled', type: 'resources.games.labels.inactive' },
      { id: 'Postponed', name: 'resources.games.values.status.Postponed', type: 'resources.games.labels.inactive' },
      { id: 'Conflict', name: 'resources.games.values.status.Conflict', type: 'resources.games.labels.inactive' },
    ]}
    groupBy={({ type }) => translate(type)}
  />
}

export const GamePeriodEnumInput = props =>
  <EnumInput {...props} select choices={[
    { id: FIRST_PERIOD, name: 'resources.scoresheets.values.periods.1', sports: ['Hockey'] },
    { id: SECOND_PERIOD, name: 'resources.scoresheets.values.periods.2', sports: ['Hockey'] },
    { id: THIRD_PERIOD, name: 'resources.scoresheets.values.periods.3', sports: ['Hockey'] },
    { id: OVERTIME_PERIOD, name: 'resources.scoresheets.values.periods.OT', sports: ['Hockey'] },
    { id: SHOOTOUT_PERIOD, name: 'resources.scoresheets.values.periods.SO', sports: ['Hockey'] },
  ]}
  />

export const ArenaSlotTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Exhibition', name: `resources.arenaslots.values.types.Exhibition` },
    { id: 'Placement', name: `resources.arenaslots.values.types.Placement`, sports: ['Hockey'] },
    { id: 'League', name: `resources.arenaslots.values.types.League` },
    { id: 'Playoffs', name: `resources.arenaslots.values.types.Playoffs` },
    { id: 'Tournament', name: `resources.arenaslots.values.types.Tournament` },
    { id: 'Championship', name: `resources.arenaslots.values.types.Championship`, sports: ['Hockey'] },
    { id: 'Cup', name: `resources.arenaslots.values.types.Cup`, sports: ['Soccer'] },
    { id: 'Practice', name: 'resources.arenaslots.values.types.Practice' },
  ]} />

export const ActivityTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Training', name: 'resources.activities.values.type.Training' },
    { id: 'Meeting', name: 'resources.activities.values.type.Meeting' },
    { id: 'Meal', name: 'resources.activities.values.type.Meal' },
    { id: 'Accomodation', name: 'resources.activities.values.type.Accomodation' },
  ]} optionDescription="description" />

export const AssignmentStatusEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Active', name: 'resources.games.values.assignment.status.Active' },
    { id: 'Draft', name: 'resources.games.values.assignment.status.Draft', description: 'resources.games.messages.assignment.draft_status_description' },
  ]} optionDescription="description" />

export const OfficeTypeEnumInput = ({ hideOrganization = false, ...props }) => {
  const types = [
    { id: 'Branch', name: 'resources.offices.values.type.Branch' },
    { id: 'Region', name: 'resources.offices.values.type.Region' },
    { id: 'District', name: 'resources.offices.values.type.District' },
    { id: 'Association', name: 'resources.offices.values.type.Association' },
    { id: 'Administrative', name: 'resources.offices.values.type.Administrative' },
    { id: 'Zone', name: 'resources.offices.values.type.Zone' },
    { id: 'League', name: 'resources.offices.values.type.League' },
    { id: 'Tournament', name: 'resources.offices.values.type.Tournament' },
    { id: 'Cup', name: 'resources.offices.values.type.Cup', sports: ['Soccer'] },
  ]
  if (!hideOrganization) types.unshift({ id: 'Organization', name: 'resources.offices.values.type.Organization' })
  return <EnumInput {...props} choices={types} />
}

export const PlayerPositionsEnumInput = props =>
  <EnumInput {...props} multiple choices={[
    { id: 'F', name: 'resources.members.values.positions.F' },
    { id: 'C', name: 'resources.members.values.positions.C', sports: ['Hockey'] },
    { id: 'D', name: 'resources.members.values.positions.D', sports: ['Hockey'] },
    { id: 'G', name: 'resources.members.values.positions.G', sports: ['Hockey'] },
  ]} />

export const AssignmentPositionsEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Referee', name: 'resources.games.values.assignment.position.Referee' },
    { id: 'Linesperson', name: 'resources.games.values.assignment.position.Linesperson' },
    { id: 'LinespersonTwo', name: 'resources.games.values.assignment.position.LinespersonTwo' },
    { id: 'LinespersonThree', name: 'resources.games.values.assignment.position.LinespersonThree' },
    { id: 'Official', name: 'resources.games.values.assignment.position.Official', sports: ['Soccer'] },
    { id: 'Scorekeeper', name: 'resources.games.values.assignment.position.Scorekeeper', sports: ['Hockey', 'Baseball'] },
    { id: 'Timekeeper', name: 'resources.games.values.assignment.position.Timekeeper', sports: ['Hockey'] },
    { id: 'Supervisor', name: 'resources.games.values.assignment.position.Supervisor' },
  ]} />

export const MemberPositionsEnumInput = props => {
  const translate = useTranslate();
  return <EnumInput
    {...props}
    multiple
    choices={[
      { id: 'F', name: 'resources.members.values.positions.F', type: 'resources.members.labels.player' },
      { id: 'C', name: 'resources.members.values.positions.C', type: 'resources.members.labels.player', sports: ['Hockey'] },
      { id: 'D', name: 'resources.members.values.positions.D', type: 'resources.members.labels.player', sports: ['Hockey'] },
      { id: 'G', name: 'resources.members.values.positions.G', type: 'resources.members.labels.player', sports: ['Hockey'] },
      { id: 'Manager', name: 'resources.members.values.positions.Manager', type: 'resources.members.labels.staff' },
      { id: 'Head Coach', name: 'resources.members.values.positions.Head Coach', type: 'resources.members.labels.staff' },
      { id: 'Assistant Coach', name: 'resources.members.values.positions.Assistant Coach', type: 'resources.members.labels.staff' },
      { id: 'Goaltending Coach', name: 'resources.members.values.positions.Goaltending Coach', type: 'resources.members.labels.staff', sports: ['Hockey', 'Soccer'] },
      { id: 'Safety Person', name: 'resources.members.values.positions.Safety Person', type: 'resources.members.labels.staff' },
      { id: 'Trainer', name: 'resources.members.values.positions.Trainer', type: 'resources.members.labels.staff' },
    ]}
    groupBy={({ type }) => translate(type)}
  />
}

export const ContactTypeEnumInput = props =>
  <EnumInput {...props} radio choices={[
    { id: 'Email', name: 'resources.contacts.values.type.Email' },
    { id: 'Mobile', name: 'resources.contacts.values.type.Mobile' },
    { id: 'Home', name: 'resources.contacts.values.type.Home' },
  ]} />

export const AddressTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Home', name: 'resources.addresses.values.type.Home' },
    { id: 'Work', name: 'resources.addresses.values.type.Work' },
    { id: 'Other', name: 'resources.addresses.values.type.Other' },
  ]} />

export const LanguageTypeEnum = props =>
  <EnumInput {...props} radio choices={[
    { id: 'en', name: 'resources.contacts.values.language.en' },
    { id: 'fr', name: 'resources.contacts.values.language.fr' },
  ]} />

export const ProfileDexterityEnumInput = props =>
  <EnumInput {...props} select choices={[
    { id: 'Right', name: 'resources.profiles.values.dexterity.Right' },
    { id: 'Left', name: 'resources.profiles.values.dexterity.Left' },
  ]} />

export const DayEnumInput = ({ abbreviation = true, ...props }) => {
  return <EnumInput {...props} select choices={[
    { id: 'Sun', name: `ra.date.days.${abbreviation ? 'short' : 'long'}.Sun` },
    { id: 'Mon', name: `ra.date.days.${abbreviation ? 'short' : 'long'}.Mon` },
    { id: 'Tue', name: `ra.date.days.${abbreviation ? 'short' : 'long'}.Tue` },
    { id: 'Wed', name: `ra.date.days.${abbreviation ? 'short' : 'long'}.Wed` },
    { id: 'Thu', name: `ra.date.days.${abbreviation ? 'short' : 'long'}.Thu` },
    { id: 'Fri', name: `ra.date.days.${abbreviation ? 'short' : 'long'}.Fri` },
    { id: 'Sat', name: `ra.date.days.${abbreviation ? 'short' : 'long'}.Sat` },
  ]} />
}

export const AnnouncementTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    // { id: 'General', name: 'resources.announcements.values.type.General' },
    // { id: 'League', name: 'resources.announcements.values.type.League' },
    { id: 'Officiating', name: 'resources.announcements.values.type.Officiating' },
  ]} />

export const AnnouncementTargetTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Office', name: 'resources.announcements.values.targetType.Office' },
    { id: 'List', name: 'resources.announcements.values.targetType.List' },
  ]} />

export const DraftGameMethodInput = props =>
  <EnumInput {...props} choices={[
    { id: GENERATOR_METHODS.GAMES_PER_TEAM, name: `resources.draftGames.values.methods.${GENERATOR_METHODS.GAMES_PER_TEAM}` },
    { id: GENERATOR_METHODS.TOTAL_ROUNDS, name: `resources.draftGames.values.methods.${GENERATOR_METHODS.TOTAL_ROUNDS}` },
  ]} />

export const DraftGameTypeInput = ({ showMatrixOption, ...props }) =>
  <EnumInput {...props} choices={[
    { id: GENERATOR_TYPES.SINGLE_ROTATION, name: `resources.draftGames.values.types.${GENERATOR_TYPES.SINGLE_ROTATION}` },
    { id: GENERATOR_TYPES.DOUBLE_ROTATION, name: `resources.draftGames.values.types.${GENERATOR_TYPES.DOUBLE_ROTATION}` },
    showMatrixOption && { id: GENERATOR_TYPES.MATRIX, name: `resources.draftGames.values.types.${GENERATOR_TYPES.MATRIX}` },
  ].filter(Boolean)} />

export const DraftGameRoundTypeEnumInput = props =>
  <EnumInput {...props} optionDescription="description" choices={[
    { id: GENERATOR_ROUND_TYPES.HOME_SLOTS, name: `resources.draftGames.values.round_types.${GENERATOR_ROUND_TYPES.HOME_SLOTS}`, description: `resources.draftGames.helpers.round_type.${GENERATOR_ROUND_TYPES.HOME_SLOTS}` },
    { id: GENERATOR_ROUND_TYPES.WEEKDAYS, name: `resources.draftGames.values.round_types.${GENERATOR_ROUND_TYPES.WEEKDAYS}`, description: `resources.draftGames.helpers.round_type.${GENERATOR_ROUND_TYPES.WEEKDAYS}` },
  ]} />

export const DraftGameWeekdaysTypeEnumInput = props =>
  <EnumInput {...props} optionDescription="description" choices={[
    { id: GENERATOR_WEEKDAYS_TYPES.CONSECUTIVE, name: `resources.draftGames.values.weekdays_types.${GENERATOR_WEEKDAYS_TYPES.CONSECUTIVE}`, description: `resources.draftGames.helpers.weekdays_type.${GENERATOR_WEEKDAYS_TYPES.CONSECUTIVE}` },
    { id: GENERATOR_WEEKDAYS_TYPES.ROTATING, name: `resources.draftGames.values.weekdays_types.${GENERATOR_WEEKDAYS_TYPES.ROTATING}`, description: `resources.draftGames.helpers.weekdays_type.${GENERATOR_WEEKDAYS_TYPES.ROTATING}` },
  ]} />

export const DraftTypeEnumInput = props =>
  <EnumInput {...props} optionDescription="description" choices={[
    { id: 'Single Rotation', name: 'resources.drafts.values.draft_type.Single Rotation' },
    { id: 'Double Rotation', name: 'resources.drafts.values.draft_type.Double Rotation' }
  ]} />

export const DraftMethodEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'totalRounds', name: 'resources.drafts.fields.options.totalRounds' },
    { id: 'gamesPerTeam', name: 'resources.drafts.fields.options.gamesPerTeam' },
  ]} />

export const DraftRoundTypeEnumInput = props =>
  <EnumInput {...props} optionDescription="description" choices={[
    { id: ROUND_TYPES.HOME_SLOTS, name: `resources.drafts.values.round_type.${ROUND_TYPES.HOME_SLOTS}`, description: `resources.drafts.helpers.roundType.${ROUND_TYPES.HOME_SLOTS}` },
    { id: ROUND_TYPES.WEEKDAYS, name: `resources.drafts.values.round_type.${ROUND_TYPES.WEEKDAYS}`, description: `resources.drafts.helpers.roundType.${ROUND_TYPES.WEEKDAYS}` }
  ]} />

export const ProvinceEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'AB', name: 'resources.addresses.values.province.AB' },
    { id: 'BC', name: 'resources.addresses.values.province.BC' },
    { id: 'MB', name: 'resources.addresses.values.province.MB' },
    { id: 'NB', name: 'resources.addresses.values.province.NB' },
    { id: 'NL', name: 'resources.addresses.values.province.NL' },
    { id: 'NT', name: 'resources.addresses.values.province.NT' },
    { id: 'NS', name: 'resources.addresses.values.province.NS' },
    { id: 'NU', name: 'resources.addresses.values.province.NU' },
    { id: 'ON', name: 'resources.addresses.values.province.ON' },
    { id: 'PEI', name: 'resources.addresses.values.province.PEI' },
    { id: 'QC', name: 'resources.addresses.values.province.QC' },
    { id: 'SK', name: 'resources.addresses.values.province.SK' },
    { id: 'YT', name: 'resources.addresses.values.province.YT' },
  ]} />

export const RoleEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'super-admin', name: 'resources.help.values.roles.super-admin' },
    { id: 'admin', name: 'resources.help.values.roles.admin' },
    { id: 'registrar', name: 'resources.help.values.roles.registrar' },
    { id: 'director', name: 'resources.help.values.roles.director' },
    { id: 'manager', name: 'resources.help.values.roles.manager' },
    { id: 'organizer', name: 'resources.help.values.roles.organizer' },
    { id: 'assigning-manager', name: 'resources.help.values.roles.assigning-manager' },
    { id: 'assigner', name: 'resources.help.values.roles.assigner' },
    { id: 'official', name: 'resources.help.values.roles.official' },
    { id: 'player', name: 'resources.help.values.roles.player' },
    { id: 'coach', name: 'resources.help.values.roles.coach' },
    { id: 'assistant', name: 'resources.help.values.roles.assistant' },
    { id: 'association', name: 'resources.help.values.roles.association' },
    { id: 'editor', name: 'resources.help.values.roles.editor' },
    { id: 'scorekeeper', name: 'resources.help.values.roles.scorekeeper' },
  ]} />

export const FrequencyEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Once', name: 'resources.activities.values.frequency.Once' },
    { id: 'Daily', name: 'resources.activities.values.frequency.Daily' },
    { id: 'Weekly', name: 'resources.activities.values.frequency.Weekly' },
    { id: 'Monthly', name: 'resources.activities.values.frequency.Monthly' },
  ]} disableClearable />

export const TimezoneEnumInput = props => {
  const translate = useTranslate();
  const COUNTRY_CODES = {
    'Canada': 'CA',
    'United States': 'US',
    'Europe': ['GB', 'FR', 'IT', 'NL'],
  }

  const timezones = region => {
    const codes = Array.isArray(COUNTRY_CODES[region]) ? COUNTRY_CODES[region] : [COUNTRY_CODES[region]];
    const zones = codes.map(code => moment.tz.zonesForCountry(code)).flat();
    return zones.map(tz => ({ id: tz, name: tz.replaceAll('_', ' '), region }));
  }

  return <EnumInput disableClearable {...props} choices={[
    ...timezones('Canada'),
    ...timezones('United States'),
    ...timezones('Europe'),
  ]} groupBy={({ region }) => translate(`common.regions.${region}`)} />
}

export const FileTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Lineup', name: 'resources.gameDocuments.values.type.Lineup' },
    { id: 'Scoresheet', name: 'resources.gameDocuments.values.type.Scoresheet' },
    { id: 'Other', name: 'resources.gameDocuments.values.type.Other' },
  ]} />

export const SportsEnumInput = props => {
  return <EnumInput {...props} multiple choices={[
    { id: 'Hockey', name: 'resources.surfaces.values.sports.Hockey' },
    { id: 'Soccer', name: 'resources.surfaces.values.sports.Soccer' },
    { id: 'Baseball', name: 'resources.surfaces.values.sports.Baseball' },
  ]} />
}

export const SurfaceSizeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'XL', name: 'resources.surfaces.values.size.XL' },
    { id: 'L', name: 'resources.surfaces.values.size.L', sports: ['Soccer', 'Baseball'] },
    { id: 'M', name: 'resources.surfaces.values.size.M' },
    { id: 'S', name: 'resources.surfaces.values.size.S', sports: ['Soccer', 'Baseball'] },
    { id: 'XS', name: 'resources.surfaces.values.size.XS' },
  ]} />

export const SurfaceTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Ice', name: 'resources.surfaces.values.type.Ice', sports: ['Hockey'] },
    { id: 'Grass', name: 'resources.surfaces.values.type.Grass', sports: ['Soccer', 'Baseball'] },
    { id: 'Synthetic', name: 'resources.surfaces.values.type.Synthetic' },
    { id: 'Wood', name: 'resources.surfaces.values.type.Wood', sports: ['Soccer'] },
    { id: 'Gym', name: 'resources.surfaces.values.type.Gym', sports: ['Soccer'] },
    { id: 'Clay', name: 'resources.surfaces.values.type.Clay', sports: ['Baseball'] },
    { id: 'Dirt', name: 'resources.surfaces.values.type.Dirt', sports: ['Baseball'] },
    { id: 'Grass/Clay', name: 'resources.surfaces.values.type.Grass/Clay', sports: ['Baseball'] },
    { id: 'Grass/Dirt', name: 'resources.surfaces.values.type.Grass/Dirt', sports: ['Baseball'] },
  ]} />

export const GameFormatEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: '3x3', name: 'resources.schedules.values.gameFormat.3x3', sports: ['Hockey'] },
    { id: '4x4', name: 'resources.schedules.values.gameFormat.4x4', sports: ['Soccer'] },
    { id: '5x5', name: 'resources.schedules.values.gameFormat.5x5', sports: ['Hockey', 'Soccer'] },
    { id: '6x6', name: 'resources.schedules.values.gameFormat.6x6', sports: ['Baseball'] },
    { id: '7x7', name: 'resources.schedules.values.gameFormat.7x7', sports: ['Soccer'] },
    { id: '9x9', name: 'resources.schedules.values.gameFormat.9x9', sports: ['Soccer', 'Baseball'] },
    { id: '11x11', name: 'resources.schedules.values.gameFormat.11x11', sports: ['Soccer'] },
  ]} />

export const AttributeTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Officiating', name: 'resources.attributeTypes.type.Officiating' },
    { id: 'League', name: 'resources.attributeTypes.type.League' },
  ]} />

export const AttributeTargetTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Participant', name: 'resources.attributeTypes.targetType.Participant' },
    { id: 'Venue', name: 'resources.attributeTypes.targetType.Venue' },
    { id: 'Surface', name: 'resources.attributeTypes.targetType.Surface' },
    { id: 'Team', name: 'resources.attributeTypes.targetType.Team' },
  ]} />

export const AttributeValueTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Dropdown', name: 'resources.attributeTypes.valueType.Dropdown' },
    { id: 'Text', name: 'resources.attributeTypes.valueType.Text' },
    { id: 'Number', name: 'resources.attributeTypes.valueType.Number' },
  ]} />

export const AssignRuleTypeEnumInput = ({ disabled, types, ...props }) =>
  <EnumInput {...props} disabled={disabled} choices={[
    (disabled || !types || types.includes('systemId')) && { id: 'systemId', name: 'resources.assignRules.options.systemId' },
    (disabled || !types || types.includes('feesId')) && { id: 'feesId', name: 'resources.assignRules.options.feesId' },
    (!types || types.includes('assignType')) && { id: 'assignType', name: 'resources.assignRules.options.assignType' },
    (!types || types.includes('officeId')) && { id: 'officeId', name: 'resources.assignRules.options.officeId' },
    (!types || types.includes('minReferee')) && { id: 'minReferee', name: 'resources.assignRules.options.minReferee' },
    (!types || types.includes('minLinesperson')) && { id: 'minLinesperson', name: 'resources.assignRules.options.minLinesperson' },
    (!types || types.includes('minOfficial')) && { id: 'minOfficial', name: 'resources.assignRules.options.minOfficial', sports: ['Soccer'] },
    (!types || types.includes('minScorekeeper')) && { id: 'minScorekeeper', name: 'resources.assignRules.options.minScorekeeper', sports: ['Hockey', 'Baseball'] },
    (!types || types.includes('minTimekeeper')) && { id: 'minTimekeeper', name: 'resources.assignRules.options.minTimekeeper', sports: ['Hockey'] },
    (!types || types.includes('minRefereeGrade')) && { id: 'minRefereeGrade', name: 'resources.assignRules.options.minRefereeGrade', sports: ['Hockey'] },
    (!types || types.includes('minLinespersonGrade')) && { id: 'minLinespersonGrade', name: 'resources.assignRules.options.minLinespersonGrade', sports: ['Hockey'] },
    (!types || types.includes('minAge')) && { id: 'minAge', name: 'resources.assignRules.options.minAge' },
    (!types || types.includes('status')) && { id: 'status', name: 'resources.assignRules.options.status' },
  ]} />

export const AssignTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Arena', name: 'resources.assignRules.options.Arena' },
    { id: 'Arena Primary', name: 'resources.assignRules.options.Arena Primary' },
    { id: 'Schedule', name: 'resources.assignRules.options.Schedule' },
    { id: 'Home', name: 'resources.assignRules.options.Home' },
    { id: 'Home Parent', name: 'resources.assignRules.options.Home Parent' },
    { id: 'Zone', name: 'resources.assignRules.options.Zone', sports: ['Hockey'] },
  ]} />

export const AssignTypeDelegationEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Office', name: 'resources.assignRules.options.Office' },
    { id: 'Home', name: 'resources.assignRules.options.Home' },
  ]} />

export const AssignRuleStatusEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Active', name: 'resources.assignRules.options.Active' },
    { id: 'Draft', name: 'resources.assignRules.options.Draft' },
  ]} />

export const QualificationCategoryTypeInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Official', name: 'resources.qualificationCategories.values.types.Official' },
    { id: 'Scorekeeper', name: 'resources.qualificationCategories.values.types.Scorekeeper' },
  ]} />

export const OfficialTransactionTypeInput = ({ hideBase = false, ...props }) =>
  <EnumInput {...props} choices={[
    (!hideBase && { id: 'Base', name: 'resources.officialTransactions.values.type.Base' }),
    { id: 'Travel', name: 'resources.officialTransactions.values.type.Travel' },
    { id: 'Per Diem', name: 'resources.officialTransactions.values.type.Per Diem' },
    { id: 'Evaluation', name: 'resources.officialTransactions.values.type.Evaluation' },
    { id: 'Exhibition Game', name: 'resources.officialTransactions.values.type.Exhibition Game' },
    { id: 'Internship', name: 'resources.officialTransactions.values.type.Internship' },
    { id: 'Withhold', name: 'resources.officialTransactions.values.type.Withhold' },
    { id: 'Other', name: 'resources.officialTransactions.values.type.Other' },
  ]} />

export const PitcherLimitsScheduleTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Exhibition', name: `resources.rulebooks.values.pitcherLimits.scheduleTypes.Exhibition`, sports: ['Baseball'] },
    { id: 'League', name: `resources.rulebooks.values.pitcherLimits.scheduleTypes.League`, sports: ['Baseball'] },
    { id: 'Playoffs', name: `resources.rulebooks.values.pitcherLimits.scheduleTypes.Playoffs`, sports: ['Baseball'] },
    { id: 'Tournament', name: `resources.rulebooks.values.pitcherLimits.scheduleTypes.Tournament`, sports: ['Baseball'] },
  ]} />

export const LimitTypeEnumInput = props =>
  <EnumInput {...props} choices={[
    { id: 'Daily', name: `resources.rulebooks.values.pitcherLimits.limitType.Daily` },
    { id: 'Weekly', name: `resources.rulebooks.values.pitcherLimits.limitType.Weekly` },
  ]} />

export const ClaimTypeEnumInput = ({ settings = {}, hideAmount = false, helperText, ...props }) => {
  const locale = useLocale();
  const translate = useTranslate()
  const { values } = useFormState();

  const {
    claimableDriver,
    claimablePassenger,
    claimableBreakfast,
    claimableLunch,
    claimableDinner,
    driverAmount,
    passengerAmount,
    breakfastAmount,
    lunchAmount,
    dinnerAmount,
    driverExcludedDistance,
    passengerExcludedDistance,
  } = settings;

  const amount = (amount, excludedDistance, isKm) => {
    if (amount == null || hideAmount) return;

    const amountText = formatCurrency(amount, locale, true);
    if (!isKm) return amountText;

    if (excludedDistance == null) return `${amountText}/km`;
    return `${amountText}/km ${translate('resources.officialClaims.helpers.excluded_amount', { distance: excludedDistance })}`;
  }

  const value = values?.[props?.source];
  const choices = [
    {
      id: 'Driver',
      name: 'resources.officialClaims.values.type.Driver',
      type: 'resources.officialTransactions.values.type.Travel',
      amount: amount(driverAmount, driverExcludedDistance, true),
      enabled: claimableDriver || value === 'Driver',
    },
    {
      id: 'Passenger',
      name: 'resources.officialClaims.values.type.Passenger',
      type: 'resources.officialTransactions.values.type.Travel',
      amount: amount(passengerAmount, passengerExcludedDistance, true),
      enabled: claimablePassenger || value === 'Passenger',
    },
    {
      id: 'Breakfast',
      name: 'resources.officialClaims.values.type.Breakfast',
      type: 'resources.officialTransactions.values.type.Per Diem',
      amount: amount(breakfastAmount),
      enabled: claimableBreakfast || value === 'Breakfast',
    },
    {
      id: 'Lunch',
      name: 'resources.officialClaims.values.type.Lunch',
      type: 'resources.officialTransactions.values.type.Per Diem',
      amount: amount(lunchAmount),
      enabled: claimableLunch || value === 'Lunch',
    },
    {
      id: 'Dinner',
      name: 'resources.officialClaims.values.type.Dinner',
      type: 'resources.officialTransactions.values.type.Per Diem',
      amount: amount(dinnerAmount),
      enabled: claimableDinner || value === 'Dinner',
    },
  ].filter(choice => choice.enabled);

  const selectedChoice = value && choices.find(choice => choice.id === value);
  return <EnumInput
    choices={choices}
    helperText={selectedChoice?.amount || helperText}
    optionDescription="amount"
    groupBy={({ type }) => translate(type)}
    alwaysGroup
    disableSort
    {...props}
  />
}

export const ClaimStatusEnumInput = props => {
  return <EnumInput {...props} choices={[
    { id: 'Pending', name: 'resources.officialClaims.values.status.Pending' },
    { id: 'Declined', name: 'resources.officialClaims.values.status.Declined' },
    { id: 'Approved', name: 'resources.officialClaims.values.status.Approved' },
  ]} />
}

export const InfractionSeverityInput = props => {
  return <EnumInput {...props}
    choices={[
      { id: 'Low', name: 'resources.infractions.values.severity.Low' },
      { id: 'Medium', name: 'resources.infractions.values.severity.Medium' },
      { id: 'High', name: 'resources.infractions.values.severity.High' },
      { id: 'Severe', name: 'resources.infractions.values.severity.Severe' },
    ]}
  />
}

export const SuspensionStatusInput = props => {
  const { values } = useFormState();
  return <EnumInput
    {...props}
    getOptionDisabled={option => option.disabled}
    hideDisabled
    choices={[
      { id: 'Pending', name: 'resources.suspensions.values.status.Pending' },
      { id: 'Appealed', name: 'resources.suspensions.values.status.Appealed', disabled: values?.code },
      { id: 'Active', name: 'resources.suspensions.values.status.Active' },
      { id: 'Completed', name: 'resources.suspensions.values.status.Completed' },
      { id: 'Cancelled', name: 'resources.suspensions.values.status.Cancelled' },
    ]}
  />
}

export const SuspensionPositionGroupInput = props => {
  return <EnumInput {...props}
    choices={[
      { id: 'Player', name: 'resources.suspensions.values.positionGroup.Player' },
      { id: 'Staff', name: 'resources.suspensions.values.positionGroup.Staff' },
    ]}
  />
}

export const SanctionPositionGroupInput = props => {
  return <EnumInput {...props}
    choices={[
      { id: 'Player', name: 'resources.sanctions.values.positionGroup.Player' },
      { id: 'Staff', name: 'resources.sanctions.values.positionGroup.Staff' },
    ]}
  />
}

export const DurationTypeInput = props => {
  const resource = useResourceContext(props);
  return <EnumInput {...props}
    choices={[
      { id: 'Definite', name: `resources.${resource}.values.durationType.Definite` },
      { id: 'Indefinite', name: `resources.${resource}.values.durationType.Indefinite` },
    ]}
  />
}

export const DisciplineStatusInput = props => {
  return <EnumInput
    {...props}
    choices={[
      { id: 'Pending', name: 'resources.suspensionDisciplines.values.status.Pending' },
      { id: 'Active', name: 'resources.suspensionDisciplines.values.status.Active' },
      { id: 'Inactive', name: 'resources.suspensionDisciplines.values.status.Inactive' },
    ]}
  />
}
