import React from 'react'
import { GET_LIST, RecordContextProvider, useQuery, useTranslate } from 'react-admin';
import { makeStyles, MenuItem, TableCell, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';

import InfoCard from '../../../common/cards/InfoCard';
import { useSport } from '../../../http';

import { ParticipantField } from '../../participants/ParticipantField';
import { TeamSuggestion } from '../../teams/TeamInput';
import { ScheduleStatsTable, useScheduleStatsContext } from '../ScheduleStatsTable'

const inputProps = {
  variant: 'outlined',
  margin: 'dense',
}

const useStyles = makeStyles(theme => ({
  input: {
    minWidth: theme.spacing(24),
  },
}));

const useGroups = (groupIds) => useQuery({
  type: GET_LIST,
  resource: 'groups',
  payload: {
    filter: { id: { inq: groupIds } },
    pagination: { page: 1, perPage: 99999 },
  },
}, { enabled: groupIds?.length > 0 })

const useTeams = (teamIds) => useQuery({
  type: GET_LIST,
  resource: 'teams',
  payload: {
    filter: { id: { inq: teamIds } },
    pagination: { page: 1, perPage: 99999 },
  },
}, { enabled: teamIds?.length > 0 })

const renderColumns = (row, schedule) =>
  <TableCell component="th" scope="row">
    <RecordContextProvider value={{ participantId: row.participantId, ...schedule }}>
      <ParticipantField source="participantId" />
    </RecordContextProvider>
  </TableCell>

const renderHeaders = ({ translate }) =>
  <TableCell component="th" scope="row" style={{ width: 250 }}>{translate('resources.participants.labels.card.title')}</TableCell>

const NoOptionsText = (loading) => {
  const translate = useTranslate();
  return loading ? translate('ra.message.no_options') : translate('ra.navigation.no_results')
}

export const GroupSelect = () => {
  const { groupIds, filters, setFilters, setPage } = useScheduleStatsContext();
  const { data: groups, loading } = useGroups(groupIds);
  const translate = useTranslate();
  const classes = useStyles();

  const handleGroupChange = (event, value) => {
    setPage(0);
    setFilters(prev => ({ ...prev, groupId: value?.id }));
  };

  if (!groupIds?.length) return null;

  const value = groups?.find(group => group.id === filters?.groupId) || null;

  return <Autocomplete
    value={value}
    options={groups || []}
    getOptionLabel={(option) => option.name}
    noOptionsText={<NoOptionsText loading={loading} />}
    renderInput={(params) => <TextField {...params} className={classes.input} label={translate('resources.groups.name', 1)} {...inputProps} />}
    onChange={handleGroupChange}
  />
}

const ScheduleTeamSelect = () => {
  const { teamIds, filters, setFilters, setPage } = useScheduleStatsContext();
  const { data: scheduleTeams, loading } = useTeams(teamIds);
  const translate = useTranslate();
  const classes = useStyles();

  const handleTeamChange = (event, value) => {
    setPage(0);
    setFilters(prev => ({ ...prev, teamId: value?.id }));
  };

  const value = scheduleTeams?.find(team => team.id === filters?.teamId) || null;

  return <Autocomplete
    value={value}
    options={scheduleTeams || []}
    getOptionLabel={(option) => option.name}
    noOptionsText={<NoOptionsText loading={loading} />}
    renderOption={option => <TeamSuggestion suggestion={option} />}
    renderInput={(params) => <TextField {...params} className={classes.input} label={translate('resources.teams.name', 1)} {...inputProps} />}
    onChange={handleTeamChange}
  />
}

const PlayerTypeSelect = () => {
  const { filters, setFilters, setSortBy, setSort, setPage } = useScheduleStatsContext();
  const translate = useTranslate();
  const classes = useStyles();

  const handleTypeChange = (event) => {
    const { value } = event.target;

    if (value !== filters?.type) {
      setSortBy('ranking');
      setSort('asc');
    }

    setPage(0);
    setFilters(prev => ({ ...prev, type: value }));
  };

  return <TextField
    className={classes.input}
    label={translate("components.scheduleStats.labels.type")}
    select
    value={filters?.type}
    onChange={handleTypeChange}
    SelectProps={{
      MenuProps: {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      }
    }}
    {...inputProps}
  >
    <MenuItem value="Skater">{translate("components.scheduleStats.values.type.Skater")}</MenuItem>
    <MenuItem value="Goalie">{translate("components.scheduleStats.values.type.Goalie")}</MenuItem>
  </TextField>
}

export default function PlayerScheduleStatsCard() {
  const sport = useSport();

  const isHockey = sport === 'Hockey';

  const filters = [
    isHockey && <PlayerTypeSelect key="type" />,
    <ScheduleTeamSelect key="team" />,
    <GroupSelect key="group" />,
  ].filter(Boolean);

  return <InfoCard
    title="components.scheduleStats.titles.ParticipantScheduleStats"
  >
    <ScheduleStatsTable
      resource="ParticipantScheduleStats"
      filters={filters}
      filter={{ type: 'Skater' }}
      sort={{ field: 'ranking', order: 'asc' }}
      renderColumns={renderColumns}
      renderHeaders={renderHeaders}
      fixedColumns={2}
    />
  </InfoCard>
}
