import React from 'react';
import { useRecordContext } from 'react-admin';
import { GroupOutlined } from '@material-ui/icons';
import { useTheme } from '@material-ui/core';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';
import { useFocusState } from '../../../common/hooks/hooks';

import { AssignSystemGrid } from '../../assignSystems/AssignSystemGrid';
import { AssignRuleList } from '../../assignRules/AssignRuleList';

export default props => {
  const office = useRecordContext(props);
  const seasonId = useSeason();
  const theme = useTheme();
  const { isFocused } = useFocusState('assignSystems');

  return <div id="assignSystems">
    <ListCard
      title="resources.assignSystems.labels.card.title"
      subtitle="resources.assignSystems.labels.card.subtitle"
      icon={<GroupOutlined />}
      collapsable={!isFocused}
      reference="assignSystems"
      basePath="/assignSystems"
      target="officeId"
      filter={{ seasonId, effectiveOffices: true, includeParents: true, _scope: 'Parents' }}
      sort={{ field: 'name', order: 'ASC' }}
      componentProps={{ style: { boxShadow: isFocused ? theme.focusShadow : undefined } }}
    >
      <AssignSystemGrid
        rowClick="expand"
        expand={<AssignRuleList type="systemId" officeId={office?.id} />}
      />
    </ListCard>
  </div>
}
