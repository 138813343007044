import React from 'react';
import { DatagridRow, RecordContextProvider } from 'react-admin';

import GroupedView from '../../common/GroupedView';
import { OfficeField } from '../offices/OfficeField';
import { OfficeNameField } from '../offices/OfficeGrid';

const TournamentTitle = ({ group: id }) => {
  return <RecordContextProvider value={{ id }}>
    <OfficeField source="id" variant="subtitle2" link={false} />
  </RecordContextProvider>
}

const TournamentList = ({ records, basePath, resource, classes, ...props }) => {
  return records.map(record =>
    <DatagridRow key={record.id} basePath={basePath} resource={resource} record={record} id={record.id} rowClick="show" className={classes.row}>
      <OfficeNameField source="name" />
    </DatagridRow>
  )
}

export const TournamentView = () =>
  <GroupedView grouping={tournament => tournament.parentId} titleComponent={TournamentTitle}>
    <TournamentList />
  </GroupedView>
