import React, { useState, Fragment, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button, SimpleForm, useRefresh, useNotify, useTranslate, TextInput, NumberInput } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText, List, ListItem } from '@material-ui/core';
import createCalculator from 'final-form-calculate';

import { FF_GRADES, FF_OFFICIALS, FF_SCOREKEEPERS, FF_TIMEKEEPERS } from '@hisports/common/featureFlags';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { AssignmentPositionsEnumInput, AssignmentStatusEnumInput, GameStatusEnumInput, AssignTypeDelegationEnumInput } from '../../common/inputs/EnumInputs';
import CheckboxRowInput from '../../common/inputs/CheckboxRowInput';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';
import { isAuthorized } from '../../common/Authorize';
import { useSeason } from '../../common/inputs/SeasonSelector'
import { useScopes, apiClient, useFlag } from '../../http';

import { OfficeInput } from '../offices/OfficeInput';
import { AssignSystemInput } from '../assignSystems';
import { AssignFeesInput } from '../assignFees';
import { FieldDependency } from '../../common/FieldDependency';

export const canChangeStatus = game => game && !(game.isApproved || game.isCertified);
export const canManageAssignments = gameStatus => gameStatus && gameStatus.assigning != null && gameStatus.officials != null;
export const canCertify = gameStatus => gameStatus && !!gameStatus.flags && gameStatus.status === 'approved';

const validateStatus = values => {
  const errors = {};
  if (!values.status) errors.status = 'ra.validation.required';
  return errors;
}

const validateDelegateForm = values => {
  const errors = {};
  if (!values.assignType) errors.assignType = 'ra.validation.required';
  if (!values.officeId && values.assignType == 'Office') errors.officeId = 'ra.validation.required';
  return errors;
}

const validateAssignSettings = values => {
  const errors = {};
  if (values.systemIdIsChecked && !values.systemId) errors.systemId = 'ra.validation.required';
  if (values.statusIsChecked && !values.status) errors.status = 'ra.validation.required';

  if (values.minRefereeIsChecked && values.minReferee != null) {
    if (values.minReferee < 0) {
      errors.minReferee = 'resources.assignSettings.validations.min_setting_range';
    } else if (values.minReferee % 1 > 0) {
      errors.minReferee = 'resources.assignSettings.validations.invalid_level';
    }
  }

  if (values.minLinespersonIsChecked && values.minLinesperson != null) {
    if (values.minLinesperson < 0) {
      errors.minLinesperson = 'resources.assignSettings.validations.min_setting_range';
    } else if (values.minLinesperson % 1 > 0) {
      errors.minLinesperson = 'resources.assignSettings.validations.invalid_level';
    }
  }

  if (values.minOfficialIsChecked && values.minOfficial != null) {
    if (values.minOfficial < 0) {
      errors.minOfficial = 'resources.assignSettings.validations.min_setting_range';
    } else if (values.minOfficial % 1 > 0) {
      errors.minOfficial = 'resources.assignSettings.validations.invalid_level';
    }
  }

  if (values.minScorekeeperIsChecked && values.minScorekeeper != null) {
    if (values.minScorekeeper < 0) {
      errors.minScorekeeper = 'resources.assignSettings.validations.min_setting_range';
    } else if (values.minScorekeeper % 1 > 0) {
      errors.minScorekeeper = 'resources.assignSettings.validations.invalid_level';
    }
  }

  if (values.minTimekeeperIsChecked && values.minTimekeeper != null) {
    if (values.minTimekeeper < 0) {
      errors.minTimekeeper = 'resources.assignSettings.validations.min_setting_range';
    } else if (values.minTimekeeper % 1 > 0) {
      errors.minTimekeeper = 'resources.assignSettings.validations.invalid_level';
    }
  }

  if (values.minRefereeGradeIsChecked && values.minRefereeGrade != null && (values.minRefereeGrade % 1 > 0 || values.minRefereeGrade < 0)) {
    errors.minRefereeGrade = 'resources.assignSettings.validations.invalid_grade';
  }
  if (values.minLinespersonGradeIsChecked && values.minLinespersonGrade != null && (values.minLinespersonGrade % 1 > 0 || values.minLinespersonGrade < 0)) {
    errors.minLinespersonGrade = 'resources.assignSettings.validations.invalid_grade';
  }

  if (values.minAgeIsChecked && values.minAge != null && (values.minAge % 1 > 0 || values.minAge < 1)) {
    errors.minAge = 'resources.assignSettings.validations.invalid_age';
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const DelegatePositionsForm = props => {
  const translate = useTranslate();
  return <SimpleForm {...props} validate={validateDelegateForm} initialValues={{ assignType: 'Office' }} {...inputProps} >
    <AssignTypeDelegationEnumInput source="assignType" label={translate("resources.assignRules.options.officeId")} {...inputProps} helperText="" radio />
    <FieldDependency fieldSource="assignType" test={type => type == 'Office'}>
      <OfficeInput label={translate('resources.games.fields.officeId')} source="officeId" filter={{ type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] }, isAssigner: true, _scope: 'Tenant' }} {...inputProps} />
    </FieldDependency>
    <AssignmentPositionsEnumInput label={translate('resources.officials.labels.position')} source="positions" helperText="ra.message.optional" multiple {...inputProps} />
  </SimpleForm>
}

const DelegateDialog = ({ isOpen, onSubmit, onClose }) => {
  const translate = useTranslate();
  return <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.gameAssignSettings.labels.change_assigner')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{translate('resources.gameAssignSettings.messages.change_assigner')}</DialogContentText>
      <DialogContentText>{translate('resources.gameAssignSettings.messages.change_assigner_partial')}</DialogContentText>
      <DelegatePositionsForm save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.update" onCancel={onClose} />
      } />
    </DialogContent>
  </Dialog>
}

const Delegate = ({ selectedIds, resource }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const statuses = useSelector(state => state.admin.resources.gameStatuses.data)
  const [ open, setOpen ] = useState(false);
  const actionableGameIds = selectedIds.filter(id => canManageAssignments(statuses?.[id]))

  const onClose = () => {
    setOpen(false);
  }

  const onSubmit = async values => {
    const { assignType, positions, officeId } = values;
    const type = positions ? 'delegated' : 'changed_assigner'

    apiClient(`/games/bulkDelegate`, {
      method: 'POST',
      data: {
        gameIds: actionableGameIds,
        positions,
        officeId,
        assignType
      },
    })
      .then(({ data }) => {
        const count = data.count || 0;
        onClose();
        // wait 1s for events to be processed
        setTimeout(() => refresh(), 1000);
        notify(translate(`resources.games.notifications.${type}_games`, count))
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate(`resources.games.notifications.${type}_games_error`, actionableGameIds.length))
      })

    setOpen(false)
  }

  return <>
    <Button label={translate('resources.gameAssignSettings.labels.set_assigner')} onClick={() => setOpen(true)} disabled={!actionableGameIds.length} />
    <DelegateDialog isOpen={open} onSubmit={onSubmit} onClose={onClose} />
  </>
}

const GameStatusForm = props => {
  return <SimpleForm {...props} {...inputProps} validate={validateStatus} >
    <GameStatusEnumInput source="status" defaultValue="Active" {...inputProps} />
    <TextInput source="comments" multiline minRows="3" helperText="resources.games.helpers.comments" {...inputProps} />
  </SimpleForm>
}

const GameStatusDialog = ({ isOpen, onSubmit, onClose }) => {
  const translate = useTranslate();
  return <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.games.labels.game_status')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{translate('resources.games.messages.game_status')}</DialogContentText>
      <GameStatusForm initialValues={{ status: 'Active', comments: '' }} save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.update" onCancel={onClose} />
      } />
    </DialogContent>
  </Dialog>
}

const GameStatus = ({ selectedIds, resource }) => {
  const [ open, setOpen ] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const games = useSelector(state => state.admin.resources.games.data)
  const actionableGameIds = selectedIds.filter(id => canChangeStatus(games?.[id]))

  const onClose = () => {
    setOpen(false);
  }

  const onSubmit = (values) => {
    apiClient(`/games/bulkGameStatus`, {
      method: 'POST',
      data: {
        gameIds: actionableGameIds,
        status: values.status,
        comments: values.comments
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        onClose();
        refresh();
        notify(translate('resources.games.notifications.change_game_status', {
          smart_count: count,
          status: values.status,
        }))
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify('resources.games.notifications.change_game_status_error', actionableGameIds.length)
      })
  }

  return <>
    <Button label={translate('resources.games.labels.set_game_status')} onClick={() => setOpen(true)} disabled={!actionableGameIds.length} />
    <GameStatusDialog
      isOpen={open}
      selectedIds={actionableGameIds}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  </>
}

const AssignSettingsForm = props => {
  const isEnabled = useFlag();
  const seasonId = useSeason();

  const decorators = useRef([createCalculator({
    field: 'statusIsChecked',
    updates: {
      status: (statusIsChecked, values) => {
        if (!statusIsChecked || values.status) return values.status;
        return 'Active'
      }
    }
  })])

  return <SimpleForm validate={validateAssignSettings} decorators={decorators.current} {...props}>
    <CheckboxRowInput resource="gameAssignSettings" source="systemId" {...inputProps}>
      <AssignSystemInput />
    </CheckboxRowInput>
    <CheckboxRowInput resource="gameAssignSettings" source="feesId" filter={{ seasonId }} {...inputProps}>
      <AssignFeesInput />
    </CheckboxRowInput>
    <CheckboxRowInput resource="gameAssignSettings" source="status" helperText="resources.gameAssignSettings.helpers.status_draft" {...inputProps}>
      <AssignmentStatusEnumInput />
    </CheckboxRowInput>
    <CheckboxRowInput resource="gameAssignSettings" source="minReferee" min={0} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>
    <CheckboxRowInput resource="gameAssignSettings" source="minLinesperson" min={0} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>
    {isEnabled(FF_OFFICIALS) && <CheckboxRowInput resource="gameAssignSettings" source="minOfficial" min={0} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>}
    {isEnabled(FF_SCOREKEEPERS) && <CheckboxRowInput resource="gameAssignSettings" source="minScorekeeper" min={0} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>}
    {isEnabled(FF_TIMEKEEPERS) && <CheckboxRowInput resource="gameAssignSettings" source="minTimekeeper" min={0} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>}
    {isEnabled(FF_GRADES) && <CheckboxRowInput resource="gameAssignSettings" source="minRefereeGrade" min={0} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>}
    {isEnabled(FF_GRADES) && <CheckboxRowInput resource="gameAssignSettings" source="minLinespersonGrade" min={0} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>}
    <CheckboxRowInput resource="gameAssignSettings" source="minAge" min={1} step={1} {...inputProps}>
      <NumberInput />
    </CheckboxRowInput>
  </SimpleForm>
}

const AssignSettingsDialog = ({ isOpen, onSubmit, onClose }) => {
  const translate = useTranslate();
  return <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.gameAssignSettings.labels.assigning_settings')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.gameAssignSettings.alerts.bulk_assign_settings')}
      </DialogContentText>
      <AssignSettingsForm save={onSubmit} component={Fragment} toolbar={
        <DialogFormToolbar submitLabel="ra.action.update" onCancel={onClose} />
      } />
    </DialogContent>
  </Dialog>
}

const AssignStatus = ({ selectedIds, resource }) => {
  const [ open, setOpen ] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const statuses = useSelector(state => state.admin.resources.gameStatuses.data)

  const actionableGameIds = selectedIds.filter(id => canManageAssignments(statuses?.[id]))

  const onClose = () => {
    setOpen(false);
  }

  const onSubmit = (values) => {
    const {
      systemIdIsChecked, systemId,
      feesIdIsChecked, feesId,
      statusIsChecked, status,
      minRefereeIsChecked, minReferee,
      minLinespersonIsChecked, minLinesperson,
      minOfficialIsChecked, minOfficial,
      minScorekeeperIsChecked, minScorekeeper,
      minTimekeeperIsChecked, minTimekeeper,
      minRefereeGradeIsChecked, minRefereeGrade,
      minLinespersonGradeIsChecked, minLinespersonGrade,
      minAgeIsChecked, minAge,
    } = values;

    apiClient(`/games/bulkAssignSettings`, {
      method: 'POST',
      data: {
        gameIds: actionableGameIds,
        settings: JSON.parse(JSON.stringify({
          systemId: systemIdIsChecked ? systemId : undefined,
          feesId: feesIdIsChecked ? (feesId || null) : undefined,
          status: statusIsChecked ? status : undefined,
          minReferee: minRefereeIsChecked ? (minReferee || null) : undefined,
          minLinesperson: minLinespersonIsChecked ? (minLinesperson || null) : undefined,
          minOfficial: minOfficialIsChecked ? (minOfficial || null) : undefined,
          minScorekeeper: minScorekeeperIsChecked ? (minScorekeeper || null) : undefined,
          minTimekeeper: minTimekeeperIsChecked ? (minTimekeeper || null) : undefined,
          minRefereeGrade: minRefereeGradeIsChecked ? (minRefereeGrade || null) : undefined,
          minLinespersonGrade: minLinespersonGradeIsChecked ? (minLinespersonGrade || null) : undefined,
          minAge: minAgeIsChecked ? (minAge || null) : undefined,
        })),
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        onClose();
        refresh();
        notify(translate('resources.gameAssignSettings.notifications.updated_game_settings', count))
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        const errorName = err?.response?.data?.error?.name;
        const errorMessage = errorName && translate(`resources.gameAssignSettings.notifications.errors.${errorName}`);
        notify(errorMessage || 'resources.gameAssignSettings.notifications.updating_settings_error', 'error')
      })
  }

  return <>
    <Button label={translate('resources.gameAssignSettings.labels.set_assigning_settings')} onClick={() => setOpen(true)} disabled={!actionableGameIds.length} />
    <AssignSettingsDialog
      isOpen={open}
      selectedIds={actionableGameIds}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  </>
}

const CertifyDialog = ({ open, onSubmit, onClose, gameIds = [] }) => {
  const translate = useTranslate();
  const games = useSelector(state => state.admin.resources.games.data)

  return <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
    <DialogTitle>{translate('resources.scoresheets.labels.certify', gameIds.length)}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('resources.scoresheets.messages.confirm_certify', gameIds.length)}
      </DialogContentText>
      <SimpleForm
        save={onSubmit}
        component={Fragment}
        toolbar={<DialogFormToolbar submitLabel="ra.action.certify" cancelLabel="ra.action.cancel" onCancel={onClose} hideSubmit={!gameIds?.length} />}
      >
        {!!gameIds?.length &&
        <List>
          {gameIds.map(gameId => <ListItem>{`• ${games?.[gameId]?.number}`}</ListItem>)}
        </List>}
      </SimpleForm>
    </DialogContent>
  </Dialog>
}

const Certify = ({ selectedIds, resource }) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const statuses = useSelector(state => state.admin.resources.gameStatuses.data)
  const [ open, setOpen ] = useState(false);

  const actionableGameIds = selectedIds.filter(id => canCertify(statuses?.[id]))

  const onClose = () => {
    setOpen(false);
  }

  const onSubmit = async () => {
    apiClient(`/games/bulkCertify`, {
      method: 'POST',
      data: {
        gameIds: actionableGameIds,
      },
    })
      .then(({ data }) => {
        const count = data?.count || 0;
        onClose();
        refresh();
        notify(translate('resources.games.notifications.certified_games', count))
      })
      .catch(err => {
        console.log(err) // eslint-disable-line no-console
        notify(translate('resources.games.notifications.certified_games_error', actionableGameIds.length))
      })

    setOpen(false)
  }

  return <>
    <Button label={translate('ra.action.certify')} onClick={() => setOpen(true)} disabled={!actionableGameIds.length} />
    <CertifyDialog open={open} onSubmit={onSubmit} onClose={onClose} gameIds={actionableGameIds} />
  </>
}

export const GameBulkActions = props => {
  const scopes = useScopes();
  const canChangeStatuses = isAuthorized(scopes, 'games', 'changeStatus');
  const canManageAssigning = isAuthorized(scopes, 'games', 'assign');
  const canCertify = isAuthorized(scopes, 'scoresheets', 'full');

  return <>
    {canChangeStatuses && <GameStatus {...props} />}
    {canManageAssigning && <AssignStatus {...props} />}
    {canManageAssigning && <Delegate {...props} />}
    {canCertify && <Certify {...props} />}
  </>
}
