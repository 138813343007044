import React from "react"
import { GET_LIST, RecordContextProvider, TextField, useQuery, useRecordContext } from "react-admin"
import { makeStyles, Table, TableBody } from "@material-ui/core";

import { isAuthorized } from "../../common/Authorize";
import { EnumField } from "../../common/inputs/EnumInputs";
import InfoCard from "../../common/cards/InfoCard";
import Row from '../../common/cards/TableRow';
import FunctionField from "../../common/fields/FunctionField";

import { EditSuspensionDisciplineButton } from "./SuspensionDisciplineModal";

const useStyles = makeStyles(theme => ({
  comments: {
    whiteSpace: 'pre-line',
  }
}));

const isIndefinite = (durationType) => durationType === 'Indefinite';

const useSuspensionDiscipline = (suspensionId) => {
  const { data, ...rest } = useQuery({
    type: GET_LIST,
    resource: 'suspensionDisciplines',
    payload: { filter: { suspensionId } },

  }, {
    enabled: !!suspensionId
  })

  return { data: data?.[0], ...rest };
}

const renderDisciplineAdditionalGames = (record, source, resource, translate) => {
  const { disciplineAdditionalGames, disciplineDurationType } = record;

  return isIndefinite(disciplineDurationType) ? translate('resources.suspensions.values.durationType.Indefinite') : disciplineAdditionalGames;
};

export const SuspensionDisciplineCard = () => {
  const classes = useStyles();
  const suspension = useRecordContext();
  const { data: suspensionDiscipline } = useSuspensionDiscipline(suspension?.id);

  const canEditSuspensionDiscipline = isAuthorized(suspensionDiscipline, 'suspensionDisciplines', 'edit');

  if (!suspensionDiscipline) return null;

  return <InfoCard
    title="resources.suspensionDisciplines.name"
    actions={[
      canEditSuspensionDiscipline && <EditSuspensionDisciplineButton id={suspensionDiscipline?.id} size="small" />
    ]}
  >
    <RecordContextProvider value={suspensionDiscipline}>
      <Table>
        <TableBody>
          <Row label="resources.suspensions.labels.disciplineAdditionalGames">
            <RecordContextProvider value={suspension}>
              <FunctionField source="disciplineAdditionalGames" render={renderDisciplineAdditionalGames} />
            </RecordContextProvider>
          </Row>
          <Row label="resources.suspensionDisciplines.fields.status">
            <EnumField source="status" />
          </Row>
          <Row label="resources.suspensionDisciplines.fields.comments">
            <TextField source="comments" className={classes.comments} />
          </Row>
        </TableBody>
      </Table>
    </RecordContextProvider>
  </InfoCard>
}
