import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Grid, useMediaQuery } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AssignmentIndOutlined } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';

import { FF_DRAFT_GAMES, FF_SCOREKEEPERS } from '@hisports/common/featureFlags';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import { useFlag, useScopes } from '../../http';
import { isAuthorized } from '../../common/Authorize';
import { TabbedShowLayout, Tab } from '../../common/ra/TabbedShowLayout';
import SchedulerView from '../../common/calendar/scheduler/SchedulerView';

import AvailabilityCard from '../availabilities/AvailabilityCard';
import ReportsCard from '../reports/ReportsCard';
import ArenaSlotsCard from '../offices/cards/ArenaSlotsCard';
import { useBranchSettings } from '../branchOfficeSettings';

import SchedulesCard from './cards/SchedulesCard';
import TeamsCard from './cards/TeamsCard';
import ArenasCard from './cards/ArenasCard';
import RegistrationsCard from './cards/RegistrationsCard';
import QualificationsCard from './cards/QualificationsCard';
import AssignSettingsCard from './cards/OfficeAssignSettingsCard';
import GroupsCard from './cards/GroupsCard';
import RolesCard from './cards/RolesCard';
import NotificationCard from './cards/NotificationsCard';
import SectionsCard from './cards/SectionsCard';
import OfficesCard from './cards/OfficesCard';
import OfficialsCard from './cards/OfficialsCard';
import ListsCard from './cards/ListsCard';
import AssignSystemsCard from './cards/AssignSystemsCard';
import AssignFeesCard from './cards/AssignFeesCard';
import AssignRulesCard from './cards/AssignRulesCard';
import AssignTargetsCard from './cards/AssignTargetsCard';
import RulebooksCard from './cards/RulebooksCard';
import AttributeTypesCard from './cards/AttributeTypesCard';
import SchedulingConstraintsCard from './cards/SchedulingConstraintsCard';
import PayPeriodsCard from './cards/PayPeriodsCard';
import SuspensionsCard from './cards/SuspensionsCard';
import ClaimsCard from './cards/ClaimsCard';
import GamePenaltiesCard from './cards/GamePenaltiesCard';
import InfractionTypesCard from './cards/InfractionTypesCard';
import RulesCard from './cards/RulesCard';
import InfractionAccumulationsCard from './cards/InfractionAccumulationsCard';
import SanctionsCard from './cards/SanctionsCard';
import OfficeSettingsCard from './cards/OfficeSettingsCard';
import BranchOfficeSettingsCard from './cards/BranchOfficeSettingsCard';

const SummaryTab = props => {
  const office = useRecordContext(props);
  const scopes = useScopes()
  const { data: branchSettings } = useBranchSettings(office?.id)

  const isZone = office?.type === 'Zone';
  const isAssociation = office?.type === 'Association';

  const showAvailabilitiesCard = isAuthorized(office, 'availabilities', 'list') && !isZone
  const canEditAvailabilities = isAuthorized(office, 'availabilities', 'edit') && !isZone

  const showOffices = !isZone;
  const showTeams = isAuthorized(office, 'teams', 'list') && !isZone;
  const showSchedules = isAuthorized(office, 'schedules', 'list') && !isZone;
  const showGamePenalties = isAuthorized(office, 'gamepenalties', 'show')
  const showSuspensions = isAuthorized(office, 'suspensions', 'show') && branchSettings?.ff_suspensions;
  const showArenas = isAuthorized(office, 'surfaces', 'list');
  const showRegistrations = isAuthorized(office, 'registrations', 'list') && !isZone
  const showQualifications = isAuthorized(office, 'qualifications', 'list')
  const showReports = isAuthorized(scopes, 'reports', 'list');
  const showTimeSlots = isAuthorized(office, 'arenaslots', 'list') && ![...GAME_OFFICE_TYPES, 'Historical'].includes(office?.type);

  return <Tab {...props}>
    <Grid container spacing={3}>
      {showOffices && <Grid item xs={12}>
        <OfficesCard />
      </Grid>}
      {showSchedules && <Grid item xs={12}>
        <SchedulesCard filter={{ effectiveOffices: true, includeMembers: true }} collapsable />
      </Grid>}
      {showAvailabilitiesCard && <Grid item xs={12}>
        <AvailabilityCard collapsable={!isAssociation} editable={canEditAvailabilities} />
      </Grid>}
      <Grid item xs={12}>
        <GroupsCard />
      </Grid>
      {showGamePenalties && <Grid item xs={12}>
        <GamePenaltiesCard collapsable />
      </Grid>}
      {showSuspensions && <Grid item xs={12}>
        <SuspensionsCard collapsable />
      </Grid>}
      {showArenas && <Grid item xs={12}>
        <ArenasCard collapsable />
      </Grid>}
      {showTimeSlots && <Grid item xs={12}>
        <ArenaSlotsCard title="resources.arenaslots.labels.card.title" />
      </Grid>}
      {showTeams && <Grid item xs={12}>
        <TeamsCard collapsable />
      </Grid>}
      {showRegistrations && <Grid item xs={12}>
        <RegistrationsCard collapsable />
      </Grid>}
      {showQualifications && <Grid item xs={12}>
        <QualificationsCard collapsable={!isZone} />
      </Grid>}
      {showReports && <Grid item xs={12}>
        <ReportsCard targetType="Office" officeId={office.id} />
      </Grid>}
    </Grid>
  </Tab>
}

const SchedulerTab = props => {
  const office = useRecordContext();

  return <Tab {...props}>
    <SchedulerView office={office} />
  </Tab>
}

const AssigningTab = props => {
  const office = useRecordContext(props);
  const isEnabled = useFlag();

  if (!isAuthorized(office, 'offices', 'assigningTab')) return null;

  const showAssignSettings = isAuthorized(office, 'officeAssignSettings', 'list')
  const showPayPeriods = isAuthorized(office, 'payPeriods', 'list')
  const showClaimPeriods = isAuthorized(office, 'officialClaims', 'list')
  const showOfficials = isAuthorized(office, 'officialOffices', 'list')
  const showScorekeepers = showOfficials && isEnabled(FF_SCOREKEEPERS);
  const showLists = isAuthorized(office, 'lists', 'list')
  const showAssignSystems = isAuthorized(office, 'assignSystems', 'list')
  const showAssignFees = isAuthorized(office, 'assignFees', 'list')
  const showAssignRules = isAuthorized(office, 'assignRules', 'list')
  const showAssignTargets = isAuthorized(office, 'assignTargets', 'list')
  const showAttributes = isAuthorized(office, 'attributeTypes', 'list')

  return <Tab {...props}>
    <Grid container spacing={3}>
      {showAssignSettings && <Grid item xs={12} md={6}>
        <AssignSettingsCard />
      </Grid>}
      {showAssignSettings && <Grid item xs={12} md={6} />}
      {showPayPeriods && <Grid item xs={12}>
        <PayPeriodsCard />
      </Grid>}
      {showClaimPeriods && <Grid item xs={12}>
        <ClaimsCard />
      </Grid>}
      {showOfficials && <Grid item xs={12}>
        <OfficialsCard type="Official" />
      </Grid>}
      {showScorekeepers && <Grid item xs={12}>
        <OfficialsCard type="Scorekeeper" />
      </Grid>}
      {showLists && <Grid item xs={12}>
        <ListsCard />
      </Grid>}
      {showAttributes && <Grid item xs={12}>
        <AttributeTypesCard type="Officiating" targetType="Participant" />
      </Grid>}
      {showAssignSystems && <Grid item xs={12}>
        <AssignSystemsCard />
      </Grid>}
      {showAssignFees && <Grid item xs={12}>
        <AssignFeesCard />
      </Grid>}
      {showAssignRules && <Grid item xs={12}>
        <AssignRulesCard
          title="resources.assignRules.labels.card.title"
          subtitle="resources.assignRules.labels.card.subtitle"
          types={["minReferee", "minLinesperson", "minOfficial", "minScorekeeper", "minTimekeeper", "minRefereeGrade", "minLinespersonGrade", "minAge", "status"]} />
      </Grid>}
      {showAssignRules && <Grid item xs={12}>
        <AssignRulesCard
          title="resources.assignRules.labels.alt_card.title"
          subtitle="resources.assignRules.labels.alt_card.subtitle"
          icon={<AssignmentIndOutlined />}
          types={["officeId", "assignType"]} />
      </Grid>}
      {showAssignTargets && <Grid item xs={12}>
        <AssignTargetsCard />
      </Grid>}
    </Grid>
  </Tab>
}

const RulebookTab = props => {
  const office = useRecordContext(props);
  const translate = useTranslate();

  if (!isAuthorized(office, 'offices', 'rulebookTab')) return null;

  const hasParentOffice = !!office?.parentId

  const showRules = isAuthorized(office, 'ruleSections', 'list');
  const showTypes = isAuthorized(office, 'infractionTypes', 'list');
  const showAccumulations = isAuthorized(office, 'infractionAccumulations', 'list');
  const showSanctions = isAuthorized(office, 'sanctions', 'list')
  const showRulebooks = isAuthorized(office, 'rulebooks', 'list')
  const showAlert = hasParentOffice

  return <Tab {...props}>
    <Grid container spacing={3}>
      {showAlert && <Grid item xs={12}>
        <Alert severity="info">{translate('resources.rulebooks.alerts.rulebook_independence')}</Alert>
      </Grid>}
      {showRules && <Grid item xs={12}>
        <RulesCard />
      </Grid>}
      {showTypes && <Grid item xs={12}>
        <InfractionTypesCard />
      </Grid>}
      {showAccumulations && <Grid item xs={12}>
        <InfractionAccumulationsCard />
      </Grid>}
      {showSanctions && <Grid item xs={12}>
        <SanctionsCard />
      </Grid>}
      {showRulebooks && <Grid item xs={12}>
        <RulebooksCard />
      </Grid>}
    </Grid>
  </Tab>
}

const SettingsTab = props => {
  const office = useRecordContext(props);

  if (!isAuthorized(office, 'offices', 'settingsTab')) return null;

  const showGroups = isAuthorized(office, 'groups', 'list')
  const showRoles = isAuthorized(office, 'roles', 'list')
  const showNotifications = isAuthorized(office, 'notifications', 'list')
  const showSections = isAuthorized(office, 'sections', 'list')
  const canShowConstraints = isAuthorized(office, 'schedulingConstraints', 'list')
  const showSettings = isAuthorized(office, 'officeSettings', 'list')
  const showBranchSettings = isAuthorized(office, 'branchOfficeSettings', 'list') && office?.type === 'Branch'

  return <Tab {...props}>
    <Grid container spacing={3}>
      {showSettings && <Grid item xs={12} md={6}>
        <OfficeSettingsCard />
      </Grid>}
      {showBranchSettings && <Grid item xs={12} md={6}>
        <BranchOfficeSettingsCard />
      </Grid>}
      {showGroups && <Grid item xs={12}>
        <GroupsCard />
      </Grid>}
      {canShowConstraints && <Grid item xs={12}>
        <SchedulingConstraintsCard />
      </Grid>}
      {showRoles && <Grid item xs={12}>
        <RolesCard />
      </Grid>}
      {showNotifications && <Grid item xs={12}>
        <NotificationCard />
      </Grid>}
      {showSections && <Grid item xs={12}>
        <SectionsCard />
      </Grid>}
    </Grid>
  </Tab>
}

export const OfficeShowLayout = props => {
  const office = useRecordContext(props);
  const isEnabled = useFlag();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

  if (office?.type === 'League') return <Redirect to={`/leagues/${office?.id}/show`} />
  if (office?.type === 'Tournament') return <Redirect to={`/tournaments/${office?.id}/show`} />
  if (office?.type === 'Cup') return <Redirect to={`/cups/${office?.id}/show`} />

  const hasParentOffice = !!office?.parentId

  const showRulebook = isAuthorized(office, 'offices', 'rulebookTab') && (!hasParentOffice || office?.type === 'Branch')
  const showScheduler = isEnabled(FF_DRAFT_GAMES) && isAuthorized(office, 'offices', 'schedulerTab') && !isMobile

  return <TabbedShowLayout>
    <SummaryTab label="resources.offices.labels.tabs.summary" />
    {showScheduler && <SchedulerTab label="resources.offices.labels.tabs.scheduler" path="scheduler" />}
    <AssigningTab label="resources.offices.labels.tabs.assigning" path="assigning" />
    {showRulebook && <RulebookTab label="resources.offices.labels.tabs.rulebook" path="rulebook" />}
    <SettingsTab label="resources.offices.labels.tabs.settings" path="settings" />
  </TabbedShowLayout>
}
