import { downloadCSV } from 'react-admin';
import moment from 'moment-timezone';
import Papa from 'papaparse'

import { appName } from '../../flags';
import { getSurfaceName } from '../surfaces/util';

const gamesExporter = data => {
  const games = data.map(game => {
    const timezone = game.timezone;
    const homeTeam = game.homeTeam;
    const awayTeam = game.awayTeam;
    const schedule = game.schedule
    const surface = game.surface;

    return [
      game.scheduleId,
      schedule?.name,
      game.number,
      game.homeTeamId,
      homeTeam?.name,
      homeTeam?.HCRId,
      game.awayTeamId,
      awayTeam?.name,
      awayTeam?.HCRId,
      moment.utc(game.date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      moment.tz(game.startTime, timezone).format("HH:mm"),
      moment.tz(game.endTime, timezone).format("HH:mm"),
      timezone,
      getSurfaceName(surface, true),
      game.status,
      game.comments
    ]
  });

  const fields = ['Schedule ID', 'Schedule Name', 'Game Number', 'Home Team ID', 'Home Team Name', 'Home Team Registry ID', 'Away Team ID', 'Away Team Name', 'Away Team Registry ID', 'Date', 'Start Time', 'End Time', 'Timezone', 'Venue', 'Status', 'Comments'];

  const csv = Papa.unparse({
    fields,
    data: games
  }, {
    quotes: fields.map(columnName => ['Home Team Registry ID', 'Away Team Registry ID'].includes(columnName)),
  });
  downloadCSV(csv, `${appName} - Games - ${moment().format('YYYYMMDDHHmmss')}`);
};

gamesExporter._include = [
  'homeTeam',
  'awayTeam',
  'schedule',
  'surface',
]

export default gamesExporter;
