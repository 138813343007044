import React, { useRef } from 'react';
import { NumberInput, SimpleForm, TextInput, useTranslate } from 'react-admin';
import { Grid, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Restore } from '@material-ui/icons';
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';

import { isEmpty } from '@hisports/parsers';
import { GAME_OFFICE_TYPES } from '@hisports/common/src/constants';

import Toolbar from '../../common/ra/Toolbar';
import TimeInput from '../../common/inputs/BasicTimeInput';
import { AssignRuleStatusEnumInput, AssignRuleTypeEnumInput, AssignTypeEnumInput, DayEnumInput, ScheduleTypeEnumInput } from '../../common/inputs/EnumInputs';

import { OfficeInput } from '../offices/OfficeInput';
import { OfficeGroupInput } from '../groups/GroupInput';
import { CategoryInput } from '../categories/CategoryInput';
import { AssignSystemInput } from '../assignSystems';
import { AssignFeesInput } from '../assignFees';
import SwitchInput from '../../common/inputs/SwitchInput';
import { DateInput } from '../../common/inputs/DateInput';
import { InformationTooltip } from '../../common/InformationTooltip';
import { useRichTranslate } from '../../common/useRichTranslate';

const inputProps = {
  resource: 'assignRules',
  basePath: '/assignRules',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const validate = values => {
  const errors = {};
  switch (values.type) {
    case 'minReferee':
    case 'minLinesperson':
    case 'minOfficial':
    case 'minScorekeeper':
    case 'minTimekeeper':
    case 'minRefereeGrade':
    case 'minLinespersonGrade':
    case 'minAge': {
      if (values.value <= 0) errors.value = 'ra.validation.greater_than_zero'
    }
  }
  if (values.type == null) errors.type = 'ra.validation.required'
  if (values.value == null) errors.value = 'ra.validation.required'

  if (values.order == null) {
    errors.order = 'ra.validation.required'
  } else if (values.order <= 0) {
    errors.order = 'ra.validation.greater_than_zero'
  }
  return errors;
}

export const AssignRuleValueInput = ({ ...props }) => {
  const { values = {} } = useFormState();
  const { value: assignValue } = values

  switch (values.type) {
    case 'assignType':
      return <AssignTypeEnumInput helperText={`resources.assignRules.helpers.${assignValue ? `assignTypes.${assignValue}` : 'assignType'}`} {...inputProps} {...props} />

    case 'officeId':
      return <OfficeInput filter={{ type: { nin: [...GAME_OFFICE_TYPES, 'Historical'] }, isAssigner: true }} helperText="resources.assignRules.helpers.officeId" {...inputProps} {...props} />

    case 'systemId':
      return <AssignSystemInput {...inputProps} {...props} />

    case 'feesId':
      return <AssignFeesInput {...inputProps} {...props} />

    case 'minReferee':
    case 'minLinesperson':
    case 'minOfficial':
    case 'minScorekeeper':
    case 'minTimekeeper':
    case 'minRefereeGrade':
    case 'minLinespersonGrade':
    case 'minAge':
      return <NumberInput {...inputProps} {...props} />

    case 'status':
      return <AssignRuleStatusEnumInput {...inputProps} {...props} />

    default:
      return <TextInput {...inputProps} {...props} disabled />
  }
}

export const AssignRuleOfficeInput = props => {
  const { values } = useFormState();
  let helperText;
  switch (values.type) {
    case 'assignType':
    case 'officeId':
      helperText = 'resources.assignRules.helpers.scheduledBy';
      break;
    default:
      if (!values.type) break;
      helperText = 'resources.assignRules.helpers.assignedBy';
      break;
  }
  return <OfficeInput helperText={helperText} {...props} />
}

const RuleValueInput = props => {
  const translate = useRichTranslate()
  const { values: { type } } = useFormState();

  const isAssignVia = type === 'assignType';

  return <Grid container spacing={2}>
    <Grid item xs={isAssignVia ? 11 : 12}>
      <AssignRuleValueInput source="value" {...inputProps} />
    </Grid>
    {isAssignVia && <Grid item xs={1} container style={{ paddingTop: 22 }}>
      <InformationTooltip
        text={<div dangerouslySetInnerHTML={{ __html: translate('resources.assignRules.tooltips.determine_via') } } />}
        fontSize="medium"
        placement="top"
        width={1000}
      />
    </Grid>}
  </Grid>
}

export const AssignRuleForm = ({ types, ...props }) => {
  const { type, value } = props.initialValues || {}

  const translate = useTranslate();
  const decorators = useRef([createCalculator({
    field: 'type',
    updates: {
      value: (type, values, prevValues) => {
        if (!isEmpty(prevValues) && prevValues.type != type) {
          // Weird behavior when saving if we dont reset the value initialValues
          props.initialValues.value = null;
          return null;
        }

        return values.value;
      }
    }
  })])

  return <>
    <Alert severity="info" icon={<Restore />} fullWidth>
      <AlertTitle>{translate('resources.assignRules.alerts.new_games_only_title')}</AlertTitle>
      <span dangerouslySetInnerHTML={{ __html: translate('resources.assignRules.alerts.new_games_only') }}></span>
    </Alert>
    <br />
    <SimpleForm toolbar={<Toolbar />} decorators={decorators.current} validate={validate} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} md={4}>
          <AssignRuleTypeEnumInput source="type" types={types} {...inputProps} />
        </Grid>

        <Grid item xs={12} md={8}>
          <RuleValueInput />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">{translate('resources.assignRules.labels.criteria')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CategoryInput source="categoryIds" multiple allowEmpty emptyLabel="All" helperText="resources.assignRules.helpers.categoryIds" {...inputProps} />
        </Grid>

        <Grid item xs={12}>
          <ScheduleTypeEnumInput source="types" multiple allowEmpty emptyLabel="All" helperText="resources.assignRules.helpers.types" {...inputProps} />
        </Grid>

        <Grid item xs={12}>
          <OfficeGroupInput source="groupIds" multiple allowEmpty helperText="resources.assignRules.helpers.groupIds" {...inputProps} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <NumberInput source="attributes.duration" helperText="resources.assignRules.helpers.duration" {...inputProps} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DayEnumInput source="attributes.days" multiple helperText="ra.message.optional" {...inputProps} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TimeInput source="attributes.time" helperText="ra.message.optional" {...inputProps} />
        </Grid>

        <Grid item xs={12} md={4}>
          <NumberInput source="order" initialValue={1} {...inputProps} />
        </Grid>

        <Grid item xs={12} md={4}>
          <AssignRuleOfficeInput source="officeId" disabled {...inputProps} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <DateInput source="attributes.effectiveDate" helperText="ra.message.optional" {...inputProps} />
        </Grid>

        <Grid item xs={12}>
          <SwitchInput source="stopInheritance" helperText="resources.assignRules.helpers.stopInheritance" {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
