import React, { useRef } from "react";
import { NumberInput, SimpleForm, TextInput, useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import createCalculator from 'final-form-calculate';

import { FieldDependency } from "../../common/FieldDependency";
import { DisciplineStatusInput, DurationTypeInput } from "../../common/inputs/EnumInputs";

const INDEFINITE = 'Indefinite';
const DEFINITE = 'Definite';

const validate = (values, requiredGames) => {
  const errors = {};

  if (values.durationType === DEFINITE) {
    if (values.additionalGames == null) {
      errors.additionalGames = 'ra.validation.required';
    } else if (requiredGames + values.additionalGames < 1) {
      errors.additionalGames = 'resources.suspensionDisciplines.validations.must_be_greater_than_zero';
    }
  } else if (values.durationType === INDEFINITE && values.additionalGames >= 0) {
    errors.additionalGames = 'resources.suspensionDisciplines.validations.must_be_empty';
  }

  if (!values.durationType) {
    errors.durationType = 'ra.validation.required';
  }

  if (!values.status) {
    errors.status = 'ra.validation.required';
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const AdditionalGamesField = ({ requiredGames }) => {
  const translate = useTranslate();
  const { values } = useFormState();

  const totalGames = values.durationType === DEFINITE
    ? requiredGames + (values?.additionalGames || 0)
    : '∞';

  return <FieldDependency fieldSource="durationType" test={durationType => durationType !== INDEFINITE} disabled>
    <NumberInput
      source="additionalGames"
      label="resources.suspensionDisciplines.fields.additionalGames"
      helperText={translate('resources.suspensionDisciplines.helpers.totalGames', { totalGames })}
      {...inputProps}
    />
  </FieldDependency>
}

export const SuspensionDisciplineForm = ({ initialValues, ...props }) => {
  const translate = useTranslate();
  const suspensions = useSelector(state => state?.admin?.resources?.suspensions?.data);

  const suspension = suspensions[initialValues?.suspensionId];
  const requiredGames = suspension?.requiredGames;

  const decorators = useRef([createCalculator({
    field: 'durationType',
    updates: {
      additionalGames: (durationType, values, prevValues) => {
        if (durationType === INDEFINITE) {
          return;
        }
        return values.additionalGames;
      }
    }
  })]);

  return <SimpleForm decorators={decorators.current} validate={values => validate(values, requiredGames)} initialValues={initialValues} {...props}>
    <Grid container spacing={2} fullWidth>
      <Grid item xs={12}>
        <Alert severity="info" style={{ marginBottom: 8 }}>
          <span dangerouslySetInnerHTML={{ __html: translate('resources.suspensionDisciplines.alerts.additionalGames') }}></span>
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <AdditionalGamesField requiredGames={requiredGames} />
      </Grid>
      <Grid item xs={12}>
        <DurationTypeInput source="durationType" label="resources.suspensionDisciplines.fields.durationType" radio helperText="" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <TextInput source="comments" label="resources.suspensionDisciplines.fields.comments" multiline minRows="3" {...inputProps} />
      </Grid>
      <Grid item xs={12}>
        <DisciplineStatusInput source="status" label="resources.suspensionDisciplines.fields.status" {...inputProps} />
      </Grid>
    </Grid>
  </SimpleForm>
}
