import React, { memo, useEffect, useMemo, useState } from 'react';
import { ListContextProvider, RecordContextProvider, useDataProvider, useListContext, useRecordContext, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormHelperText, Grid, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { Edit, EventBusy } from '@material-ui/icons';

import { isEmpty } from '@hisports/parsers';

import { useScopes } from '../../../http';
import { isAuthorized } from '../../Authorize';
import { useList } from '../../ra/useList';
import { useSeason } from '../../inputs/SeasonSelector';
import ListCard from '../../cards/ListCard';

import { EventReferenceManyField } from '../../../resources/events/EventReferenceField';
import EventCalendar from '../../../resources/events/EventCalendar';
import { CALENDAR_VIEWS, EventViewSettingsContext, EventViewSettingsMenu, GAME_LIST_FILTERS, useCalendarView, useDatelessView, useGameListFilter, useReverseHomeAway, useShowAwaySlots, useShowCalendar, useShowGameInfo } from '../../../resources/events/EventViewSettings';
import { EditGameDialog } from '../../../resources/games/GameModal';
import { ShareDraftGameAction } from '../../../resources/draftgames/DraftGameShareModal';
import { PublishDraftGamesAction } from '../../../resources/draftgames/DraftGamesPublishModal';
import { EditDraftGameDialog } from '../../../resources/draftgames/DraftGameModal';
import { DraggableEventRow, ScheduleButton, UnscheduleButton as RemoveDate, useLimitDateChange } from '../../../resources/events/EventGroupedGrid';
import DraggableGameList from '../../../resources/drafts/draggables/DraggableGameList';
import DraggableEvent from '../../../resources/drafts/draggables/DraggableEvent';
import ScheduleSequenceAlert from '../../../resources/schedulesequences/ScheduleSequenceAlert';
import { usePrevious } from '../../hooks/hooks';

import { SchedulingProvider, useSchedulingContext } from '../SchedulingContext';
import { GameListFilterInput, getGameListFilters } from './GameListFilters';
import { CalendarProvider } from '../CalendarContext';
import { DragProvider } from '../DragContext';
import { isDraft, isGame } from '../EventDetails';
import { getResourceByType } from '../Events';
import { AddMenu } from './AddMenu';

const useStyles = makeStyles(theme => ({
  gameList: {
    borderRadius: 0,
    height: '100%',
  },
  selectedGameActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  table: {
    width: '100%',
    backgroundColor: 'lightgrey'
  }
}))

const getFilter = (office, seasonId) => {
  if (!office) return;

  return {
    seasonId,
    ...(['League', 'Tournament', 'Cup'].includes(office.type) ? { scheduleOffices: office.id } : { or: [{ scheduleOffices: office.id }, { homeTeamOffices: office.id, isShared: true }] })
  }
}

const UnscheduleDialog = ({ open, setOpen, handleConfirm }) => {
  const translate = useTranslate();
  const [ view ] = useCalendarView();
  const [ removeDate, setRemoveDate ] = useState(view === CALENDAR_VIEWS.SEASON);
  const disableDateChange = useLimitDateChange();

  const onClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  }

  const handleCheckboxChange = e => {
    const checked = e.target.checked;
    setRemoveDate(checked);
  }

  return <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>{translate('components.calendar.labels.unschedule')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {translate('components.calendar.messages.remove_time_field')}
      </DialogContentText>
      <DialogContentText>
        <FormControlLabel
          label={translate('components.calendar.labels.remove_date')}
          control={<Checkbox checked={removeDate} onChange={handleCheckboxChange} disabled={disableDateChange} />}
        />
        {disableDateChange && <FormHelperText error>{translate('resources.games.alerts.no_change_date_permission')}</FormHelperText>}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} autoFocus>{translate('ra.action.cancel')}</Button>
      <Button onClick={() => handleConfirm(removeDate)} color="primary">{translate('ra.action.confirm')}</Button>
    </DialogActions>
  </Dialog>
}

const EventViewSettingsProvider = ({ ...props }) => {
  const groupDates = useState(true);
  const groupArenas = useState(false);
  const groupRounds = useState(false);
  const assignments = useState(false);
  const gameInfo = useState(false);
  const flags = useState(false);
  const reverseHomeAway = useReverseHomeAway()
  const calendar = useState(false);
  const calendarView = useCalendarView()
  const teamEvents = useState(false);
  const slots = useState(true);
  const teamNames = useState(false)
  const officeNames = useState(false)
  const condensedView = useState(true)
  const availabilities = useState(false);
  const surfaceSizes = useState([]);
  const gameListFilter = useState(GAME_LIST_FILTERS.ALL_GAMES);
  const value = useMemo(() => ({
    calendar,
    calendarView,
    teamEvents,
    slots,
    teamNames,
    officeNames,
    condensedView,
    availabilities,
    gameInfo,
    flags,
    reverseHomeAway,
    groupDates,
    groupArenas,
    groupRounds,
    assignments,
    surfaceSizes,
    gameListFilter
  }), [ calendar, calendarView, teamEvents, slots, availabilities, gameInfo, flags, reverseHomeAway, groupDates, groupArenas, groupRounds, assignments, surfaceSizes, gameListFilter, teamNames, officeNames, condensedView ])
  return <EventViewSettingsContext.Provider value={value} {...props} />
}

export const UnscheduleButton = ({ setUnscheduleGame, ...props }) => {
  const translate = useTranslate();
  const game = useRecordContext();

  if (isGame(game)) return null;

  const onClick = (e) => {
    e.stopPropagation();
    setUnscheduleGame(game)
  }

  return <Tooltip title={translate('components.calendar.tooltips.unschedule')}>
    <IconButton onClick={onClick} size="small">
      <EventBusy fontSize="medium" />
    </IconButton>
  </Tooltip>
}

const EditButton = ({ setGame, ...props }) => {
  const game = useRecordContext();
  const translate = useTranslate();

  const canEdit = isAuthorized(game, 'games', 'edit');
  if (!canEdit) return null;

  const onClick = (e) => {
    e.stopPropagation();
    setGame(game)
  }

  return <Tooltip title={translate('resources.games.actions.edit')}>
    <IconButton color="primary" size="small" onClick={onClick}>
      <Edit fontSize="small" />
    </IconButton>
  </Tooltip>
}

const Calendar = memo(({ setEditGame, setUnscheduleGame, ...props }) => {
  const { data, ids, ...listContext } = useListContext()

  const games = useMemo(() => {
    return ids.map(id => data[id])
  }, [data, ids])

  const contextValue = useMemo(() => ({
    ...listContext,
    data: games,
    ids: games.map(game => game.id),
    loaded: true,
    loading: false,
    page: 1,
    perPage: 99999
  }), [games, listContext])

  const context = useList(contextValue)

  const popoverActions = useMemo(() => [
    <UnscheduleButton setUnscheduleGame={setUnscheduleGame} />,
    <EditButton setGame={setEditGame} />
  ], [setUnscheduleGame, setEditGame]);

  return <ListContextProvider value={context}>
    <CalendarProvider disableAuth hideArena popoverActions={popoverActions}>
      <EventCalendar />
    </CalendarProvider>
  </ListContextProvider>
})

const SelectedGame = memo(({ setEditGame, setUnscheduleGame, ...props }) => {
  const { selectedGame } = useSchedulingContext();
  const classes = useStyles()

  if (!selectedGame) return null;

  const isScheduled = selectedGame.startTime != null && selectedGame.endTime != null && selectedGame.arenaId != null;
  const showCalendar = !isScheduled && !selectedGame?.date;
  const showClear = selectedGame?.date && !isScheduled

  const actions = [
    showCalendar && <ScheduleButton size="small" />,
    showClear && <RemoveDate size="small" />,
    isScheduled && <UnscheduleButton setUnscheduleGame={setUnscheduleGame} />,
    <EditButton setGame={setEditGame} />,
  ].filter(Boolean)

  return <RecordContextProvider value={selectedGame}>
    <table className={classes.table}>
      <DraggableEventRow
        actions={actions}
        event={selectedGame}
        dragComponent={DraggableEvent}
        {...props}
      />
    </table>
  </RecordContextProvider>
})

const GameList = memo(({ setEditGame, setUnscheduleGame, showAdd, ...props }) => {
  const upperListContext = useListContext()
  const { selectedGame, setSelectedGame } = useSchedulingContext();
  const [ view, setView ] = useCalendarView();
  const previousView = usePrevious(view);
  const [ gameListFilter, setGameListFilter ] = useGameListFilter()
  const classes = useStyles();

  const isSeasonView = view === CALENDAR_VIEWS.SEASON;
  const wasSeasonView = previousView === CALENDAR_VIEWS.SEASON;

  const { data, ids } = useMemo(() => {
    return {
      data: upperListContext.ids.map(id => upperListContext.data[id]),
      ids: upperListContext.ids,
    }
  }, [upperListContext.ids, upperListContext.data])

  useEffect(() => {
    if (!wasSeasonView && isSeasonView) {
      setGameListFilter(GAME_LIST_FILTERS.DRAFT_GAMES_NO_DATES)
    }
    if (wasSeasonView && !isSeasonView) {
      setGameListFilter(GAME_LIST_FILTERS.ALL_GAMES)
    }
  }, [wasSeasonView, isSeasonView, setGameListFilter])

  useEffect(() => {
    if (!wasSeasonView && isSeasonView && !isEmpty(upperListContext.filterValues)) {
      // clear all filters when entering season view
      upperListContext.setFilters({})
    }
  }, [wasSeasonView, isSeasonView, upperListContext])

  const filter = useMemo(() => {
    const listFilter = {}
    switch (gameListFilter) {
      case GAME_LIST_FILTERS.ALL_DRAFT_GAMES:
        listFilter.type = ['Draft', 'Draft Game']
        break;
      case GAME_LIST_FILTERS.DRAFT_GAMES_NO_DATES:
        listFilter.type = ['Draft', 'Draft Game']
        listFilter.date = null
        break;
      case GAME_LIST_FILTERS.DRAFT_GAMES_NO_TIMES:
        listFilter.type = ['Draft', 'Draft Game']
        listFilter.missingStartTime = true
        break;
      case GAME_LIST_FILTERS.ALL_PUBLISHED_GAMES:
        listFilter.type = 'Game'
    }

    return {
      ...upperListContext.filterValues,
      ...listFilter
    }
  }, [ gameListFilter, upperListContext.filterValues ])

  const contextValue = useMemo(() => ({
    data,
    ids,
    loaded: true,
    loading: false,
    perPage: 50,
    sort: upperListContext.currentSort,
    filter,
  }), [data, filter, ids, upperListContext.currentSort])

  const context = useList(contextValue)

  const handleRowSelect = useMemo(() => (id, basePath, game) => {
    if (selectedGame?.id === game?.id) {
      // unselecting the selected row
      setSelectedGame(null)
      if (view === CALENDAR_VIEWS.DAY) {
        setView(CALENDAR_VIEWS.MONTH)
      }
    } else {
      // selecting a new row
      setSelectedGame(game)
    }
  }, [selectedGame?.id, setSelectedGame, setView, view])

  const actions = useMemo(() => [
    <EventViewSettingsMenu
      disableCalendar
      disableAssignments
      disableGroupRounds={false}
      disableGroupArenas={false}
      alwaysOn
      showTeamEvents={false}
      showCondensedView={false}
      showSlots={false}
      showAvailabilties={false}
      showSurfaceSizes={false}
      showFlagsInput={false}
      showRenumber={false}
      showGameInfoInput={false}
    />
  ], [])

  const filters = useMemo(() => [
    <GameListFilterInput alwaysOn />
  ], [])


  const listViewProps = useMemo(() => {
    const headerRow = <SelectedGame
      rowClick={handleRowSelect}
      setEditGame={setEditGame}
      setUnscheduleGame={setUnscheduleGame}
    />
    return {
      filters,
      actions,
      headerRow,
      fill: true,
      noResultsText: false,
      addButton: showAdd ? <AddMenu /> : null,
      addPosition: false,
      rowsPerPageOptions: false,
      compactToolbar: true
    }
  }, [actions, filters, handleRowSelect, setEditGame, setUnscheduleGame, showAdd])

  return <ListContextProvider value={context}>
    <DraggableGameList
      className={classes.gameList}
      rowClick={handleRowSelect}
      dragComponent={DraggableEvent}
      hideActions
      listViewProps={listViewProps}
    />
  </ListContextProvider>
})

const View = memo((props) => {
  const { onSave, setSelectedGame, setRefreshAvailability } = useSchedulingContext()
  const [ view ] = useCalendarView();

  const [ unscheduleGame, setUnscheduleGame ] = useState(false);
  const [ editGame, setEditGame ] = useState(false);

  const handleUnschedule = async (removeDate) => {
    if (!unscheduleGame) return;

    const newGame = { ...unscheduleGame, startTime: null, endTime: null, surfaceId: null, arenaId: null }

    if (removeDate) {
      newGame.date = null;
    }

    await onSave(newGame);

    setSelectedGame(newGame)
    setUnscheduleGame(false)
    if (view === CALENDAR_VIEWS.SEASON) {
      setRefreshAvailability(true)
    }
  }

  const onSubmitSuccess = (event) => {
    setSelectedGame(event)
    if (view === CALENDAR_VIEWS.SEASON) {
      setRefreshAvailability(true)
    }
  }

  return <>
    <Grid container fullWidth spacing={0}>
      <Grid item xs={3}>
        <EventViewSettingsProvider>
          <GameList setEditGame={setEditGame} setUnscheduleGame={setUnscheduleGame} {...props} />
        </EventViewSettingsProvider>
      </Grid>
      <Grid item xs={9}>
        <Calendar setEditGame={setEditGame} setUnscheduleGame={setUnscheduleGame} />
      </Grid>
    </Grid>
    {!!unscheduleGame && <UnscheduleDialog
      open={!!unscheduleGame}
      setOpen={setUnscheduleGame}
      handleConfirm={handleUnschedule}
    />}
    <EditDraftGameDialog isOpen={editGame && isDraft(editGame)} id={editGame?.id} handleClose={() => setEditGame(false)} onSuccess={onSubmitSuccess} skipRefresh />
    <EditGameDialog isOpen={editGame && isGame(editGame)} id={editGame?.id} handleClose={() => setEditGame(false)} onSuccess={onSubmitSuccess} skipRefresh />
  </>
})

export default memo(({ office, schedule, scheduleSettings, setGameId, ...props }) => {
  const scopes = useScopes();
  const seasonId = useSeason();
  const [ showCalendar, setShowCalendar ] = useShowCalendar()
  const [ calendarView, setCalendarView ] = useCalendarView();
  const [ datelessView, setDateLessView ] = useDatelessView();
  const [ showGameInfo, setShowGameInfo ] = useShowGameInfo();
  const [ showAwaySlots, setShowAwaySlots ] = useShowAwaySlots()
  const dataProvider = useDataProvider();
  const groupsData = useSelector(state => state.admin.resources.groups.data);

  const officeId = office?.id || schedule?.officeId;
  const scheduleId = schedule?.id;
  const surfaceOfficeId = scheduleSettings?.surfaceOfficeId

  const target = office ? 'officeId' : 'scheduleId'

  const canPublishDrafts = target === 'officeId' ? false : isAuthorized(schedule, 'schedules', 'publish');
  const canShareDrafts = target === 'officeId' ? isAuthorized(office, 'offices', 'share') : isAuthorized(schedule, 'schedules', 'share');

  const onSave = async (event = {}) => {
    const { id, resource: eventResource, ...rest } = event || {};
    await dataProvider.update(getResourceByType(event) || eventResource, { id, data: { ...rest } })
  }

  useEffect(() => {
    // on mount
    setShowCalendar(true)
    setShowGameInfo(false)
    setCalendarView(target === 'scheduleId' ? datelessView : CALENDAR_VIEWS.MONTH)
    // on unmount
    return () => setShowCalendar(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (![CALENDAR_VIEWS.MONTH, CALENDAR_VIEWS.SEASON].includes(calendarView)) return;

    if (calendarView === CALENDAR_VIEWS.SEASON) {
      setShowAwaySlots(false)
    }

    setDateLessView(calendarView)
  }, [calendarView, setDateLessView, setShowAwaySlots])

  const showAdd = target === 'scheduleId';

  const actions = useMemo(() => [
    canShareDrafts && <ShareDraftGameAction office={office} schedule={schedule} />,
    canPublishDrafts && <PublishDraftGamesAction office={office} schedule={schedule} />,
    <EventViewSettingsMenu
      disableCalendar
      disableAssignments
      disableGroupRounds
      disableGroupArenas
      disableSeasonView={target !== 'scheduleId'}
      alwaysOn
      showTeamEvents={calendarView === CALENDAR_VIEWS.DAY}
      showSlots={[CALENDAR_VIEWS.DAY, CALENDAR_VIEWS.MONTH].includes(calendarView)}
      showAwaySlots={[CALENDAR_VIEWS.MONTH, CALENDAR_VIEWS.SEASON].includes(calendarView) && !surfaceOfficeId}
      showTeamNames={calendarView === CALENDAR_VIEWS.MONTH}
      showOfficeNames={calendarView === CALENDAR_VIEWS.SEASON}
      showCondensedView={calendarView === CALENDAR_VIEWS.SEASON}
      showAvailabilties={calendarView === CALENDAR_VIEWS.DAY}
      showViewToggle
      showSurfaceSizes={calendarView === CALENDAR_VIEWS.DAY}
      showFlagsInput={false}
      showRenumber={false}
      showGameInfoInput={false}
    />
  ].filter(Boolean), [calendarView, canPublishDrafts, canShareDrafts, office, schedule, surfaceOfficeId, target])

  const filter = getFilter(office, seasonId);
  const filters = useMemo(() => {
    return getGameListFilters(office, schedule, groupsData, scopes, seasonId, calendarView)
  }, [groupsData, office, schedule, scopes, seasonId, calendarView])

  return <>
    {target === 'scheduleId' && <ScheduleSequenceAlert scheduleId={scheduleId} style={{ marginBottom: 8 }} />}
    <ListCard
      resource="games"
      target={target}
      base={EventReferenceManyField}
      baseProps={{
        includeGames: true,
        includeDraftGames: true,
        includePractices: false,
        includeActivities: false,
        includeSlots: false,
        includeAvailabilities: false
      }}
      addButton={showAdd ? <AddMenu /> : null}
      filters={filters}
      filter={filter}
      actions={actions}
      noResultsText={false}
      sort={{ field: ['date', 'startTime', 'number', 'id'], order: ['ASC', 'ASC', 'ASC', 'ASC'] }}
      rowsPerPageOptions={false}
      perPage={99999}
      pagination={null}
    >
      <SchedulingProvider scheduleId={scheduleId} onSave={onSave} officeId={officeId} showSchedule={target === 'officeId'}>
        <DragProvider>
          <View showAdd={showAdd} />
        </DragProvider>
      </SchedulingProvider>
    </ListCard>
  </>
})
