import React, { useState } from "react";
import { useDataProvider, useNotify, useRecordContext, useRefresh, useTranslate } from "react-admin";
import { Button } from "@material-ui/core";
import { Add, Edit, GroupAdd } from "@material-ui/icons";


import { CreateResourceDialog, EditResourceDialog } from "../../common/dialogs/ResourceDialog";
import { DialogDatagrid } from "../../common/dialogs/DialogDatagrid";
import ConfirmDialog from "../../common/dialogs/ConfirmDialog";

import { SuspensionDisciplineForm } from "./SuspensionDisciplineForm";

const AddSuspensionDisciplineDialog = props =>
  <CreateResourceDialog label="resources.suspensionDisciplines.actions.add" resource="suspensionDisciplines" basePath="/suspensionDisciplines" {...props}>
    <SuspensionDisciplineForm />
  </CreateResourceDialog>

const EditSuspensionDisciplineDialog = props =>
  <EditResourceDialog label="resources.suspensionDisciplines.labels.edit" resource="suspensionDisciplines" basePath="/suspensionDisciplines" canDelete={false} {...props}>
    <SuspensionDisciplineForm />
  </EditResourceDialog>

export const AddSuspensionDisciplineButton = ({ size, variant, icon = <Add />, label = true, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={icon} onClick={() => setOpen(true)}>{label && translate('resources.suspensionDisciplines.actions.add')}</Button>
    <AddSuspensionDisciplineDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const EditSuspensionDisciplineButton = ({ size, variant, icon = <Edit />, label = true, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={icon} onClick={() => setOpen(true)}>{label && translate('resources.suspensionDisciplines.labels.edit')}</Button>
    <EditSuspensionDisciplineDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const TriggerSuspensionDiscplineButton = ({ size, variant, icon = <GroupAdd />, label = true, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const suspension = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleTrigger = async () =>
    await dataProvider.create('suspensionDisciplines', {
      data: { suspensionId: suspension.id, status: 'Pending', additionalGames: 0, durationType: 'Definite' }
    })
      .then(() => {
        notify('resources.suspensionDisciplines.notifications.trigger_success', 'success');
        setOpen(false)
        refresh();
      })
      .catch(() => {
        notify('resources.suspensionDisciplines.notifications.trigger_error', 'error');
        setOpen(false);
      })


  return <>
    <Button color="primary" size={size} variant={variant} startIcon={icon} onClick={() => setOpen(true)}>{label && translate('resources.suspensionDisciplines.actions.trigger')}</Button>
    <ConfirmDialog
      open={isOpen}
      setOpen={setOpen}
      handleConfirm={handleTrigger}
      title={translate('resources.suspensionDisciplines.labels.confirm_trigger')}
      text={translate('resources.suspensionDisciplines.messages.confirm_trigger')}
      {...props}
    />
  </>
}

export const SuspensionDisciplineDatagrid = props => <DialogDatagrid dialog={EditSuspensionDisciplineDialog} {...props} />
