import React from 'react';
import { TextField, useListContext, useRecordContext, useTranslate } from 'react-admin';
import { makeStyles, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import FunctionField from '../../common/fields/FunctionField'

import { ParticipantField } from '../participants/ParticipantField';
import { TargetField } from '../targets/TargetField';
import { TargetTypeField } from '../targets/TargetTypeField';
import { CategoriesField } from '../categories/CategoriesField';
import { GroupsField } from '../groups/GroupsField';
import { SeasonField } from '../seasons/SeasonField';

import { NotificationDatagrid } from './NotificationModal'

const useStyles = makeStyles(theme => ({
  icon: {
    position: 'absolute',
    paddingLeft: theme.spacing(0.5),
  }
}))

const DestinationField = ({ showContact, ...props }) => {
  const notification = useRecordContext(props);

  if (!notification) return null;
  if (!notification.participantId) return <TextField source="email" {...props} />
  return <ParticipantField {...props} source="participantId" />
}

const TypesField = props => {
  const classes = useStyles();

  return <FunctionField {...props} render={(notification, source, resource, translate) => {
    const types = [
      notification.scheduling && translate('resources.notifications.fields.scheduling'),
      notification.activities && translate('resources.notifications.fields.activities'),
      notification.assigning && translate('resources.notifications.fields.assigning'),
      notification.gameReport && translate('resources.notifications.fields.gameReport'),
      notification.majorPenalty && translate('resources.notifications.fields.majorPenalty'),
      notification.suspension && translate('resources.notifications.fields.suspension'),
      notification.drafts && translate('resources.notifications.fields.drafts'),
    ].filter(Boolean)
    const isDefault = !Number.isInteger(notification.id)

    const notifications = [
      types.length ? types.join(', ') : translate('ra.input.autocomplete.none'),
      isDefault ? `(${translate('ra.message.default')})` : null,
    ].filter(Boolean).join(' ')

    return <div>
      {notifications}
      {isDefault && <span>
        <Tooltip title={<Typography variant="body2">{translate('resources.notifications.helpers.default')}</Typography>}>
          <InfoOutlined color="primary" fontSize="small" className={classes.icon} />
        </Tooltip>
      </span>
      }
    </div>
  }} />
}


const has = (ids, data, property) =>
  ids?.some(id => data?.[id]?.[property]?.length > 0)

export const NotificationGrid = ({ showTarget = false, showParticipant = true, showSeason = true, ...props }) => {
  const { ids = [], data } = useListContext(props);
  const translate = useTranslate();

  const showCategories = has(ids, data, 'categoryIds')
  const showGroups = has(ids, data, 'groupIds')

  return <NotificationDatagrid size="medium">
    {showTarget && <TargetTypeField source="targetType" label="" />}
    {showTarget && <TargetField source="targetId" />}
    {showParticipant && <DestinationField source="participantId" />}
    <TypesField label={translate('resources.notifications.labels.types')} />
    {showCategories && <CategoriesField source="categoryIds" emptyText="ra.message.all" />}
    {showGroups && <GroupsField source="groupIds" emptyText="ra.message.all" showType />}
    {showSeason && <SeasonField source="seasonId" />}
  </NotificationDatagrid>
}
