import React from 'react';
import { useRecordContext } from 'react-admin';
import { AssignmentOutlined } from '@material-ui/icons';

import ListCard from '../../../common/cards/ListCard';
import { useSeason } from '../../../common/inputs/SeasonSelector';

import { AssignRuleGrid } from '../../assignRules/AssignRuleGrid';
import { AddAssignRuleButton } from '../../assignRules/AssignRuleModal';
import { useFocusState } from '../../../common/hooks/hooks';
import { useTheme } from '@material-ui/core';

export default ({ title = "resources.assignRules.labels.default_rules", types, typesToExclude, ...props }) => {
  const office = useRecordContext(props);
  const seasonId = useSeason();
  const officeId = office?.id;
  const { isFocused } = useFocusState('assignRules');
  const theme = useTheme();

  const addButton = <AddAssignRuleButton types={types} initialValues={{ officeId, seasonId }} />;

  return <div id="assignRules">
    <ListCard
      title={title}
      icon={<AssignmentOutlined />}
      collapsable={!isFocused}
      addButton={addButton}
      reference="assignRules"
      target="officeId"
      filter={{
        seasonId,
        ...(types ? { type: { inq: types } } : {}),
        effectiveOffices: true,
        includeParents: true,
        _scope: 'Parents'
      }}
      sort={{
        field: ['type', 'value', 'order'],
        order: ['ASC', 'ASC', 'ASC'],
      }}
      componentProps={{ style: { boxShadow: isFocused ? theme.focusShadow : undefined } }}
      {...props}
    >
      <AssignRuleGrid showRule showAttributes showCriteria={false} showConditions={false} />
    </ListCard>
  </div>
}
