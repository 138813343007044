import React, { Fragment, useRef } from 'react';
import { SelectInput, TabbedForm, TabbedFormTabs, FormTab, useTranslate, NumberInput, useRecordContext } from 'react-admin';
import { Alert } from '@material-ui/lab'
import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import createCalculator from 'final-form-calculate';

import { FF_DOUBLE_FORFEIT, FF_DRAFT_GAMES, FF_PERIODS, FF_SPORTSMANSHIP } from '@hisports/common/featureFlags';
import { translateApiProperty } from '@hisports/common';

import { useFlag, useSport } from '../../http';
import { useLocale } from '../../locale';
import Toolbar from '../../common/ra/Toolbar';
import SwitchInput from '../../common/inputs/SwitchInput';
import { EnumInput, LanguageTypeEnum } from '../../common/inputs/EnumInputs';
import { FieldDependency } from '../../common/FieldDependency';
import { isAuthorized } from '../../common/Authorize';

import { StandingsOrderInput } from '../scheduleSettings/ScheduleSettingsForm';
import { SchemaInput } from '../schemas/SchemaInput';
import { DivisionInput } from '../categories/DivisionInput';
import { OfficeInput } from '../offices/OfficeInput';
import { HockeyPeriodSettings } from '../scoresheet/summary/PeriodsModal';
import { useBranchSettings } from '../branchOfficeSettings';

const useStyles = makeStyles(theme => ({
  periods: {
    marginTop: 0,
  },
  advancedTab: {
    color: theme.palette.tertiary.main,
  }
}))

const validate = values => {
  const errors = { points: {} }

  errors.points.Team = ['win', 'otWin', 'soWin', 'forfeitWin', 'loss', 'otLoss', 'soLoss', 'forfeitLoss', 'tie', 'forfeitTie']
    .reduce((errors, key) => {
      const value = values.points.Team[key];
      if (value == null) {
        errors[key] = 'ra.validation.required'
      }
      return errors;
    }, {})

  if (values.sportsmanshipPoints !== 'none' && values.points.Team.sportsmanship == null) {
    errors.points.Team.sportsmanship = 'ra.validation.required';
  }

  errors.points.Player = ['goal', 'assist']
    .reduce((errors, key) => {
      const value = values.points.Player[key];
      if (value == null) {
        errors[key] = 'ra.validation.required'
      } else if (value < 0) {
        errors[key] = 'ra.validation.greater_than_or_equal_zero'
      }
      return errors;
    }, {})

  if (values.maxScoreDiff != null && values.maxScoreDiff <= 0) errors.maxScoreDiff = 'ra.validation.greater_than_zero'
  if (values.gameLength != null && values.gameLength <= 0) errors.gameLength = 'resources.scheduleSettings.validations.game_length';
  if (!values.language) errors.language = 'ra.validation.required'
  if (values.rescheduleRequestsEnabled && !values.requiredRescheduleApprovals?.length) errors.requiredRescheduleApprovals = 'ra.validation.required'

  return errors;
}

export const SportsmanshipPointsInput = props =>
  <SelectInput {...props} choices={[
    { id: 'none', name: 'resources.officeSettings.values.sportsmanship_points.none' },
    { id: 'PCAHA', name: 'resources.officeSettings.values.sportsmanship_points.PCAHA' },
    { id: 'VIAHA', name: 'resources.officeSettings.values.sportsmanship_points.VIAHA' },
  ]} />

const SyncAttributesInput = props =>
  <SelectInput {...props} choices={[
    { id: 'position', name: 'resources.officeSettings.values.sync_attributes.position' },
    { id: 'all', name: 'resources.officeSettings.values.sync_attributes.all' },
  ]} />

const ContactsInput = props =>
  <SelectInput {...props} choices={[
    { id: 'profiles', name: 'resources.officeSettings.values.contacts.profiles' },
    { id: 'schedules', name: 'resources.officeSettings.values.contacts.schedules' },
    { id: 'teams', name: 'resources.officeSettings.values.contacts.teams' },
  ]} />

const ReportsInput = props =>
  <EnumInput {...props} multiple emptyLabel="Disabled" choices={[
    { id: 'Penalty', name: 'resources.officeSettings.values.reports.penalty' },
    { id: 'Scoring', name: 'resources.officeSettings.values.reports.scoring' },
    { id: 'AP', name: 'resources.officeSettings.values.reports.ap' },
  ]} />

const SuspensionActivationInput = ({ disableOptions = [], ...props }) => {
  const { values } = useFormState();

  return <EnumInput {...props} radio choices={[
    { id: 'Manual', name: 'resources.officeSettings.values.suspension_activation.manual' },
    { id: 'Approval', name: 'resources.officeSettings.values.suspension_activation.approval' },
    values?.requireCertification && { id: 'Certification', name: 'resources.officeSettings.values.suspension_activation.certification' },
  ].filter(Boolean)} />
}

export const ScoringModeInput = props => {
  return <EnumInput {...props} radio choices={[
    { id: 'Detailed', name: 'resources.officeSettings.values.scoring_mode.detailed', sports: ['Hockey', 'Soccer'] },
    { id: 'Score', name: 'resources.officeSettings.values.scoring_mode.score' },
  ]} />
}

export const PenaltyModeInput = props => {
  return <EnumInput {...props} radio choices={[
    { id: 'Detailed', name: 'resources.officeSettings.values.penalty_mode.detailed' },
  ]} />
}

export const PeriodsInput = ({ source }) => {
  const classes = useStyles();
  const settings = useRecordContext()

  if (!settings?.periods?.length) return null;

  return <HockeyPeriodSettings className={classes.periods} periods={settings.periods} />
}

export const EnforcementInput = ({ hideNone = false, ...props }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const choices = [
    { id: 'Conflict', name: 'resources.officeSettings.values.enforcement.conflict' },
    { id: 'Validation', name: 'resources.officeSettings.values.enforcement.validation' },
  ]

  if (!hideNone) choices.unshift({ id: 'None', name: 'resources.officeSettings.values.enforcement.none' })

  return <EnumInput {...props} radio row={isMobile} choices={choices} />
}

const RescheduleApprovalsInput = props => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  return <EnumInput {...props} optionDescription="description" multiple row={isMobile} choices={[
    { id: 'Team Staff', name: 'resources.officeSettings.values.required_approvals.Team Staff', description: 'resources.officeSettings.helpers.required_approvals.Team Staff' },
    { id: 'Team Office', name: 'resources.officeSettings.values.required_approvals.Team Office', description: 'resources.officeSettings.helpers.required_approvals.Team Office' },
    { id: 'Schedule Office', name: 'resources.officeSettings.values.required_approvals.Schedule Office', description: 'resources.officeSettings.helpers.required_approvals.Schedule Office' },
  ]} />
}

export const DraftRequestInput = props => {
  const { values: { rescheduleRequestsEnabled } } = useFormState();
  return <>
    <SwitchInput
      source="rescheduleRequestsEnabled"
      label="resources.officeSettings.fields.rescheduleRequestsEnabled"
      helperText="resources.officeSettings.helpers.rescheduleRequestsEnabled"
      {...inputProps}
    />
    {rescheduleRequestsEnabled && <RescheduleApprovalsInput
      source="requiredRescheduleApprovals"
      label="resources.officeSettings.fields.requiredRescheduleApprovals"
      helperText="resources.officeSettings.helpers.requiredRescheduleApprovals"
      {...inputProps}
    />}
  </>
}

export const HiddenStatsInput = ({ source, type, scope, ...props }) => {
  const [ locale ] = useLocale();
  const { values: { schemaIds } } = useFormState();

  const schemaId = schemaIds?.[type]?.[scope];
  const schema = useSelector(state => state.admin.resources.schemas.data?.[schemaId]);

  const properties = Object.keys(schema?.schema?.properties || [])
    .filter(property =>
      !(schema.schema.properties[property].displayOptions && schema.schema.properties[property].displayOptions.hidden)
    )
    .map(property => {
      const { displayOptions } = schema.schema.properties[property];
      const abbreviation = translateApiProperty(displayOptions, 'abbreviation', locale);
      const description = translateApiProperty(displayOptions, 'description', locale);

      return {
        id: property,
        name: description || abbreviation,
        description: abbreviation || description,
      };
    });

  return <EnumInput {...props} source={`${source}.${type}.${scope}`} multiple emptyLabel="Disabled" choices={properties} optionDescription="description" />;
}

const DefaultAlert = () => {
  const translate = useTranslate();
  return <Alert
    severity="info"
    fullWidth
    icon={<FileCopyOutlined />}
  >
    {translate('resources.officeSettings.alerts.new_schedules_only')}
  </Alert>
}

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const GeneralTab = props => {
  const translate = useTranslate();
  return <FormTab label="resources.officeSettings.labels.tabs.general" {...props}>
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.reports')}</Typography>
    <ReportsInput
      source="adminReports"
      label="resources.officeSettings.fields.adminReports"
      helperText="resources.officeSettings.helpers.adminReports"
      {...inputProps}
    />
    <ReportsInput
      source="teamReports"
      label="resources.officeSettings.fields.teamReports"
      helperText="resources.officeSettings.helpers.teamReports"
      {...inputProps}
    />

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.contacts')}</Typography>
    <LanguageTypeEnum
      source="language"
      label="resources.officeSettings.fields.language"
      helperText="resources.officeSettings.helpers.language"
      {...inputProps}
    />
    <ContactsInput source="contactsVisibility" label="resources.officeSettings.fields.contactsVisibility" helperText="" {...inputProps} />
  </FormTab>
}

const SchedulingTab = ({ office, ...props }) => {
  const translate = useTranslate();
  const isEnabled = useFlag();

  return <FormTab label="resources.officeSettings.labels.tabs.scheduling" {...props}>
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.conflict_enforcement')}</Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <EnforcementInput source="conflictEnforcement" label="resources.officeSettings.fields.conflictEnforcement" hideNone {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <EnforcementInput source="availabilityEnforcement" label="resources.officeSettings.fields.availabilityEnforcement" {...inputProps} />
      </Grid>
      <Grid item xs={12} md={4}>
        <EnforcementInput source="constraintEnforcement" label="resources.officeSettings.fields.constraintEnforcement" {...inputProps} />
      </Grid>
    </Grid>

    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.drafts')}</Typography>
    <SwitchInput
      source="deferDraftConflict"
      label={`resources.officeSettings.fields.${isEnabled(FF_DRAFT_GAMES) ? 'deferLegacyDraftConflict' : 'deferDraftConflict'}`}
      helperText={`resources.officeSettings.helpers.${isEnabled(FF_DRAFT_GAMES) ? 'deferLegacyDraftConflict' : 'deferDraftConflict'}`}
      {...inputProps}
    />
    <SwitchInput
      source="conflictDrafts"
      label={`resources.officeSettings.fields.${isEnabled(FF_DRAFT_GAMES) ? 'conflictLegacyDrafts' : 'conflictDrafts'}`}
      helperText={`resources.officeSettings.helpers.${isEnabled(FF_DRAFT_GAMES) ? 'conflictLegacyDrafts' : 'conflictDrafts'}`}
      {...inputProps}
    />
    <SwitchInput
      source="limitDateChange"
      label="resources.officeSettings.fields.limitDateChange"
      helperText="resources.officeSettings.helpers.limitDateChange"
      {...inputProps}
    />

    <br />
    <Typography variant="subtitle2" gutterBottom>{translate('resources.officeSettings.labels.scheduling')}</Typography>
    <DefaultAlert />
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <NumberInput
          source="gameLength"
          label="resources.officeSettings.fields.gameLength"
          helperText="resources.officeSettings.helpers.gameLength"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <OfficeInput
          source="surfaceOfficeId"
          label="resources.officeSettings.fields.surfaceOfficeId"
          helperText="resources.officeSettings.helpers.surfaceOfficeId"
          filter={{ type: { nin: ['League', 'Tournament', 'Cup'] } }}
          {...inputProps}
        />
      </Grid>
      <Grid item xs={12}>
        <SwitchInput
          source="homeChangeStatus"
          label="resources.officeSettings.fields.homeChangeStatus"
          helperText="resources.officeSettings.helpers.homeChangeStatus"
          {...inputProps}
        />
      </Grid>
    </Grid>

    <br />
    <DraftRequestInput />
  </FormTab>
}

const ScorekeepingTab = ({ office, ...props }) => {
  const translate = useTranslate();
  const isEnabled = useFlag();
  const { data: branchSettings } = useBranchSettings(office?.id);

  return <FormTab label="resources.officeSettings.labels.tabs.scoresheet" {...props}>
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.teamScorekeeping')}</Typography>
    <SwitchInput source="homeTeamScorekeeping" helperText="resources.officeSettings.helpers.homeTeamScorekeeping" {...inputProps} />
    <SwitchInput source="awayTeamScorekeeping" helperText="resources.officeSettings.helpers.awayTeamScorekeeping" {...inputProps} />

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.certification')}</Typography>
    <SwitchInput source="requireCertification" helperText="resources.officeSettings.helpers.certification" {...inputProps} />

    {branchSettings?.ff_incidents && <SwitchInput source="requireIncidentReports" helperText="resources.officeSettings.helpers.incidentReports" {...inputProps} />}

    {branchSettings?.ff_suspensions && <>
      <br />
      <Typography variant="subtitle2">{translate('resources.officeSettings.labels.suspensions')}</Typography>
      <SuspensionActivationInput source="suspensionActivation" helperText="resources.officeSettings.helpers.suspensionActivation" {...inputProps} />
    </>}

    <br />
    <Typography variant="subtitle2" gutterBottom>{translate('resources.scheduleSettings.labels.lineups')}</Typography>
    <DefaultAlert />
    <SwitchInput
      source="includeRegistrations"
      helperText="resources.officeSettings.helpers.includeRegistrations"
      {...inputProps}
    />
    <SwitchInput
      source="allowExtras"
      helperText="resources.officeSettings.helpers.allowExtras"
      {...inputProps}
    />
    <ScoringModeInput source="scoringMode" helperText="resources.officeSettings.helpers.scoringMode" {...inputProps} />
    <PenaltyModeInput source="penaltyMode" helperText="resources.officeSettings.helpers.penaltyMode" {...inputProps} />

    {isEnabled(FF_PERIODS) && <>
      <br />
      <Typography variant="subtitle2">{translate('resources.officeSettings.labels.periods')}</Typography>
      <DefaultAlert />
      <PeriodsInput />
    </>}
  </FormTab>
}

const StandingsTab = props => {
  const translate = useTranslate();
  const isEnabled = useFlag();

  return <FormTab label="resources.officeSettings.labels.tabs.team_stats" {...props}>
    <DefaultAlert />

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.points')}</Typography>
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.win"
          label="resources.officeSettings.fields.points.Team.win"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.otWin"
          label="resources.officeSettings.fields.points.Team.otWin"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.soWin"
          label="resources.officeSettings.fields.points.Team.soWin"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.forfeitWin"
          label="resources.officeSettings.fields.points.Team.forfeitWin"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.loss"
          label="resources.officeSettings.fields.points.Team.loss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.otLoss"
          label="resources.officeSettings.fields.points.Team.otLoss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.soLoss"
          label="resources.officeSettings.fields.points.Team.soLoss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.forfeitLoss"
          label="resources.officeSettings.fields.points.Team.forfeitLoss"
          helperText=""
          {...inputProps}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.tie"
          label="resources.officeSettings.fields.points.Team.tie"
          helperText=""
          {...inputProps}
        />
      </Grid>
      {isEnabled(FF_DOUBLE_FORFEIT) && <Grid item xs={6} md={3}>
        <NumberInput
          source="points.Team.forfeitTie"
          label="resources.officeSettings.fields.points.Team.forfeitTie"
          helperText=""
          {...inputProps}
        />
      </Grid>}
      <Grid item xs={6} md={3}>
        <NumberInput
          source="maxScoreDiff"
          label="resources.officeSettings.fields.maxScoreDiff"
          helperText="ra.message.optional"
          {...inputProps}
        />
      </Grid>
      <Grid item xs={4} md={3}>
        <FieldDependency fieldSource="sportsmanshipPoints" test={value => value != 'none'}>
          <NumberInput
            source="points.Team.sportsmanship"
            label="resources.officeSettings.fields.points.Team.sportsmanship"
            helperText=""
            {...inputProps}
          />
        </FieldDependency>
      </Grid>
    </Grid>

    {isEnabled(FF_SPORTSMANSHIP) && <br />}
    {isEnabled(FF_SPORTSMANSHIP) && <SportsmanshipPointsInput
      source="sportsmanshipPoints"
      label="resources.officeSettings.fields.sportsmanshipPoints"
      helperText="resources.officeSettings.helpers.sportsmanshipPoints"
      {...inputProps}
    />}

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.standings')}</Typography>
    <StandingsOrderInput source="standingsOrder" type="Team" label="resources.scheduleSettings.labels.standings_order.team" {...inputProps} />

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.public')}</Typography>
    <DivisionInput
      source="standingsHiddenDivisionIds"
      multiple
      helperText="resources.officeSettings.helpers.standingsVisibility"
      {...inputProps}
    />
    <HiddenStatsInput
      source="hiddenStats"
      type="Team"
      scope="Schedule"
      label="resources.officeSettings.labels.hidden_stats.team"
      helperText="resources.officeSettings.helpers.hiddenStats"
      {...inputProps}
    />
    <DivisionInput
      source="boxScoreHiddenDivisionIds"
      multiple
      helperText="resources.officeSettings.helpers.boxScoreVisibility"
      {...inputProps}
    />
  </FormTab>
}

const StatsTab = props => {
  const translate = useTranslate();
  const officeSettings = useRecordContext(props);
  const sport = useSport();
  const scoreOnly = officeSettings?.scoringMode === 'Score';

  return <FormTab label="resources.officeSettings.labels.tabs.player_stats" {...props}>
    <DefaultAlert />

    <br />
    {!scoreOnly && <>
      <Typography variant="subtitle2">{translate('resources.officeSettings.labels.points')}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <NumberInput
            source="points.Player.goal"
            label="resources.officeSettings.fields.points.Player.goal"
            helperText=""
            min={0}
            {...inputProps}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <NumberInput
            source="points.Player.assist"
            label="resources.officeSettings.fields.points.Player.assist"
            helperText=""
            min={0}
            {...inputProps}
          />
        </Grid>
      </Grid>
    </>}

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.standings')}</Typography>
    <StandingsOrderInput source="standingsOrder" type="Skater" label="resources.scheduleSettings.labels.standings_order.skater" {...inputProps} />
    {sport != 'Baseball' && <StandingsOrderInput source="standingsOrder" type="Goalie" label="resources.scheduleSettings.labels.standings_order.goalie" {...inputProps} />}

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.public')}</Typography>
    <DivisionInput
      source="skaterStatsHiddenDivisionIds"
      multiple
      helperText="resources.officeSettings.helpers.statsVisibility"
      {...inputProps}
    />
    <HiddenStatsInput
      source="hiddenStats"
      type="Skater"
      scope="Schedule"
      label="resources.officeSettings.labels.hidden_stats.skater"
      helperText="resources.officeSettings.helpers.hiddenStats"
      {...inputProps}
    />

    <br />
    {sport != 'Baseball' && <>
      <DivisionInput
        source="goalieStatsHiddenDivisionIds"
        multiple
        helperText="resources.officeSettings.helpers.statsVisibility"
        {...inputProps}
      />
      <HiddenStatsInput
        source="hiddenStats"
        type="Goalie"
        scope="Schedule"
        label="resources.officeSettings.labels.hidden_stats.goalie"
        helperText="resources.officeSettings.helpers.hiddenStats"
        {...inputProps}
      />
    </>}
  </FormTab>
}

const AdvancedTab = ({ office, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!isAuthorized(office, 'offices', 'internal')) return null;
  return <FormTab className={classes.advancedTab} label="resources.officeSettings.labels.tabs.advanced" {...props}>
    <Alert severity="warning" fullWidth>{translate('resources.officeSettings.alerts.advanced')}</Alert>
    <br />

    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.schedule_stats')}</Typography>
    <SchemaInput
      label="resources.officeSettings.labels.stats_schema.team"
      source="schemaIds.Team.Schedule"
      filter={{ type: 'Team', scope: 'Schedule' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.officeSettings.labels.stats_schema.skater"
      source="schemaIds.Skater.Schedule"
      filter={{ type: 'Skater', scope: 'Schedule' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.officeSettings.labels.stats_schema.goalie"
      source="schemaIds.Goalie.Schedule"
      filter={{ type: 'Goalie', scope: 'Schedule' }}
      {...inputProps}
    />

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.game_stats')}</Typography>
    <SchemaInput
      label="resources.officeSettings.labels.stats_schema.team"
      source="schemaIds.Team.Game"
      filter={{ type: 'Team', scope: 'Game' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.officeSettings.labels.stats_schema.skater"
      source="schemaIds.Skater.Game"
      filter={{ type: 'Skater', scope: 'Game' }}
      {...inputProps}
    />
    <SchemaInput
      label="resources.officeSettings.labels.stats_schema.goalie"
      source="schemaIds.Goalie.Game"
      filter={{ type: 'Goalie', scope: 'Game' }}
      {...inputProps}
    />

    <br />
    <Typography variant="subtitle2">{translate('resources.officeSettings.labels.rosters')}</Typography>
    <SyncAttributesInput source="syncAttributes" label="resources.officeSettings.fields.syncAttributes" helperText="" {...inputProps} />
    <SwitchInput
      source="updateRosterFromLineups"
      helperText="resources.officeSettings.helpers.updateRosterFromLineups"
      {...inputProps}
    />
  </FormTab>
}

export default ({ office, ...props }) => {
  const decorators = useRef([createCalculator({
    field: 'requireCertification',
    updates: {
      suspensionActivation: (requireCertification, values, prevValues) => {
        if (!requireCertification && values.suspensionActivation === 'Certification') return 'Manual'
        return values.suspensionActivation
      }
    }
  })])

  return <TabbedForm toolbar={<Toolbar />} validate={validate} decorators={decorators.current} tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />} component={Fragment} {...props} syncWithLocation={false}>
    <GeneralTab />
    <SchedulingTab office={office} />
    <ScorekeepingTab office={office} />
    <StandingsTab />
    <StatsTab />
    <AdvancedTab office={office} />
  </TabbedForm>
}
