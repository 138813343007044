import React, { useEffect, useState } from 'react';
import { useListContext, useRecordContext } from 'react-admin';
import { useLocation } from 'react-router-dom';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { OfficeTypeEnumInput } from '../../../common/inputs/EnumInputs';

import { OfficeGrid } from '../../offices/OfficeGrid';
import { AddOfficeButton } from '../../offices/OfficeModal';
import { OfficeIcon } from '../../icons';

const InitializeFilter = ({ children }) => {
  const { setFilters } = useListContext();
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);

  useEffect(() => {
    // Reset filters when navigating from office to office
    if (location.pathname === path) return;
    setFilters({})
    setPath(location.pathname);
  }, [location, path, setFilters]);

  return children;
}

export default props => {
  const office = useRecordContext(props);
  const canAddOffice = isAuthorized(office, 'offices', 'create');

  const filters = [
    <OfficeTypeEnumInput source="type" variant="outlined" alwaysOn multiple />,
  ]

  return <ListCard
    title="resources.offices.labels.card.sub_offices_title"
    reference="offices"
    target="parentId"
    icon={<OfficeIcon />}
    sort={{ field: 'name', order: 'ASC' }}
    addButton={canAddOffice && <AddOfficeButton initialValues={{ parentId: office?.id }} />}
    perPage={10}
    rowsPerPageOptions={[10, 25, 50, 100]}
    filters={filters}
  >
    <InitializeFilter>
      <OfficeGrid rowClick="show" />
    </InitializeFilter>
  </ListCard>
}
