import React from 'react';
import { TextInput, SimpleForm } from 'react-admin';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useFormState } from 'react-final-form';


import { GameFormatEnumInput, SeasonInput, ScheduleTypeEnumInput, TournamentScheduleTypeEnumInput, CupScheduleTypeEnumInput } from '../../common/inputs/EnumInputs';
import { InlineDateInput } from '../../common/inputs/DateInput';
import Toolbar from '../../common/ra/Toolbar';

import { OfficeInput } from '../offices/OfficeInput';
import { CategoryInput } from '../categories/CategoryInput';
import { SyncAlert, useRecordSynced } from '../../common/SyncAlert';

const validate = values => {
  const errors = {};

  if (!values.name) errors.name = 'ra.validation.required'

  if (!values.type) errors.type = 'ra.validation.required'
  if (!values.officeId) errors.officeId = 'ra.validation.required'

  if (!values.categoryId) errors.categoryId = 'ra.validation.required'

  if (!values.seasonId) errors.seasonId = 'ra.validation.required'
  if (!values.startDate) errors.startDate = 'ra.validation.required'
  if (!values.endDate) errors.endDate = 'ra.validation.required'

  if (values.startDate && values.endDate) {
    if (moment(values.endDate).isBefore(values.startDate, 'day')) {
      errors.endDate = 'resources.schedules.validations.end_after_start'
    }
  }

  return errors;
}

const inputProps = {
  resource: 'schedules',
  basePath: '/schedules',
  variant: 'outlined',
  margin: 'none',
  fullWidth: true,
}

const NameInput = props => {
  const { values } = useFormState()
  const { name } = values;

  const hasDivision = name && name.match(/Cohort|Group|Flight|Round/i);
  const helperText = hasDivision ? "resources.schedules.helpers.cohorts" : null;
  return <TextInput helperText={helperText} {...props} />
}

const TypeInput = props => {
  const { values } = useFormState()
  const { officeId } = values;
  const office = useSelector(store => officeId && store.admin.resources.offices.data[officeId])

  switch (office?.type) {
    case 'Tournament':
      return <TournamentScheduleTypeEnumInput {...props} {...inputProps} />
    case 'Cup':
      return <CupScheduleTypeEnumInput {...props} {...inputProps} />
    default:
      return <ScheduleTypeEnumInput {...props} {...inputProps} />
  }
}

const ScheduleOfficeInput = props => {
  const { values } = useFormState()
  const { officeId } = values;
  const office = useSelector(store => officeId && store.admin.resources.offices.data[officeId])

  const label = office?.type ? `resources.offices.values.type.${office.type}` : undefined;
  return <OfficeInput source="officeId" label={label} filter={{ type: { neq: 'Historical' } }} {...props} {...inputProps} />
}

export default props => {
  const disableOffice = props?.initialValues?.officeId != null;
  const [ isSynced, toggle ] = useRecordSynced(props);

  return <>
    <SyncAlert isSynced={isSynced} toggle={toggle} />
    <SimpleForm toolbar={<Toolbar isSynced={isSynced} />} validate={validate} {...props}>
      <Grid container spacing={2} fullWidth>
        <Grid item xs={12} md={8}>
          <NameInput source="name" autoFocus {...inputProps} disabled={isSynced} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SeasonInput source="seasonId" disabled={isSynced} {...inputProps} />
        </Grid>

        <Grid item xs={12} md={4}>
          <TypeInput source="type" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <CategoryInput source="categoryId" disabled={isSynced} {...inputProps} />
        </Grid>
        <Grid item xs={12} md={4}>
          <GameFormatEnumInput
            source="gameFormat"
            helperText="resources.schedules.helpers.gameFormat"
            {...inputProps} />
        </Grid>

        <Grid item xs={12}>
          <ScheduleOfficeInput source="officeId" disabled={disableOffice || isSynced} {...inputProps} />
        </Grid>

        <Grid item xs={12} md={6}>
          <InlineDateInput source="startDate" label="resources.schedules.fields.startDate" {...inputProps} />
        </Grid>
        <Grid item xs={12} md={6}>
          <InlineDateInput source="endDate" label="resources.schedules.fields.endDate" {...inputProps} />
        </Grid>

        <Grid item xs={12}>
          <TextInput source="comments" multiline rows="3" helperText="ra.message.optional" {...inputProps} />
        </Grid>
      </Grid>
    </SimpleForm>
  </>
}
