import React, { Fragment, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Button } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import { EditResourceDialog } from '../../common/dialogs/ResourceDialog';

import ScheduleSettingsForm from './ScheduleSettingsForm';

const EditScheduleSettingsDialog = props => {
  const schedule = useRecordContext();

  return <EditResourceDialog label="resources.scheduleSettings.labels.edit_schedule" disableDelete maxWidth="md" resource="scheduleSettings" basePath="/scheduleSettings" canDelete={false} {...props}>
    <ScheduleSettingsForm schedule={schedule} />
  </EditResourceDialog>
}

export const UpdateScheduleSettingsButton = ({ scheduleId, ...props }) => {
  const [ isOpen, setOpen ] = useState(false);
  const translate = useTranslate();

  return <>
    <Button color="primary" startIcon={<Edit />} onClick={() => setOpen(true)}>
      {translate('resources.scheduleSettings.actions.edit')}
    </Button>
    <EditScheduleSettingsDialog id={scheduleId} isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}
