import React from "react"
import { Tooltip, Typography } from "@material-ui/core"
import { InfoOutlined } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxWidth: props => props.width,
  },
}));

export const InformationTooltip = ({ text, fontSize = 'small', placement, width }) => {
  const classes = useStyles({ width });

  return <Tooltip title={<Typography variant="body2">{text}</Typography>} placement={placement} classes={{ tooltip: classes.tooltip }}>
    <InfoOutlined color="primary" fontSize={fontSize} />
  </Tooltip>
}
