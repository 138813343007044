import React from 'react';
import { Datagrid, FunctionField } from 'react-admin';

import { EnumField } from '../../common/inputs/EnumInputs';
import { SyncedIndicatorField } from '../../common/SyncAlert';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  logo: {
    width: '28px',
    height: '28px',
    objectFit: 'contain',
  },
}))

export const OfficeNameField = ({ includeLogo = true, ...props }) => {
  const classes = useStyles();

  return <FunctionField {...props} render={record => {
    return <div className={classes.root}>
      {includeLogo && !!record?.logo?.url && (
        <img
          src={record.logo.url}
          alt={`${record.id} logo`}
          className={classes.logo}
        />
      )}
      <SyncedIndicatorField {...props} />
    </div>
  }} />
}

export const OfficeGrid = props =>
  <Datagrid size="medium" {...props}>
    <OfficeNameField source="name" />
    <EnumField source="type" />
  </Datagrid>

