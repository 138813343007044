const INTERNAL = 'internal'

const LEAGUES = {
  MANAGE: 'leagues:manage',
  VIEW: 'leagues:view',
  PUBLIC: 'leagues:public',
}

const PARTICIPANTS = {
  MANAGE: 'participants:manage',
  ACCOUNTS: 'participants:accounts',
  PERMISSIONS: 'participants:permissions',
  INVITE: 'participants:invite',
  VIEW: 'participants:view',
}

const PROFILES = {
  MANAGE: 'profiles:manage',
  ADDRESSES: 'profiles:addresses',
  NOTIFICATIONS: 'profiles:notifications',
  VIEW: 'profiles:view',
  PUBLIC: 'profiles:public',
}

const TEAMS = {
  MANAGE: 'teams:manage',
  ROSTER: 'teams:roster',
  ACTIVITIES: 'teams:activities',
  SETTINGS: 'teams:settings',
  VIEW: 'teams:view',
  PUBLIC: 'teams:public',
}

const SCHEDULING = {
  MANAGE: 'scheduling:manage',
  GAMES: 'scheduling:games',
  STATUS: 'scheduling:status',
  DRAFTS: 'scheduling:drafts',
  PRACTICES: 'scheduling:practices',
  AVAILABILITY: 'scheduling:availability',
  VIEW: 'scheduling:view',
  PUBLIC: 'scheduling:public',
  APPROVE: 'scheduling:approve',
}

const ASSIGNING = {
  MANAGE: 'assigning:manage',
  QUALIFICATIONS: 'assigning:qualifications',
  AVAILABILITY: 'assigning:availability',
  LISTS: 'assigning:lists',
  ASSIGN: 'assigning:assign',
  OFFICIAL: 'assigning:official',
  VIEW: 'assigning:view',
}

const SCORESHEETS = {
  MANAGE: 'scoresheets:manage',
  LINEUPS: 'scoresheets:lineups',
  EVENTS: 'scoresheets:events',
  VIEW: 'scoresheets:view',
}

const SUSPENSIONS = {
  MANAGE: 'suspensions:manage',
  VIEW: 'suspensions:view',
}

const DISCIPLINE = {
  MANAGE: 'discipline:manage',
  VIEW: 'discipline:view',
  TRIGGER: 'discipline:trigger',
}

const STATS = {
  VIEW: 'stats:view',
  WRITE: 'stats:write',
  REPORTS: 'stats:reports',
  PUBLIC: 'stats:public',
  SCHEMAS: 'stats:schemas',
}

const CMS = {
  MANAGE: 'cms:manage',
  PUBLIC: 'cms:public',
}

const every = group => Object.keys(group).map(key => group[key])
const ALL = [
  INTERNAL,
  ...every(LEAGUES),
  ...every(PARTICIPANTS),
  ...every(PROFILES),
  ...every(TEAMS),
  ...every(SCHEDULING),
  ...every(ASSIGNING),
  ...every(SCORESHEETS),
  ...every(STATS),
  ...every(CMS),
]

/* eslint-disable key-spacing */
export default {
  profile: {
    official:     [ASSIGNING.OFFICIAL],
    league:       [SCORESHEETS.VIEW],
    games:        [ASSIGNING.OFFICIAL, SCHEDULING.VIEW],
    feed:         [ASSIGNING.OFFICIAL],
  },
  reports: {
    list:         [STATS.REPORTS],
    show:         [STATS.REPORTS],
    create:       [STATS.REPORTS],
    edit:         [STATS.REPORTS],
    remove:       [STATS.REPORTS],
  },

  venues: {
    list:         [LEAGUES.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    show:         [LEAGUES.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [/* INTERNAL */],
  },
  surfaces: {
    list:         [LEAGUES.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    show:         [LEAGUES.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [/* INTERNAL */],
  },
  leagues: {
    list:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, CMS.MANAGE],
    show:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, CMS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [/* INTERNAL */],

    createProviders:  [LEAGUES.MANAGE],
    editProviders:    [LEAGUES.MANAGE],
  },
  tournaments: {
    list:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, CMS.MANAGE],
    show:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, CMS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [/* INTERNAL */],

    createProviders:  [LEAGUES.MANAGE],
    editProviders:    [LEAGUES.MANAGE],
  },
  cups: {
    list:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, CMS.MANAGE],
    show:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, CMS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [/* INTERNAL */],

    createProviders:  [LEAGUES.MANAGE],
    editProviders:    [LEAGUES.MANAGE],
  },
  offices: {
    list:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, ASSIGNING.LISTS, CMS.MANAGE],
    show:         [LEAGUES.MANAGE, PARTICIPANTS.MANAGE, TEAMS.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.DRAFTS, ASSIGNING.MANAGE, ASSIGNING.LISTS, ASSIGNING.AVAILABILITY, CMS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [/* INTERNAL */],

    editHCR:      [INTERNAL],
    peopleTab:    [PARTICIPANTS.MANAGE, ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    assigningTab: [ASSIGNING.MANAGE, ASSIGNING.LISTS, ASSIGNING.ASSIGN],
    rulebookTab:  [INTERNAL /* LEAGUES.MANAGE */],
    settingsTab:  [LEAGUES.MANAGE],
    schedulerTab: [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS],
    publish:      [SCHEDULING.MANAGE],
    share:        [SCHEDULING.MANAGE],
    internal:     [INTERNAL],
  },
  officemembers: {
    list:         [LEAGUES.MANAGE],
    show:         [LEAGUES.MANAGE],
    create:       [LEAGUES.MANAGE],
    edit:         [LEAGUES.MANAGE],
    remove:       [LEAGUES.MANAGE],
  },
  officeSettings: {
    list:         [LEAGUES.MANAGE],
    show:         [LEAGUES.MANAGE],
    create:       [LEAGUES.MANAGE],
    edit:         [LEAGUES.MANAGE],
    remove:       [],
  },
  branchOfficeSettings: {
    list:         [LEAGUES.MANAGE],
    show:         [LEAGUES.MANAGE],
    create:       [LEAGUES.MANAGE],
    edit:         [LEAGUES.MANAGE],
    internalTab:  [INTERNAL],
    remove:       [],
  },
  officeAssignSettings: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    create:       [ASSIGNING.MANAGE],
    edit:         [ASSIGNING.MANAGE],
    remove:       [],
  },
  rulebooks: {
    list:         [INTERNAL],
    show:         [INTERNAL],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [],
  },
  infractions: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  infractionTypes: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  ruleSections: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  rules: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  ruleOptions: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  ruleOptionMembers: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  infractionAccumulations: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  infractionAccumulationMembers: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  groups: {
    list:         [LEAGUES.MANAGE, LEAGUES.VIEW],
    show:         [],
    create:       [LEAGUES.MANAGE],
    edit:         [LEAGUES.MANAGE],
    remove:       [LEAGUES.MANAGE],
  },
  arenaoffices: {
    list:         [LEAGUES.MANAGE, SCHEDULING.AVAILABILITY],
    show:         [LEAGUES.MANAGE, SCHEDULING.AVAILABILITY],
    create:       [LEAGUES.MANAGE, SCHEDULING.AVAILABILITY],
    edit:         [LEAGUES.MANAGE, SCHEDULING.AVAILABILITY],
    remove:       [LEAGUES.MANAGE, SCHEDULING.AVAILABILITY],
  },
  arenaslots: {
    list:         [LEAGUES.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.AVAILABILITY, SCHEDULING.VIEW],
    show:         [LEAGUES.MANAGE, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.AVAILABILITY, SCHEDULING.VIEW],
    create:       [SCHEDULING.AVAILABILITY],
    edit:         [SCHEDULING.AVAILABILITY],
    remove:       [SCHEDULING.AVAILABILITY],
    import:       [SCHEDULING.AVAILABILITY],
    bulkActions:  [SCHEDULING.AVAILABILITY],
  },
  availabilities: {
    list:         [SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.AVAILABILITY],
    show:         [SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.AVAILABILITY],
    create:       [SCHEDULING.AVAILABILITY],
    edit:         [SCHEDULING.AVAILABILITY],
    remove:       [SCHEDULING.AVAILABILITY],
    import:       [SCHEDULING.AVAILABILITY],
  },
  officialAvailabilities: {
    list: [ASSIGNING.AVAILABILITY, ASSIGNING.VIEW],
    edit: [ASSIGNING.AVAILABILITY],
  },
  accounts: {
    list:         [PARTICIPANTS.ACCOUNTS],
    show:         [PARTICIPANTS.ACCOUNTS],
    create:       [],
    edit:         [PARTICIPANTS.ACCOUNTS],
    remove:       [],
  },
  identities: {
    list:         [PARTICIPANTS.ACCOUNTS],
    show:         [PARTICIPANTS.ACCOUNTS],
    create:       [PARTICIPANTS.ACCOUNTS],
    edit:         [PARTICIPANTS.ACCOUNTS],
    remove:       [PARTICIPANTS.ACCOUNTS],
  },
  permissions: {
    list:         [PARTICIPANTS.PERMISSIONS],
    show:         [],
    create:       [PARTICIPANTS.PERMISSIONS],
    edit:         [PARTICIPANTS.PERMISSIONS],
    remove:       [PARTICIPANTS.PERMISSIONS],
  },
  roles: {
    list:         [PARTICIPANTS.PERMISSIONS],
    show:         [],
    create:       [PARTICIPANTS.PERMISSIONS],
    edit:         [PARTICIPANTS.PERMISSIONS],
    remove:       [PARTICIPANTS.PERMISSIONS],
  },

  participants: {
    list:         [PARTICIPANTS.MANAGE, PARTICIPANTS.ACCOUNTS, PARTICIPANTS.PERMISSIONS, PARTICIPANTS.VIEW],
    show:         [PARTICIPANTS.MANAGE, PARTICIPANTS.ACCOUNTS, PARTICIPANTS.PERMISSIONS, PARTICIPANTS.INVITE, PARTICIPANTS.VIEW, PROFILES.MANAGE, PROFILES.ADDRESSES, PROFILES.ADDRESSES, PROFILES.VIEW, TEAMS.MANAGE, TEAMS.ROSTER, TEAMS.VIEW, SCORESHEETS.MANAGE, ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.AVAILABILITY, SUSPENSIONS.VIEW, SUSPENSIONS.MANAGE],
    create:       [PARTICIPANTS.MANAGE],
    edit:         [PARTICIPANTS.MANAGE],
    remove:       [/* INTERNAL */],

    invite:       [PARTICIPANTS.INVITE],
    contacts:     [PROFILES.MANAGE, PROFILES.NOTIFICATIONS],
    profile:      [PROFILES.MANAGE],

    profileTab:        [PROFILES.MANAGE, PROFILES.ADDRESSES, PROFILES.NOTIFICATIONS, PROFILES.VIEW],
    registrationsTab:  [PARTICIPANTS.MANAGE, TEAMS.MANAGE, TEAMS.ROSTER, TEAMS.VIEW],
    officiatingTab:    [ASSIGNING.QUALIFICATIONS, ASSIGNING.AVAILABILITY],
    settingsTab:       [PARTICIPANTS.NOTIFICATIONS, PARTICIPANTS.PERMISSIONS, PARTICIPANTS.ACCOUNTS],
  },
  registrations: {
    list:         [PARTICIPANTS.MANAGE],
    show:         [PARTICIPANTS.MANAGE],
    create:       [PARTICIPANTS.MANAGE],
    edit:         [PARTICIPANTS.MANAGE],
    remove:       [PARTICIPANTS.MANAGE],
  },
  qualifications: {
    list:         [PARTICIPANTS.MANAGE, ASSIGNING.QUALIFICATIONS, ASSIGNING.ASSIGN],
    show:         [PARTICIPANTS.MANAGE, ASSIGNING.QUALIFICATIONS, ASSIGNING.ASSIGN],
    create:       [PARTICIPANTS.MANAGE, ASSIGNING.QUALIFICATIONS],
    edit:         [PARTICIPANTS.MANAGE, ASSIGNING.QUALIFICATIONS],
    remove:       [PARTICIPANTS.MANAGE, ASSIGNING.QUALIFICATIONS],

    importGrades: [PARTICIPANTS.MANAGE, ASSIGNING.QUALIFICATIONS],
    importQualifications: [PARTICIPANTS.MANAGE, ASSIGNING.QUALIFICATIONS],
  },
  officialOffices: {
    list:         [ASSIGNING.AVAILABILITY],
    show:         [ASSIGNING.AVAILABILITY],
    create:       [ASSIGNING.AVAILABILITY],
    edit:         [ASSIGNING.AVAILABILITY],
    remove:       [ASSIGNING.AVAILABILITY],

    importOfficials: [ASSIGNING.AVAILABILITY],
  },
  officialClaims: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    edit:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],

    changeStatus: [ASSIGNING.MANAGE, ASSIGNING.ASSIGN]
  },
  officialClaimDocuments: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    edit:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],

    changeStatus: [ASSIGNING.MANAGE, ASSIGNING.ASSIGN]
  },
  officialTransactions: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    edit:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
  },
  payPeriods: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    edit:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
  },


  contacts: {
    list:         [PROFILES.MANAGE, PROFILES.NOTIFICATIONS],
    show:         [PROFILES.MANAGE, PROFILES.NOTIFICATIONS],
    create:       [PROFILES.MANAGE, PROFILES.NOTIFICATIONS],
    edit:         [PROFILES.MANAGE, PROFILES.NOTIFICATIONS],
    remove:       [PROFILES.MANAGE, PROFILES.NOTIFICATIONS],

    verify:       [PROFILES.MANAGE, PROFILES.NOTIFICATIONS],
  },
  addresses: {
    list:         [PROFILES.ADDRESSES],
    show:         [PROFILES.ADDRESSES],
    create:       [PROFILES.ADDRESSES],
    edit:         [PROFILES.ADDRESSES],
    remove:       [PROFILES.ADDRESSES],
  },
  notifications: {
    list:         [PROFILES.NOTIFICATIONS],
    show:         [],
    create:       [PROFILES.NOTIFICATIONS],
    edit:         [PROFILES.NOTIFICATIONS],
    remove:       [PROFILES.NOTIFICATIONS],
  },
  profiles: {
    list:         [PROFILES.MANAGE],
    show:         [PROFILES.MANAGE],
    create:       [],
    edit:         [PROFILES.MANAGE],
    remove:       [],

    import:       [PROFILES.MANAGE],
  },


  officialConflicts: {
    list:         [ASSIGNING.QUALIFICATIONS],
    show:         [ASSIGNING.QUALIFICATIONS],
    create:       [ASSIGNING.QUALIFICATIONS],
    edit:         [ASSIGNING.QUALIFICATIONS],
    remove:       [ASSIGNING.QUALIFICATIONS],
  },
  attributeTypes: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    edit:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
  },
  attributeValues: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    edit:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    import:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
  },


  teams: {
    list:         [TEAMS.MANAGE, TEAMS.ROSTER, TEAMS.ACTIVITIES, TEAMS.SETTINGS, TEAMS.VIEW],
    show:         [TEAMS.MANAGE, TEAMS.ROSTER, TEAMS.ACTIVITIES, TEAMS.SETTINGS, TEAMS.VIEW, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCORESHEETS.MANAGE, SUSPENSIONS.VIEW, SUSPENSIONS.MANAGE],
    create:       [TEAMS.MANAGE],
    edit:         [TEAMS.MANAGE],
    remove:       [/* INTERNAL */],

    contacts:     [TEAMS.MANAGE, TEAMS.ROSTER, PROFILES.NOTIFICATIONS],
    gameTeams:    [TEAMS.MANAGE, TEAMS.ROSTER, TEAMS.SETTINGS, TEAMS.VIEW, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW],
    schedules:    [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, TEAMS.ACTIVITIES],
    settingsTab:  [TEAMS.MANAGE, TEAMS.SETTINGS],
    internal:     [INTERNAL],
  },
  activities: {
    list:         [TEAMS.ACTIVITIES, TEAMS.VIEW],
    show:         [TEAMS.ACTIVITIES, TEAMS.VIEW],
    create:       [TEAMS.ACTIVITIES],
    edit:         [TEAMS.ACTIVITIES],
    remove:       [TEAMS.ACTIVITIES],
  },
  practices: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.PRACTICES],
    show:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, SCHEDULING.PRACTICES],
    create:       [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.PRACTICES],
    edit:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.PRACTICES],
    remove:       [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.PRACTICES],

    bulkActions:  [SCHEDULING.STATUS],
    changeStatus: [SCHEDULING.STATUS],
  },
  teamArenas: {
    list:         [TEAMS.MANAGE, TEAMS.SETTINGS],
    show:         [TEAMS.MANAGE, TEAMS.SETTINGS],
    create:       [TEAMS.MANAGE, TEAMS.SETTINGS],
    edit:         [TEAMS.MANAGE, TEAMS.SETTINGS],
    remove:       [],
  },
  members: {
    list:         [TEAMS.MANAGE, TEAMS.ROSTER, TEAMS.VIEW],
    show:         [TEAMS.MANAGE, TEAMS.ROSTER, TEAMS.VIEW],
    create:       [TEAMS.MANAGE],
    edit:         [TEAMS.MANAGE, TEAMS.ROSTER],
    remove:       [TEAMS.MANAGE],

    import:       [TEAMS.MANAGE],
    status:       [TEAMS.MANAGE],
  },


  schedules: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW, ASSIGNING.ASSIGN],
    show:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW, ASSIGNING.ASSIGN, SUSPENSIONS.VIEW, SUSPENSIONS.MANAGE],
    create:       [SCHEDULING.MANAGE],
    edit:         [SCHEDULING.MANAGE],
    remove:       [SCHEDULING.MANAGE],

    teams:        [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    scheduler:    [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    settings:     [SCHEDULING.MANAGE],
    targets:      [ASSIGNING.MANAGE],
    publish:      [SCHEDULING.MANAGE],
    share:        [SCHEDULING.MANAGE],
    standings:    [SCHEDULING.MANAGE],
    stats:        [SCHEDULING.MANAGE],
    internal:     [INTERNAL],
  },
  scheduleteams: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW],
    show:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS, SCHEDULING.VIEW],
    create:       [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    edit:         [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    remove:       [SCHEDULING.MANAGE, SCHEDULING.GAMES],
  },
  schedulesequences: {
    list:         [SCHEDULING.MANAGE],
    show:         [SCHEDULING.MANAGE],
    create:       [SCHEDULING.MANAGE],
    edit:         [SCHEDULING.MANAGE],
    remove:       [SCHEDULING.MANAGE],
  },
  schedulingConstraints: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW],
    show:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW],
    create:       [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    edit:         [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    remove:       [SCHEDULING.MANAGE, SCHEDULING.GAMES],
  },
  drafts: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS],
    show:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS],
    create:       [SCHEDULING.MANAGE],
    edit:         [SCHEDULING.MANAGE, SCHEDULING.DRAFTS],
    remove:       [SCHEDULING.MANAGE],

    shared:       [SCHEDULING.DRAFTS],
  },
  draftGames: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS],
    show:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.DRAFTS],
    create:       [SCHEDULING.MANAGE],
    edit:         [SCHEDULING.MANAGE, SCHEDULING.DRAFTS],
    remove:       [SCHEDULING.MANAGE],

    publish:      [SCHEDULING.MANAGE],
    approve:      [SCHEDULING.APPROVE],
    bulkActions:  [SCHEDULING.MANAGE, SCHEDULING.DRAFTS],
  },
  games: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL, ASSIGNING.VIEW, SCORESHEETS.MANAGE],
    show:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL, ASSIGNING.VIEW, SCORESHEETS.MANAGE, SUSPENSIONS.VIEW, SUSPENSIONS.MANAGE],
    create:       [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    edit:         [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    remove:       [SCHEDULING.MANAGE],
    bulkActions:  [SCHEDULING.STATUS, ASSIGNING.MANAGE, ASSIGNING.ASSIGN],

    assignSettings: [ASSIGNING.ASSIGN],
    assign:       [ASSIGNING.ASSIGN],
    request:      [ASSIGNING.OFFICIAL],
    approve:      [SCHEDULING.APPROVE],

    officials:    [ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL, ASSIGNING.VIEW],
    affiliates:   [],
    changeStatus: [SCHEDULING.STATUS],
    forfeit:      [SCORESHEETS.MANAGE],
    shots:        [SCORESHEETS.MANAGE, SCORESHEETS.LINEUPS],
    throwsInnings: [SCORESHEETS.MANAGE, SCORESHEETS.LINEUPS],
    resetAssignRules: [ASSIGNING.ASSIGN],
    resetScoresheet: [SCORESHEETS.MANAGE],
    internal:        [INTERNAL],
  },
  gameBrackets: {
    edit:         [LEAGUES.MANAGE],
  },
  scheduleSettings: {
    list:         [SCHEDULING.MANAGE],
    show:         [SCHEDULING.MANAGE],
    create:       [SCHEDULING.MANAGE],
    edit:         [SCHEDULING.MANAGE],
    remove:       [],
  },
  gameDocuments: {
    list:         [SCORESHEETS.MANAGE, SCORESHEETS.LINEUPS, SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL, ASSIGNING.VIEW],
    show:         [],
    create:       [SCORESHEETS.MANAGE, SCORESHEETS.LINEUPS, SCHEDULING.MANAGE, SCHEDULING.GAMES, ASSIGNING.MANAGE, ASSIGNING.OFFICIAL],
    edit:         [],
    remove:       [SCORESHEETS.MANAGE, SCORESHEETS.LINEUPS, SCHEDULING.MANAGE, SCHEDULING.GAMES, ASSIGNING.MANAGE, ASSIGNING.OFFICIAL],
  },


  announcements: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    show:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    edit:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
  },
  gameMessagesLeague: {
    list:         [SCHEDULING.MANAGE, SCHEDULING.GAMES, SCHEDULING.VIEW, ASSIGNING.MANAGE, ASSIGNING.ASSIGN, ASSIGNING.OFFICIAL, ASSIGNING.VIEW],
    show:         [],
    create:       [SCHEDULING.MANAGE, SCHEDULING.GAMES],
    edit:         [],
    remove:       [SCHEDULING.MANAGE, SCHEDULING.GAMES],
  },
  gameMessagesOfficiating: {
    list:         [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    show:         [],
    create:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
    edit:         [],
    remove:       [ASSIGNING.MANAGE, ASSIGNING.ASSIGN],
  },


  assignSystems: {
    list:         [ASSIGNING.MANAGE],
    show:         [ASSIGNING.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  assignFees: {
    list:         [ASSIGNING.MANAGE],
    show:         [ASSIGNING.MANAGE],
    create:       [ASSIGNING.MANAGE],
    edit:         [ASSIGNING.MANAGE],
    remove:       [ASSIGNING.MANAGE],
  },
  assignRules: {
    list:         [ASSIGNING.MANAGE],
    show:         [ASSIGNING.MANAGE],
    create:       [ASSIGNING.MANAGE],
    edit:         [ASSIGNING.MANAGE],
    remove:       [ASSIGNING.MANAGE],
  },
  assignTargets: {
    list:         [ASSIGNING.MANAGE],
    show:         [ASSIGNING.MANAGE],
    create:       [ASSIGNING.MANAGE],
    edit:         [ASSIGNING.MANAGE],
    remove:       [ASSIGNING.MANAGE],
  },
  gameAssignSettings: {
    list:         [ASSIGNING.ASSIGN],
    show:         [ASSIGNING.ASSIGN],
    create:       [ASSIGNING.ASSIGN],
    edit:         [ASSIGNING.ASSIGN],
    remove:       [],
  },
  lists: {
    list:         [ASSIGNING.LISTS],
    show:         [ASSIGNING.LISTS],
    create:       [ASSIGNING.LISTS],
    edit:         [ASSIGNING.LISTS],
    remove:       [ASSIGNING.LISTS],
    import:       [ASSIGNING.LISTS],
  },
  listMembers: {
    list:         [ASSIGNING.LISTS],
    show:         [ASSIGNING.LISTS],
    create:       [ASSIGNING.LISTS],
    edit:         [ASSIGNING.LISTS],
    remove:       [ASSIGNING.LISTS],
  },


  scoresheets: {
    full:         [SCORESHEETS.MANAGE],
    lineup:       [SCORESHEETS.MANAGE, SCORESHEETS.LINEUPS],
    adminNotes:   [SCORESHEETS.MANAGE, ASSIGNING.OFFICIAL],
  },
  gameAffiliates: {
    list:         [SCORESHEETS.MANAGE],
    show:         [SCORESHEETS.MANAGE],
    create:       [SCORESHEETS.MANAGE],
    edit:         [SCORESHEETS.MANAGE],
    remove:       [SCORESHEETS.MANAGE],
  },
  gameEvents: {
    list:         [SCORESHEETS.EVENTS],
    remove:       [INTERNAL],
  },
  suspensions: {
    list:         [SUSPENSIONS.MANAGE, DISCIPLINE.MANAGE, DISCIPLINE.VIEW],
    show:         [SUSPENSIONS.MANAGE, SUSPENSIONS.VIEW, DISCIPLINE.MANAGE, DISCIPLINE.VIEW],
    create:       [SUSPENSIONS.MANAGE],
    edit:         [SUSPENSIONS.MANAGE],
    remove:       [SUSPENSIONS.MANAGE],
  },
  suspensionDisciplines: {
    list:         [DISCIPLINE.MANAGE, DISCIPLINE.VIEW],
    show:         [DISCIPLINE.MANAGE, DISCIPLINE.VIEW],
    create:       [DISCIPLINE.MANAGE],
    edit:         [DISCIPLINE.MANAGE],
    remove:       [DISCIPLINE.MANAGE],
    trigger:      [DISCIPLINE.TRIGGER],
    committee:    [DISCIPLINE.MANAGE, DISCIPLINE.VIEW],
  },
  gamePurges: {
    list:         [SCORESHEETS.MANAGE, SCORESHEETS.VIEW],
    show:         [SCORESHEETS.MANAGE, SCORESHEETS.VIEW],
    edit:         [],
  },
  gameIncidents: {
    list:         [SCORESHEETS.MANAGE, ASSIGNING.OFFICIAL],
    show:         [SCORESHEETS.MANAGE, ASSIGNING.OFFICIAL],
    create:       [SCORESHEETS.MANAGE, ASSIGNING.OFFICIAL],
    edit:         [SCORESHEETS.MANAGE, ASSIGNING.OFFICIAL],
    remove:       [SCORESHEETS.MANAGE, ASSIGNING.OFFICIAL],
  },
  gamepenalties: {
    list:         [SCORESHEETS.MANAGE, SCORESHEETS.VIEW],
    show:         [SCORESHEETS.MANAGE, SCORESHEETS.VIEW],
    create:       [SCORESHEETS.MANAGE],
    edit:         [SCORESHEETS.MANAGE],
    remove:       [SCORESHEETS.MANAGE],
  },
  sanctions: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  sanctionMembers: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  sanctionOffenses: {
    list:         [INTERNAL, SCORESHEETS.MANAGE],
    show:         [INTERNAL, SCORESHEETS.MANAGE],
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },

  sections: {
    list:         [CMS.MANAGE],
    show:         [CMS.MANAGE],
    create:       [CMS.MANAGE],
    edit:         [CMS.MANAGE],
    remove:       [CMS.MANAGE],
  },
  pages: {
    list:         [CMS.MANAGE],
    show:         [CMS.MANAGE],
    create:       [CMS.MANAGE],
    edit:         [CMS.MANAGE],
    remove:       [CMS.MANAGE],
  },
  posts: {
    list:         [CMS.MANAGE],
    show:         [CMS.MANAGE],
    create:       [CMS.MANAGE],
    edit:         [CMS.MANAGE],
    remove:       [CMS.MANAGE],
  },
  webevents: {
    list:         [CMS.MANAGE],
    show:         [CMS.MANAGE],
    create:       [CMS.MANAGE],
    edit:         [CMS.MANAGE],
    remove:       [CMS.MANAGE],
  },


  help: {
    list:         ALL,
    show:         ALL,
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },

  divisions: {
    list:         ALL,
    show:         ALL,
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  classes: {
    list:         ALL,
    show:         ALL,
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
  categories: {
    list:         ALL,
    show:         ALL,
    create:       [INTERNAL],
    edit:         [INTERNAL],
    remove:       [INTERNAL],
  },
}
