import React, { useEffect, Fragment, useState } from 'react';
import { Button } from '@material-ui/core';
import { SimpleForm, TextInput, RadioButtonGroupInput, useLogin, useNotify, useTranslate } from 'react-admin';
import { Redirect, Link } from 'react-router-dom';
import { useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import qs from 'qs';

import { authService, useLoggedIn, useVolunteer } from '../http';
import PasswordInput from '../common/inputs/PasswordInput';
import { registerResources } from '../resources';
import { hasBypassLogin } from '../common/has-bypass-login.constant.jsx';
import Layout from './BaseLayout';

const Meta = ({ showPasswordInput }) => {
  const translate = useTranslate()

  return <>
    {showPasswordInput && <Button type="link" component={Link} to="/reset">{translate('ra.input.password.forgot')}</Button>}
    <Button type="link" component={Link} to="/register">{translate('ra.action.register')}</Button>
  </>
}

const validate = values => {
  const errors = {};

  if (values.type === 'member') {
    if (!values.username) errors.username = 'ra.validation.required';
    if (!values.password) errors.password = 'ra.validation.required';
  } else if (values.type === 'scorekeeper') {
    if (!values.game) errors.game = 'ra.validation.required';
    if (!values.team) errors.team = 'ra.validation.required';
  } else {
    errors.type = 'ra.validation.invalid';
  }

  return errors;
}

const inputProps = {
  variant: 'outlined',
  margin: 'normal',
  fullWidth: true,
}

const UserLoginForm = ({ showPasswordInput, handleEdit, onKeyDown, disabled }) => {
  const translate = useTranslate()

  return <>
    <TextInput
      source="username"
      label="ra.input.field.email"
      type="email"
      autoFocus
      helperText={hasBypassLogin && '(Bypass enabled)'}
      options={{ InputProps: { autoComplete: "email username" } }}
      disabled={!!showPasswordInput || disabled}
      onKeyDown={onKeyDown}
      InputProps={{ endAdornment: showPasswordInput && <Button
        type="button"
        style={{ backgroundColor: 'transparent' }}
        onClick={handleEdit}
      >
        {translate('components.login.edit')}
      </Button>
      }}
      {...inputProps}
    />
    {showPasswordInput &&
    <PasswordInput
      source="password"
      label="ra.input.field.password"
      autoComplete="curent-password"
      helperText=""
      onKeyDown={onKeyDown}
      {...inputProps}
    />}
  </>
}

const ScorekeeperLoginForm = ({ onKeyDown }) => {
  const translate = useTranslate()

  return <>
    <TextInput
      source="game"
      label="resources.games.fields.number"
      helperText=""
      autoFocus
      onKeyDown={onKeyDown}
      {...inputProps}
    />
    <TextInput
      source="team"
      label="resources.teams.messages.home_team_hcr_id"
      helperText=""
      options={{ InputProps: { placeholder: translate('ra.message.digits', { number: 15 }) } }}
      onKeyDown={onKeyDown}
      {...inputProps}
    />
  </>
}

const LoginForm = ({ showPasswordInput, handleEdit, disabled }) => {
  const translate = useTranslate()
  const { values } = useFormState();
  const isScorekeeper = values.type === 'scorekeeper';

  const helperText = !isScorekeeper
    ? translate('components.login.messages.helper.member')
    : translate('components.login.messages.helper.scorekeeper')

  const onKeyDown = (e) => {
    if (e.keyCode == '13') { // Enter
      const element = document.querySelector('button[name="loginNextButton"]');
      const mouseClickEvents = ['mousedown', 'click', 'mouseup'];

      mouseClickEvents.forEach(mouseEventType =>
        element.dispatchEvent(
          new MouseEvent(mouseEventType, {
            view: window,
            bubbles: true,
            cancelable: true,
            buttons: 1
          })
        )
      );
    }
  }

  return <>
    <RadioButtonGroupInput source="type" label="components.login.messages.im_a" choices={[
      {
        id: 'member',
        name: `${translate('components.login.member')} / ${translate('components.login.admin')}`
      },
      { id: 'scorekeeper', name: translate('components.login.scorekeeper') },
    ]} helperText={helperText} disabled={disabled} />
    {!isScorekeeper ? <UserLoginForm showPasswordInput={showPasswordInput} handleEdit={handleEdit} disabled={disabled} onKeyDown={onKeyDown} /> : <ScorekeeperLoginForm onKeyDown={onKeyDown} />}
  </>
}

export default (props) => {
  const translate = useTranslate();
  const login = useLogin();
  const notify = useNotify();
  const dispatch = useDispatch()
  const isLoggedIn = useLoggedIn();
  const isVolunteer = useVolunteer();
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [loadingThirdParty, setLoadingThirdParty] = useState(false);

  useEffect(() => {
    const smaAccessToken = qs.parse(props.location.search, { ignoreQueryPrefix: true }).accessToken;
    const username = qs.parse(props.location.search, { ignoreQueryPrefix: true }).u;
    if (!smaAccessToken || !username) return;

    setLoadingThirdParty(true);

    authService.loginWithSma(smaAccessToken, username)
      .catch((e) => notify('ra.auth.login_error', 'error'))
      .finally(() => setLoadingThirdParty(false));
  }, [notify, props.location.search])

  useEffect(() => {
    registerResources(dispatch)
  }, [ dispatch ])

  if (isLoggedIn) return <Redirect to="/" />

  const handleNext = async (username) => {
    if (!username) {
      return { 'username': 'ra.validation.required' }
    }

    if (hasBypassLogin) {
      submit({ type: `member`, username, password: `${Date.now()}` });
    } else {
      authService.lookupSma(username)
        .then(({ data: redirectUrl }) => {
          if (redirectUrl) {
            window.location.href = redirectUrl
          } else {
            setShowPasswordInput(true)
          }
        })
        .catch(e => {
        // silently ignore
          setShowPasswordInput(true);
        })
    }
  }

  const handleEdit = () => {
    setShowPasswordInput(false)
  }

  const onEnterSubmit = (e) => {
    e.preventDefault();
  };

  const submit = ({ type, username, password, game, team }) => {
    return login({ type, username, password, game, team })
      .catch(res => {
        const { data, status } = res;
        const message = data?.error_description || data?.error

        switch (message) {
          case 'Invalid resource owner credentials':
            notify(`${translate('ra.validation.incorrect_email_password')}.`);
            return {
              'username': translate('ra.validation.incorrect_email_password'),
              'password': translate('ra.validation.incorrect_email_password'),
            }

          case 'Invalid game number':
            notify('components.login.validation.invalid_game_number_long')
            return {
              game: translate('components.login.validation.invalid_game_number')
            }

          case 'Invalid team ID':
            notify('components.login.validation.invalid_team_id_long')
            return {
              team: translate('components.login.validation.invalid_team_id')
            }

          case 'Missing home or away team':
            notify('components.login.validation.missing_home_or_away_team_long')
            return {
              team: translate('components.login.validation.missing_home_or_away_team')
            }

          case 'This game is cancelled':
            notify('components.login.validation.game_cancelled_long')
            return {
              game: translate('components.login.validation.game_cancelled')
            }

          case 'Unsupported sport':
            notify('components.login.validation.unsupported_sport_long')
            return {
              game: translate('components.login.validation.unsupported_sport')
            }

          case 'This game has already been completed':
            notify('components.login.validation.game_already_completed_long')
            return {
              game: translate('components.login.validation.game_already_completed_long')
            }

          default: {
            if (status === 503) {
              notify('ra.auth.login_error_retry')
            } else {
              notify('ra.auth.login_error')
            }
          }
        }
      })
  }

  const initialValues = {
    type: isVolunteer ? 'scorekeeper' : 'member',
  }
  if (!isVolunteer) {
    initialValues.username = authService.getUsername();
  }

  return <Layout meta={<Meta showPasswordInput={showPasswordInput} />}>
    <SimpleForm
      component={Fragment}
      validate={validate}
      save={submit}
      onSubmit={onEnterSubmit}
      toolbar={<Toolbar showPasswordInput={showPasswordInput} handleNext={handleNext} disabled={loadingThirdParty} />}
      initialValues={initialValues}
      {...inputProps}
    >
      <LoginForm showPasswordInput={showPasswordInput} handleEdit={handleEdit} disabled={loadingThirdParty} />
    </SimpleForm>
  </Layout>
}

const Toolbar = ({ handleSubmit, showPasswordInput, handleNext, disabled }) => {
  const translate = useTranslate();
  const { submitting } = useFormState();
  const { values } = useFormState();
  const isScorekeeper = values.type === 'scorekeeper';

  return <Button
    name="loginNextButton"
    type="button"
    fullWidth
    variant="contained"
    color="primary"
    disabled={submitting || disabled}
    onClick={showPasswordInput || isScorekeeper ? handleSubmit : () => handleNext(values?.username)}
  >
    {isScorekeeper || showPasswordInput ? translate('ra.auth.login') : translate('ra.action.next')}
  </Button>
}
