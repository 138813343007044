import React from 'react';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { ReferenceInput, ReferenceArrayInput, RecordContextProvider, useLocale, useTranslate, useQueryWithStore, GET_MANY } from 'react-admin';
import { get, isArray } from 'lodash';

import { translateApiProperty } from '@hisports/common';
import { FF_CROSS_SCHEDULING } from '@hisports/common/featureFlags';

import AutocompleteInput from '../../common/inputs/AutocompleteInput';
import AutocompleteSuggestion from '../../common/inputs/AutocompleteSuggestion'
import { useFormSeason } from '../../common/inputs/SeasonSelector';
import { useFlag } from '../../http';

import { TeamIcon } from '../icons';
import { TeamField } from './TeamField';
import { CategoryField } from '../categories/CategoryField';

const filterTeam = select => name => {
  if (!name || select) return;
  return { name };
}

const isSpecial = team => {
  if (!team || !team.HCRId) return false;
  return team.HCRId.startsWith('TBA') || team.HCRId.startsWith('NDT')
}

export const TeamSuggestion = ({ suggestion: team, includeCategory }) => {
  if (!team?.id) return null;

  let secondary = team.seasonId;
  if (team.HCRId) {
    secondary = `${team.HCRId} - ${secondary}`
  }
  if (isSpecial(team)) {
    secondary = team.name
  }

  const secondaryComponent = <RecordContextProvider value={team}>
    {secondary}
    {includeCategory && ' - '}
    {includeCategory && <CategoryField source="categoryId" link={false} />}
  </RecordContextProvider>

  return <AutocompleteSuggestion icon={<TeamIcon />} primary={team.name} secondary={secondaryComponent} />
}

export const TeamInput = ({ ignoreSchedule = false, includeCategory = true, forceSelect = false, helperText, multiple, filter: inputFilter = {}, ...props }) => {
  const translate = useTranslate();
  const locale = useLocale();
  const schedules = useSelector(state => state.admin.resources.schedules.data);
  const teams = useSelector(state => state.admin.resources.teams.data);
  const isEnabled = useFlag();

  const seasonId = useFormSeason();
  const { values } = useFormState();

  const teamIdOrTeamIds = get(values, props.source);
  const teamIds = isArray(teamIdOrTeamIds) ? teamIdOrTeamIds : [teamIdOrTeamIds];
  const categoryIds = teamIds.map(teamId => teams?.[teamId]?.categoryId).filter(Boolean);

  // pre fetch categories to display with team name
  const { data: categories = [] } = useQueryWithStore({
    type: GET_MANY,
    resource: 'categories',
    payload: { ids: categoryIds },
  }, {
    action: 'CUSTOM_QUERY',
    enabled: !!categoryIds?.length
  })

  const filter = { ...inputFilter };

  // filter by schedule / cross schedule
  const scheduleSelect = !ignoreSchedule && values && values.scheduleId;
  const crossScheduling = isEnabled(FF_CROSS_SCHEDULING)
  if (scheduleSelect) {
    const { scheduleId, groupId, crossScheduleId, crossGroupId } = values;
    if (scheduleId) filter.scheduleId = scheduleId;
    if (groupId) filter.groupId = groupId;
    if (crossScheduling) {
      const hasCrossSchedulingProperty = crossScheduleId != undefined || crossGroupId !== undefined

      if (!hasCrossSchedulingProperty) {
        filter.scheduleId = scheduleId;
        filter.groupId = groupId || null; // force groupId null if no group selected
      } else {
        const effectiveCrossScheduleId = crossScheduleId || scheduleId;
        const effectiveCrossGroupId = crossGroupId || null;

        filter.scheduleId = [scheduleId, effectiveCrossScheduleId]
        filter.groupId = [groupId || null, effectiveCrossGroupId]
      }
    }
  }

  // filter by schedule season
  if (values && values.scheduleId) {
    const schedule = schedules[values.scheduleId];
    if (schedule) {
      filter.seasonId = schedule.seasonId;
    }
  }

  // fallback to global season otherwise
  if (!filter.seasonId) {
    filter.seasonId = seasonId;
  }

  const Input = multiple ? ReferenceArrayInput : ReferenceInput;
  const select = forceSelect || scheduleSelect;

  return <Input
    reference="teams"
    filter={filter}
    filterToQuery={filterTeam(select)}
    sort={{
      field: 'name',
      order: 'ASC',
    }}
    perPage={select ? 999 : 50}
    {...props}
  >
    <AutocompleteInput
      suggestionComponent={TeamSuggestion}
      filterKeys={['name', 'HCRId']}
      multiple={multiple}
      // show the category in the helper text for single input
      helperText={(() => {
        if (multiple) return helperText
        const [teamId] = teamIds
        const team = teams[teamId]
        if (!team) return helperText
        const category = categories.find(category => category.id === team.categoryId);
        if (!category?.name) return helperText
        return `${translateApiProperty(category, 'name', locale)} ${translate(`resources.categories.values.gender.${category.gender}`)}${helperText && typeof helperText === 'string' ? ` - ${translate(helperText, { _: helperText, smart_count: 1 })}` : ''}`;
      })()}
      // show the category in the chip for multiple input
      optionText={team => {
        if (!multiple) return team?.name
        return <RecordContextProvider value={team}>
          <TeamField source="id" link={false} includeCategory={includeCategory && "inline"} />
        </RecordContextProvider>
      }}
      {...props}
      options={{
        ...props?.options,
        suggestionComponentProps: { includeCategory }
      }}
    />
  </Input>
}
