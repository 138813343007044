import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

import { List, Show, Create, Edit } from '../../common/ra';
import { useSeason } from '../../common/inputs/SeasonSelector';
import ResourceTitle from '../../common/ResourceTitle';
import { ShowHeader } from '../../common/ShowHeader';
import { useSport } from '../../http';
import { OfficeField } from '../offices/OfficeField';

import { ScheduleGrid } from './ScheduleGrid';
import { ScheduleFilters } from './ScheduleFilters';
import { ScheduleFilterList } from './ScheduleFilterList';
import ScheduleShowLayout from './ScheduleShow';
import ScheduleWizard from './ScheduleWizard';
import ScheduleForm from './ScheduleForm';
import ScheduleExporter from './ScheduleExporter';
import { ScheduleActions } from './ScheduleActions';

export const ScheduleShowHeader = () => {
  const translate = useTranslate()
  return <ShowHeader
    name={schedule => schedule.name}
    type={schedule => `${translate('resources.schedules.name', 1)} - ${translate(`resources.schedules.values.type.${schedule.type}`, { _: schedule.type })}`}
    description={schedule => <OfficeField source="officeId" variant="inherit" />}
  />;
}

export const ScheduleList = props => {
  const seasonId = useSeason();
  const sport = useSport();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const exporter = (data) => ScheduleExporter(data, sport)
  exporter._include = ScheduleExporter._include

  return <List {...props}
    exporter={exporter}
    aside={!isSmall && <ScheduleFilterList />}
    filters={isSmall && <ScheduleFilters />}
    filterDefaultValues={{ seasonId }}
    sort={{
      field: 'name',
      order: 'ASC'
    }}
  >
    <ScheduleGrid rowClick="show" />
  </List>
}

export const ScheduleShow = props => {
  const location = useLocation();
  const scheduleTab = useMemo(() => {
    return location?.pathname?.substring(location.pathname.lastIndexOf('/') + 1)
  }, [ location.pathname ])

  return <Show {...props}
    title={<ScheduleShowHeader />}
    style={{ maxWidth: scheduleTab === 'scheduler' ? 'none' : undefined }}
    actions={<ScheduleActions />}
  >
    <ScheduleShowLayout />
  </Show>
}

export const ScheduleCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.schedules.labels.add')} />}
    {...props}
  >
    <ScheduleWizard />
  </Create>
}

export const ScheduleEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: record.name })} />}
    {...props}
  >
    <ScheduleForm redirect="show" />
  </Edit>
}
