import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Button, ListItemText, ListItemIcon } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import { useHttpClient } from '../../http';
import { DialogDatagrid } from '../../common/dialogs/DialogDatagrid';
import { CreateResourceDialog, EditResourceDialog } from '../../common/dialogs/ResourceDialog';
import { ActionMenuItem } from '../../common/ActionMenu';

import { ScheduleSequenceForm } from './ScheduleSequenceForm';

const AddScheduleSequenceDialog = props =>
  <CreateResourceDialog label="resources.schedulesequences.labels.add" maxWidth="sm" resource="schedulesequences" basePath="/schedulesequences" {...props}>
    <ScheduleSequenceForm />
  </CreateResourceDialog>

const EditScheduleSequenceDialog = props => {
  const sequenceId = props?.id;
  const { data: prevalidate } = useHttpClient(`/schedulesequences/${sequenceId}/prevalidate`, null, !!sequenceId);

  return <EditResourceDialog disableDelete={prevalidate?.isSequenceUsed} label="resources.schedulesequences.labels.edit" maxWidth="sm" resource="schedulesequences" basePath="/schedulesequences" {...props}>
    <ScheduleSequenceForm prevalidate={prevalidate} />
  </EditResourceDialog>
}

export const AddScheduleSequenceButton = ({ size, variant, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  return <>
    <Button color="primary" size={size} variant={variant} startIcon={<Add />} onClick={() => setOpen(true)}>{translate('resources.schedulesequences.actions.add')}</Button>
    <AddScheduleSequenceDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />
  </>
}

export const AddScheduleSequenceAction = ({ onClick, ...props }) => {
  const translate = useTranslate();
  const [ isOpen, setOpen ] = useState(false);
  const handleClick = () => {
    onClick();
    setOpen(true);
  }
  return <>
    <ActionMenuItem color="primary" size="small" onClick={handleClick}>
      <ListItemIcon><Add fontSize="small" /></ListItemIcon>
      <ListItemText>{translate('resources.schedulesequences.actions.add')}</ListItemText>
    </ActionMenuItem>
    {isOpen && <AddScheduleSequenceDialog isOpen={isOpen} handleClose={() => setOpen(false)} {...props} />}
  </>
}

export const ScheduleSequenceDatagrid = props => <DialogDatagrid dialog={EditScheduleSequenceDialog} {...props} />
